<template>
  <div style="width: 100%; position: relative; z-index: 4">
    <div class="header">
      <div class="header__title">
        <div>🏆 {{ $i18n.t("top25") }}</div>
      </div>
      <v-btn
        class="all-buttons-t header__show-more"
        @click="showMoreUsers"
        v-if="!showAllUsers"
        >{{ $i18n.t("ViewAll2") }}</v-btn
      >
      <v-btn
        class="all-buttons-t header__show-more"
        @click="showLessUsers"
        v-else
        >{{ $i18n.t("ViewLess") }}</v-btn
      >
    </div>
    <div>
      <div
        class="card-top-players"
        :class="{ 'card-top-players_height': showAllUsers }"
      >
        <div class="preview">
          <div
            v-for="(player, ind) in topPlayersWithImages"
            :key="'top_' + ind"
            class="preview__item"
            :class="{
              preview__item_top: showAllUsers,
              'preview__item_top-no': !showAllUsers,
            }"
            :style="{ transitionDelay: calculateTransitionDelay(ind) }"
          >
            <div class="preview__body">
              <div class="preview__number">{{ ind + 1 }}.</div>
              <v-lazy v-model="isActive" class="preview__image">
                <v-lazy-image
                  v-if="isActive"
                  @load="isActive = true"
                  :src="player.image"
                  :alt="player.username"
                />
              </v-lazy>
              <div class="preview__content">
                <div class="preview__name">
                  <span>
                    {{ player.username }}
                  </span>
                </div>
                <div class="preview__flex">
                  <div class="preview__victory">
                    {{ $i18n.t("victories") }}:
                  </div>
                  <div class="preview__deposit deposit-block" v-if="player">
                    <p class="deposit-block__quantity">
                      {{ player.total_deposit ? player.total_deposit : 0 }}
                    </p>
                    <b class="deposit-block__currency" style="font-weight: 400">
                      {{ player.currency }}
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopPlayersSection",
  data() {
    return {
      showAllUsers: false,
      isActive: false,
      topUsers: [],
    };
  },
  computed: {
    topPlayersWithImages() {
      return this.$store.state.topPlayers
        .map((player, index) => {
          const avatarUrl = player.avatar
            ? `https://minio.takemybet.pro/avatars/${player.avatar.preview}`
            : this.topUsers[index % this.topUsers.length].image;

          return {
            ...player,
            image: avatarUrl,
          };
        })
        .sort((a, b) => b.total_deposit - a.total_deposit);
    },
    displayedTopPlayers() {
      return this.$store.getters.getTopPlayers;
    },
  },
  async mounted() {
    await this.$store.dispatch("awaitGetTopPlayers");
  },
  methods: {
    calculateTransitionDelay(ind) {
      if (!this.showAllUsers) {
        return "0s";
      } else {
        return ind >= 3 ? (ind - 3) * 0.2 + "s" : "0s";
      }
    },
    showMoreUsers() {
      //this.visibleUserCount = this.$store.getters.getTopPlayers.length;
      this.showAllUsers = true;
    },
    showLessUsers() {
      this.showAllUsers = false;
    },
  },
  // created() {
  //   for (let i = 1; i <= 15; i++) {
  //     this.topUsers.push({
  //       image: require(`@/assets/images/avatars/player_avatar_${i}.jpeg`)
  //     });
  //   }
  // },
};
</script>

<style lang="scss" scoped>
@mixin crown {
  &::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: -2px;
    right: -2px;
    transform: rotate(45deg);
  }
}
.card-top-players {
  @media (min-width: 320px) {
    height: 90px;
  }
  @media (max-width: 1023.98px) {
    height: 165px;
  }
  @media (max-width: 800.98px) {
    height: 240px;
  }

  width: 100%;
  overflow: hidden;
  @media (min-width: 320px) {
    flex-direction: column;
  }

  &_height {
    height: auto;
  }
}
.header {
  display: flex;
  align-items: center;
  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    flex: 1 1 100%;
    text-transform: uppercase;
    color: var(--white);
    font-size: 24px;
    margin-right: 5px;
    @media (max-width: 992px) {
      font-size: 18px;
    }
  }

  &__show-more {
  }
}

.preview {
  //@media (max-width:1329px) {
  //  position: absolute;
  //}
  //
  padding: 0;
  padding-top: 10px;
  position: sticky;
  padding-bottom: 10px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  //overflow: auto;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  @media (min-width: 800px) {
    flex-wrap: wrap;
  }

  &_pc {
    @media (min-width: 1329px) {
      margin-left: 15px;
      padding-left: 5px;
    }
  }
  &__item {
    perspective: 1000px;
    flex: 1 1 auto;
    transform: rotate3d(1, 0, 0, -90deg);
    transition: transform 0.5s, opacity 0.5s, height 0.5s ease 5s,
      max-height 0.5s ease 5s;
    padding: 5px;
    @media (min-width: 800px) {
      flex: 0 1 50%;
    }
    @media (min-width: 1024px) {
      flex: 0 1 33.333%;
    }
    @media (min-width: 1330px) {
      //flex: 1 1 auto;
      flex: 0 1 25%;
    }
    &:nth-child(1) {
      transform: rotate3d(1, 0, 0, 0deg) !important;
      @include crown;
      &::before {
        background: url("../../assets/images/crown.svg") 0 0 no-repeat;
      }
    }

    &:nth-child(2) {
      transform: rotate3d(1, 0, 0, 0deg) !important;
      @include crown;
      &::before {
        background: url("../../assets/images/crown2.svg") 0 0 no-repeat;
      }
    }

    &:nth-child(3) {
      transform: rotate3d(1, 0, 0, 0deg) !important;
      @include crown;
      &::before {
        background: url("../../assets/images/crown3.svg") 0 0 no-repeat;
      }
    }

    @media (min-width: 1330px) {
      &:nth-child(4) {
        transform: rotate3d(1, 0, 0, 0deg) !important;
      }
    }
    &:nth-child(25) {
      flex: 0 0 auto;
    }
    &_top {
      opacity: 1;
      visibility: visible;
      transform: rotate3d(1, 0, 0, 0deg);
      height: auto;
      .card-player_top {
        height: auto;
        height: auto;
      }
      &-no {
        transform: rotate3d(1, 0, 0, 90deg);
        transition: none;
      }
    }
  }
  &__body {
    display: flex;
    align-items: center;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    background-color: var(--dark-flamingo);
  }
  &__number {
    flex: 0 0 40px;
    color: var(--white);
    @media (max-width: 375px) {
      flex: 0 0 30px;
    }

    font-weight: 600;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__image {
    display: flex;
    width: 64px;
    height: 64px;
    flex: 0 0 64px;
    position: relative;
    .v-skeleton-loader {
      position: absolute;
      top: 0;
      left: 0;
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__content {
    flex: 1 1 100%;
    justify-content: flex-end;
    color: var(--white) !important;
    text-align: right !important;
    padding: 0 6px 0 0;
    font-size: 16px;
    @media (max-width: 500px) {
      font-size: 14px;
    }
  }
  &__name {
    padding-top: 0px !important;
    margin-left: 20px;
    display: flex;
    justify-content: flex-end;
    span {
      text-overflow: ellipsis;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      max-width: 210px;
    }
  }
  &__flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 5px;
  }
  &__victory {
    line-height: 1;
    @media (min-width: 320px) {
      font-size: 12px !important;
    }
    @media (min-width: 500px) {
      font-size: 14px !important;
    }
    @media (min-width: 1330px) {
      font-size: 12px !important;
    }
  }
  &__deposit {
  }
}
.deposit-block {
  display: flex;
  align-items: center;
  font-weight: 600 !important;
  margin-left: 5px;
  &__quantity {
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @media (min-width: 320px) {
      max-width: 80px;
    }
    @media (min-width: 375px) {
      max-width: 85px;
    }
    @media (min-width: 500px) {
      max-width: 145px;
    }
    @media (min-width: 992px) {
      max-width: 400px;
    }
    @media (min-width: 1330px) {
      max-width: 120px;
    }
  }
  &__currency {
    margin-left: 3px;
  }
}
</style>
