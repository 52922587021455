<template>
  <v-row style="" class="header-menu-bottom">
    <v-row v-if="user.id !== null" class="header-menu-bottom__ctn ma-0">
      <v-row
        class="header-menu-bottom__bonus-block row-menu-bottom-margin bonus-block mt-0"
        style="background-color: transparent"
      >
        <v-col
          @click="achieveRedirect"
          class="pa-0 mb-1 text-left d-flex align-center"
          style="cursor: pointer; font-size: 14px"
        >
          <font-awesome-icon
            class="ml-0 mr-1"
            style="color: var(--white); margin-right: 3px; font-size: 20px"
            icon="trophy"
          />
          <span style="color: var(--white)"
            >{{ $i18n.t("level") }}: <b>{{ this.user.level.order }}</b></span
          >
        </v-col>
        <v-progress-linear
          style="border-radius: 16px; width: 100%; border: solid 1px var(--red)"
          height="12px"
          background-color="none"
          color="var(--red)"
          :value="progressValue"
        ></v-progress-linear>
      </v-row>
      <v-row
        @click="toPlay"
        style="cursor: pointer"
        class="row-header-menu-bottom row-menu-bottom-margin mt-3 header-menu-bottom__bonus-block bonus-block"
      >
        <div class="bonus-block__lvl">
          <img src="@/assets/images/slot-machine.svg" alt="" />
          <strong></strong>
        </div>
        <div class="bonus-block__value">{{ $i18n.t("freespinsNoCount") }}:</div>
        <v-spacer></v-spacer>
        <div v-if="user.fs_count !== null" class="bonus-block__value">
          {{ user.fs_count }}
        </div>
        <div v-else class="bonus-block__value">0</div>
      </v-row>
      <v-row
        @click="payHistoryRedirect"
        style="cursor: pointer"
        class="row-header-menu-bottom row-menu-bottom-margin header-menu-bottom__balance-block balance-block"
      >
        <div class="balance-block__value">
          {{ user.balance
          }}<b class="ml-1" style="font-weight: 400">{{ user.currency }}</b>
        </div>
      </v-row>
      <!--  -->
      <v-row class="header-menu-bottom__actions row-menu-bottom-margin">
        <v-col cols="6" style="" class="text-left pa-0">
          <v-btn
            style="width: 105px"
            class="header-menu-bottom-button"
            @click="cashOutRedirect"
          >
            {{ $i18n.t("withdraw") }}
          </v-btn>
        </v-col>
        <v-col cols="6" class="pa-0">
          <div
            ref="depositButton"
            @click="buttonDepositRedirect"
            class="header-menu-bottom-button"
          >
            {{ $i18n.t("deposit1") }}
          </div>
        </v-col>
      </v-row>
    </v-row>
    <v-row v-else style="cursor: pointer" class="row-menu-bottom-margin mt-0">
      <div class="bonuses-wrapper">
        <div class="bonuses-item">
          <div @click="toSignIn" class="bonuses-button _login">
            <div class="bonuses-content">
              <div class="bonuses-icon">
                <v-icon color="var(--white)">account_circle</v-icon>
              </div>

              <div class="bonuses-text">
                {{ $t("enter") }}
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-icon color="var(--white)">navigate_next</v-icon>
          </div>
        </div>
        <div class="bonuses-item">
          <div class="bonuses-button" @click="toBonusRedirect">
            <div class="bonuses-icon">
              <img src="@/assets/images/bonus-gift.png" alt="gift" />
            </div>

            <div class="bonuses-text">
              {{ $t("all_bonuses") }}
            </div>
          </div>
        </div>
      </div>
    </v-row>
  </v-row>
</template>

<script>
export default {
  name: "HeaderMenuInfo",
  props: {
    user: {
      type: Object,
      required: true,
    },
    userBonuses: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  computed: {
    bonusItems() {
      if (this.userBonuses && this.userBonuses.free_rounds) {
        return this.userBonuses.free_rounds.map((bonus) => ({
          game_id: bonus.game_id,
          is_active: bonus.is_active,
        }));
      } else {
        return [];
      }
    },
    nextBonusAmount() {
      return this.$store.state.levels.nextBonusAmount;
    },
    betAmount() {
      return this.$store.state.levels.betAmount;
    },
    progressValue() {
      if (this.nextBonusAmount > 0 && this.betAmount != null) {
        return (this.betAmount / this.nextBonusAmount) * 100;
      }
      return 0;
    },
  },
  methods: {
    async toPlay() {
      if (this.bonusItems.length && this.bonusItems[0].is_active) {
        const gameName = this.bonusItems[0].game_id;
        this.$store.commit("search/setSearchMobile", false);
        this.$store.commit("search/setSearchMobile", false);
        this.$router.push({
          name: "Game",
          params: { id: gameName, demo: false },
        });
      } else {
        try {
          await this.$router.push("/profile/promotions");
          this.navDrawer = false;
        } catch (error) {
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        }
      }
    },
    async cashOutRedirect() {
      if (this.$route.path !== "/cashout") {
        await this.$router.push("/cashout");
      }
      this.$emit("route-one");
    },
    async buttonDepositRedirect() {
      if (this.$route.path !== "/deposit") {
        await this.$router.push("/deposit");
      }
      this.$emit("route-one");
    },
    async payHistoryRedirect() {
      if (this.$route.path !== "/profile/cashbox/payhistory") {
        await this.$router.push("/profile/cashbox/payhistory");
      }
      this.$emit("route-zero");
    },
    async achieveRedirect() {
      if (this.$route.path !== "/profile/promo-my/level") {
        await this.$router.push("/profile/promo-my/level");
      }
      this.$emit("route-three");
    },
    toSignIn() {
      this.$router.push("/enter");
      this.$store.commit("setSignInModal", true);
    },
    toBonusRedirect() {
      if (!this.user.id) {
        if (this.$route.path !== "/promotions-welcome") {
          this.$router.push("/promotions-welcome");
        }
      } else {
        if (this.$route.path !== "/profile/promotions") {
          this.$router.push("/profile/promotions");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-menu-bottom {
  margin-bottom: 14px;
  background-color: #4a1558;
  @media (min-width: 320px) {
    padding: 12px 8px;
  }
  &__ctn {
    @media (min-width: 320px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__bonus-block {
    @media (min-width: 320px) {
      margin-top: 4px;
      strong {
        color: var(--white);
      }
    }
  }

  &__balance-block {
  }
  &__actions {
    display: flex;
    align-items: center;
    &:last-child {
      text-align: right;
    }
  }
}
.header-menu-bottom-button {
  display: flex;
  justify-content: center;
  min-height: 36px;
  border-radius: 6px;
  text-transform: uppercase;
  align-items: center;
  padding: 0 10px !important;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(252, 81, 132, 0.701);
  font-size: 0.875rem;
  font-weight: 600;
  transition: box-shadow 0.3s;
  color: var(--white) !important;
  background-color: var(--red) !important;
  @media (any-hover: hover) {
    &:hover {
      box-shadow: 0 0 5px rgba(252, 81, 132, 1);
    }
  }
}
.row-header-menu-bottom {
  background-color: #cc62e9;
  display: flex;
  border-radius: 6px;
}
.row-menu-bottom-margin {
  @media (min-width: 320px) {
    margin-left: 20px;
    margin-right: 20px;
    color: var(--white);
    margin-bottom: 0px;
  }
}
.bonus-block {
  display: flex;
  align-items: center;
  &__lvl {
    padding: 5px;
    width: 36px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cc62e9;
    border-radius: 6px 0 0 6px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    strong {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
      font-size: 11px;
      color: var(--black);
    }
  }

  &__value {
    color: var(--white);
    font-size: 16px;
    padding-right: 8px;
    &:last-child {
      font-weight: 600;
    }
  }
}
.balance-block {
  justify-content: space-between;
  &__value {
    padding: 8px;
    font-weight: 600;
    b {
      font-weight: 600 !important;
    }
  }
}
.bonuses-wrapper {
  flex: 1 1 100%;
}
.bonuses-item {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
.bonuses-button {
  display: flex;
  align-items: center;
  background: var(--red);
  transition: box-shadow 0.3s;
  border-radius: 6px;
  cursor: pointer;
  padding: 6px 12px;
  @media (any-hover: hover) {
    &:hover {
      box-shadow: 0 0 5px var(--red);
    }
  }
  &._login {
    background: var(--light-flamingo);
    @media (any-hover: hover) {
      &:hover {
        box-shadow: 0 0 5px var(--light-flamingo);
      }
    }
  }
}
.bonuses-content {
  display: flex;
  align-items: center;
}
.bonuses-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
  margin-right: 6px;
}
.bonuses-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  text-transform: none;
}
</style>
