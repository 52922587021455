<template>
  <v-dialog
    class="dialog-tournament"
    persistent
    no-click-animation
    v-model="modalTournamentCompleted"
    content-class="tournament-popup"
    max-width="625"
    :fullscreen="!!$isMobile()"
  >
    <v-card flat class="tournament custom-scroll">
      <!-- <v-card-title class="tournament__header">
        {{ $t('tournamentsDepositeError') }}
      </v-card-title> -->
      <div class="tournament__row">
        <div class="tournament__column">
          <div class="tournament__image">
            <img :src="tournament.logo_img" alt="tournament win" />
          </div>
        </div>
        <div class="tournament__column">
          <div class="tournament__content mt-2">
            <v-card-text
              class="tournament__text"
              :class="{
                tournament__text_win: IsHaveAmountWin,
              }"
            >
              <span>
                {{
                  $t(
                    IsHaveAmountWin
                      ? "tournamentWinAmountText"
                      : "tournamentCompletedText"
                  )
                }}:
              </span>
              <p>
                {{ tournament.final_prize_amount }}
                {{ user.currency }}
              </p>
            </v-card-text>
            <div class="tournament__stats">
              <ul class="leaderboard-tournament">
                <li class="leaderboard-tournament__row text-left">
                  <h4>{{ $t("yourDetailStats") }}</h4>
                </li>
                <li class="leaderboard-tournament__row is-header">
                  <div
                    class="leaderboard-tournament__column text-left font-weight-bold"
                  >
                    {{ $t("place") }}
                  </div>
                  <div class="leaderboard-tournament__column">
                    {{ $t("player") }}
                  </div>
                  <div
                    class="leaderboard-tournament__column is-points text-right"
                  >
                    {{ $t("points") }}
                  </div>
                </li>
                <li class="leaderboard-tournament__row">
                  <div
                    class="leaderboard-tournament__column text-left font-weight-bold"
                  >
                    {{ tournament.leaderboard_position }}.
                  </div>
                  <div class="leaderboard-tournament__column">
                    {{ user.username }}
                  </div>
                  <div
                    class="leaderboard-tournament__column is-points text-right"
                  >
                    {{ tournament.current_score }}
                  </div>
                </li>
              </ul>
            </div>
            <v-card-actions class="tournament__buttons">
              <button
                @click="okayButton"
                class="all-buttons-t"
                :class="IsHaveAmountWin ? 'all-buttons-fw_green' : ''"
              >
                {{ $t(IsHaveAmountWin ? "claimWin" : "close") }}
              </button>
              <small class="font-weight-bold">
                *{{ $t("pageWillRefresh") }}
              </small>
            </v-card-actions>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ModalTournamentCompleted",
  data() {
    return {
      loadingFlag: false,
    };
  },

  methods: {
    async okayButton() {
      if (this.$route.path.includes("/tournament")) {
        await this.$router.replace("/");
      }
      location.reload();
    },
  },
  computed: {
    ...mapGetters({
      user: "getUserInfo",
      tournament: "tournaments/getTournament",
    }),
    IsHaveAmountWin() {
      return this.tournament.final_prize_amount > 0;
    },
    modalTournamentCompleted: {
      get() {
        return this.$store.state.tournaments.modalTournamentCompleted;
      },
      set(value) {
        this.$store.commit("tournaments/set", {
          type: "modalTournamentCompleted",
          items: value,
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tournament {
  position: relative;
  background: linear-gradient(
    to bottom right,
    rgba(2, 99, 225, 1),
    rgba(235, 24, 54, 1)
  );
  background-size: 400% 400%;
  animation: gradientAnimation 5s ease infinite;
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  border: 2px solid rgba(252, 81, 132, 0.459) !important;
  max-width: 400px;
  overflow: auto;
  border-radius: 16px !important;
  @media (max-height: 500px) and (orientation: landscape) {
    max-width: 625px;
  }
  @media (max-width: 768px) {
    border-radius: 0 !important;
  }
  color: var(--white) !important;

  &__row {
    @media (max-height: 500px) and (orientation: landscape) {
      display: flex;
    }
  }
  &__column {
    @media (max-height: 500px) and (orientation: landscape) {
      flex: 0 1 50%;
      &:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &__image {
    max-width: 100%;
    height: 200px;

    @media (max-height: 500px) and (orientation: landscape) {
      display: flex;
      height: auto;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__header {
    background-color: var(--dark-flamingo);
    justify-content: center;
    align-items: center;
    font-size: 14px !important;
    padding: 12px 16px !important;
    word-break: unset;
    //margin-bottom: 10px;
  }

  &__content {
    padding: 0 4px;
    @media (max-height: 500px) and (orientation: landscape) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    color: var(--white) !important;
    padding: 5px;
    &_win {
      p {
        animation: reveal 2400ms ease-in-out forwards 800ms,
          glow 2500ms linear infinite 2000ms;
        background: 50% 100% / 50% 50% no-repeat
          radial-gradient(ellipse at bottom, #fff, transparent, transparent);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
    }
    p {
      font-weight: bold;
      color: var(--white);
      font-size: 35px;
      text-transform: uppercase;
      line-height: 1;
      //animation: glitch 5s linear infinite;
      //@keyframes glitch{
      //  2%,64%{
      //    transform: translate(2px,0) skew(0deg);
      //  }
      //  4%,60%{
      //    transform: translate(-2px,0) skew(0deg);
      //  }
      //  62%{
      //    transform: translate(0,0) skew(5deg);
      //  }
      //}

      @keyframes reveal {
        80% {
          letter-spacing: 8px;
        }
        100% {
          background-size: 300% 300%;
        }
      }
      @keyframes glow {
        40% {
          text-shadow: 0 0 8px #fff;
        }
      }
    }
    span {
      font-weight: 700;
    }

    @media (max-height: 500px) and (orientation: landscape) {
      p {
        margin-bottom: 0;
      }
    }
  }
  &__stats {
    display: flex;
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-height: 500px) and (orientation: landscape) {
      padding-bottom: 3px !important;
      padding-top: 3px !important;
    }

    button {
      min-width: 120px !important;
    }
  }

  &__continue {
  }

  &__logout {
    margin-left: 20px !important;
  }

  &__link {
    color: var(--main-flamingo);
  }
}
.leaderboard-tournament {
  max-height: 205px;
  overflow-y: auto;
  position: relative;
  padding: 0;
  width: 100%;
  background: hsla(0, 0%, 100%, 0.1);
  border-radius: 6px;
  &__row {
    &:first-child {
      display: block;
    }
    grid-template-columns: repeat(3, 1fr) !important;
    align-items: center;
    display: grid;
    line-height: 1;
    min-height: 40px;
    padding: 10px;
    &:nth-child(2n) {
      background: hsla(0, 0%, 100%, 0.05);
    }
  }

  &__column {
    &:last-child {
      font-weight: 600;
    }
  }
}
</style>
