<template>
  <v-dialog
    v-model="dialogVisible"
    :content-class="$isMobile() ? '' : 'br-16'"
    :transition="false"
    :fullscreen="$isMobile() ? true : false"
    max-width="750px"
    scrollable
    :persistent="loadingFlag"
  >
    <v-card
      class="tournament"
      :class="{
        'is-desktop': !$isMobile(),
      }"
      :style="{
        'background-image': `url(${tournament.logo_img})`,
      }"
    >
      <div
        v-if="$isMobile()"
        class="tmb-style_title-header tmb-style_bg-dark tournament__topbar"
      >
        <v-toolbar-title class="tmb-style_title-header__content tmb-style_white"
          >{{ $i18n.t("tournamentBonus") }}
        </v-toolbar-title>
        <v-btn
          icon
          @click="closeModal"
          class="rounded-lg tmb-style_title-header__close tmb-style_bg-main"
          height="36"
          width="36"
        >
          <v-icon color="white">close</v-icon>
        </v-btn>
      </div>
      <div v-else class="tournament__header header-tournament">
        <div class="header-tournament__label">
          <font-awesome-icon style="font-size: 12px" icon="trophy" />
        </div>
        <span class="header-tournament__title">
          {{ $t("tournamentBonus") }}
        </span>
        <div @click="closeModal" class="header-tournament__close">
          <v-icon color="white" style="font-size: 18px"> close </v-icon>
        </div>
      </div>
      <v-card-text class="tournament__content">
        <div class="tournament__title">
          <span>
            {{ tournament.name }}
          </span>
          <div class="font-weight-bold timer-block item-timer">
            <span>{{ $t("timeLeftBonus") }}</span>
            <Countdown :expireInSeconds="expireInSeconds" />
          </div>
        </div>
        <!-- <v-btn
          @click="toShowTournamentProviders"
          class="all-buttons-fw all-buttons-fw_green tournament__button"
        >
          {{ $t("play") }}
        </v-btn> -->
        <div class="tournament__tables">
          <ul class="tournament__details">
            <li v-for="detail in tournamentDetails" :key="detail.type">
              <h2 v-if="detail.type === 'tournamentInfo'">
                {{ $t(detail.type) }}
              </h2>
              <span v-else>
                {{ $t(detail.type) }}
              </span>
              <strong v-if="detail.info">
                {{ detail.info }}
              </strong>
            </li>
          </ul>
          <ul class="tournament__leaderboard leaderboard-tournament">
            <li class="leaderboard-tournament__row text-left">
              <h2>{{ $t("leaderboard") }}</h2>
            </li>
            <li class="leaderboard-tournament__row is-header">
              <div class="leaderboard-tournament__column text-left">
                {{ $t("place") }}
              </div>
              <div class="leaderboard-tournament__column">
                {{ $t("player") }}
              </div>
              <div class="leaderboard-tournament__column is-points">
                {{ $t("points") }}
              </div>
              <div class="leaderboard-tournament__column is-prize text-right">
                {{ $t("prize") }}
              </div>
            </li>
            <v-skeleton-loader
              tile
              width="100%"
              height="130px"
              type="image"
              v-if="loadingFlag"
            ></v-skeleton-loader>
            <template v-else>
              <li
                class="leaderboard-tournament__row"
                v-for="leader in leaderboardItems"
                :key="leader.id"
              >
                <div class="leaderboard-tournament__column text-left">
                  {{ leader.leaderboard_position }}.
                </div>
                <div class="leaderboard-tournament__column">
                  {{ leader.username }}
                </div>
                <div class="leaderboard-tournament__column is-points">
                  {{ leader.current_score }}
                </div>
                <div class="leaderboard-tournament__column is-prize text-right">
                  {{ leader.final_prize_amount }} {{ user.currency }}
                </div>
              </li>
            </template>
          </ul>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Countdown from "@/components/Countdown/Timer";
export default {
  name: "TournamentDialog",
  props: {
    tournament: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Countdown,
  },
  data() {
    return {
      loadingFlag: false,
      dialogVisible: false,
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.dialogVisible = true;
      }
    },
    dialogVisible(val) {
      if (val) {
        this.$emit("close-modal");
      }
    },
    tournament: {
      handler(val, oldVal) {
        if (val && val.id && val.id !== oldVal.id) {
          this.toLoadLeaderboard();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      user: "getUserInfo",
    }),
    leaderboardItems() {
      return this.tournament && this.tournament.leaderboard
        ? this.tournament.leaderboard.items
        : [];
    },
    expireInSeconds() {
      if (!this.tournament.end_time) return 0;
      const now = new Date();
      const endTime = new Date(this.tournament.end_time);
      const differenceInSeconds = Math.max(
        Math.floor((endTime - now) / 1000),
        0
      );
      return differenceInSeconds;
    },
    tournamentDetails() {
      return [
        {
          type: "tournamentInfo",
          info: null,
        },
        {
          type: "tournamentBonusPool",
          info: `${this.tournament.prize_pool_amount} ${this.user.currency}`,
        },
        {
          type: "tournamentBonusPaid",
          info: `${
            this.tournament.prize_distribution &&
            this.tournament.prize_distribution.length
              ? this.tournament.prize_distribution[
                  this.tournament.prize_distribution.length - 1
                ].leaderboard_position
              : 0
          }
        (${this.$t("grandPrize")} - ${
            this.tournament.prize_distribution &&
            this.tournament.prize_distribution.length
              ? this.tournament.prize_distribution[0].prize_amount
              : 0
          } ${this.user.currency})
          `,
        },
        {
          type: "tournamentBonusFrequency",
          info: this.$t(this.tournament.repeats),
        },
      ];
    },
  },
  methods: {
    toShowTournamentProviders() {
      this.$router.push(
        `/tournament/${this.tournament.id}/section/provider/all`
      );
    },
    closeModal() {
      this.dialogVisible = false;
      this.$emit("close-modal");
    },
    async toLoadLeaderboard() {
      this.loadingFlag = true;
      await this.$store.dispatch(
        "tournaments/awaitGetLeaderboard",
        this.tournament.id
      );
      this.loadingFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tournament {
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  color: var(--white) !important;
  background-color: var(--dark-flamingo) !important;
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0 auto;
  max-width: 900px;
  overflow: hidden !important;
  padding: 20px 15px 40px !important;
  position: relative;
  z-index: 2;
  border-radius: 0px !important;
  &::before {
    content: "";
    opacity: 0.9;
    z-index: -1;
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--dark-d-flamingo);
    .is-desktop & {
      border-radius: 16px;
    }
  }
  .is-desktop & {
    border-radius: 16px !important;
  }
  ul {
    background: hsla(0, 0%, 100%, 0.1);
    border-radius: 6px;
    padding: 0;
  }
  li {
    align-items: center;
    display: grid;
    grid-template-columns: auto auto;
    line-height: 1.5;
    min-height: 40px;
    padding: 10px 15px;
  }
  &__header {
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding: 20px 15px 40px;
    gap: 10px;
    color: var(--white) !important;
    .is-desktop & {
      height: 100%;
      padding: 5px 20px 20px;
      background-position: 50%;
      background-size: cover;
    }
  }
  &__title {
    word-break: break-word;
    text-transform: uppercase;
    transition: all 0.3s;
    font-weight: 900;
    color: var(--white) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1;
    min-height: 100px;
    span {
      font-size: 47px;
    }
    order: -2;
    position: relative;
  }
  &__tables {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    width: 100%;
    .is-desktop & {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__details {
    overflow: hidden;
    &:first-child {
      li {
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        line-height: 25px;
        margin: 0;
        text-transform: uppercase;
      }
    }
    h2 {
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      line-height: 25px;
      margin: 0;
      text-transform: uppercase;
    }
    li {
      grid-template-columns: auto 1fr;
      grid-gap: 0;
      align-items: center;
      display: grid;
      grid-template-columns: auto auto;
      line-height: 1.5;
      min-height: 40px;
      padding: 10px 15px;
      &:nth-child(2n) {
        background: hsla(0, 0%, 100%, 0.05);
      }
      span {
        text-align: left;
        padding-right: 15px;
      }
      strong {
        text-align: right;
        font-weight: 600;
      }
    }
  }

  &__leaderboard {
  }
  &__button {
  }
}
.header-tournament {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
  margin: 0 -10px 5px;
  grid-column: 1 / -1;
  position: relative;
  z-index: 1;
  &__label,
  &__close {
    height: 44px;
    justify-content: center;
    padding: 10px;
    position: relative;
    width: 44px;
    display: flex;
    align-items: center;
  }

  &__title {
    color: #fff;
    display: block;
    line-height: 1.5;
    max-width: calc(100% - 68px);
    word-wrap: normal;
    cursor: default;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 5;
  }
  &__close {
    cursor: pointer;
  }
}
.leaderboard-tournament {
  max-height: 205px;
  overflow-y: auto;
  position: relative;
  &__row {
    &:first-child {
      display: block;
    }
    padding: 10px 15px;
    grid-template-columns: 0.7fr repeat(3, 1fr) !important;
    &:nth-child(2n) {
      background: hsla(0, 0%, 100%, 0.05);
    }
  }

  &__column {
    &:last-child {
      font-weight: 600;
    }
  }
}
.is-header {
}
.is-points {
}
.is-prize {
}
.timer-block {
  min-height: 48px;
  width: 100%;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--red);
  border-radius: 6px;
  background-color: rgb(78 0 72 / 85%);
  div {
    font-size: 12px;
  }
  span {
    font-size: 12px;
    color: var(--white-design);
  }
}
</style>
