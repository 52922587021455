<template>
  <v-container class="px-0" style="max-width: 900px">
    <!-- header title&button -->
    <v-row class="">
      <v-col class="d-flex align-center justify-space-between">
        <span
          class="pa-0 text-uppercase title-card"
          style="color: var(--white)"
        >
          {{ $t("bonusesTitle") }}
        </span>
      </v-col>
    </v-row>
    <template
      v-if="depositBonusActive || spinsBonusActive || nextDepositBonusActive"
    >
      <!-- title deposit bonus -->
      <v-row class="align-center">
        <v-col class="py-0">
          <v-card-title
            class="pa-0 mb-n6 text-uppercase title-card"
            style="font-size: 14px; color: var(--white)"
            >{{ $i18n.t("depositBonuses") }}
          </v-card-title>
        </v-col>
      </v-row>
      <!-- deposit bonus slider -->
      <v-row style="overflow: hidden">
        <v-col class="py-10 pr-0">
          <template v-if="depositBonusActive">
            <swiper
              style="height: 450px"
              :slides-per-view="1"
              :breakpoints="{
                '320': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '425': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '600': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '768': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '1024': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
              }"
            >
              <swiper-slide
                class="width-slide"
                v-for="(deposit, index) in arrayDepositBonuses"
                :key="'welcomeCard_' + index"
              >
                <deposit-bonus
                  :index="index"
                  :bonusId="deposit.id"
                  :cardDeposit="deposit"
                >
                </deposit-bonus>
              </swiper-slide>
            </swiper>
          </template>
          <v-col
            v-if="!depositBonusActive"
            class="d-flex flex-column mx-auto"
            style="max-width: 600px"
          >
            <span
              class="mb-3"
              style="color: var(--white); font-size: 22px; font-weight: 800"
            >
              {{ $t("noActivBonuses") }}
            </span>
            <v-btn class="all-buttons-fw" to="/profile/promotions">{{
              $t("selectBonus")
            }}</v-btn>
          </v-col>
        </v-col>
      </v-row>
      <!-- title freespins -->
      <v-row class="align-center">
        <v-col class="py-0">
          <v-card-title
            class="pa-0 mb-n6 text-uppercase title-card"
            style="font-size: 14px; color: var(--white)"
            >{{ $i18n.t("spinsBonuses") }}
          </v-card-title>
        </v-col>
      </v-row>
      <!-- freespins bonus slider -->
      <v-row style="overflow: hidden">
        <v-col class="py-10 pr-0">
          <template v-if="spinsBonusActive">
            <!-- centeredSlides="true" -->
            <swiper
              style="height: 450px"
              :slides-per-view="1"
              :breakpoints="{
                '320': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '425': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '600': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '768': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '1024': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
              }"
            >
              <swiper-slide
                v-for="(spins, index) in arraySpinsBonuses"
                class="width-slide"
                :key="'spinsCard_' + index"
              >
                <spins-bonus
                  :index="index"
                  :bonusId="spins.id"
                  :cardSpins="spins"
                ></spins-bonus>
              </swiper-slide>
            </swiper>
          </template>
          <v-col
            v-else
            class="d-flex flex-column mx-auto"
            style="max-width: 600px"
          >
            <span
              class="mb-3"
              style="color: var(--white); font-size: 22px; font-weight: 800"
            >
              {{ $t("noActivBonuses") }}
            </span>
            <v-btn class="all-buttons-fw" to="/profile/promotions">{{
              $t("selectBonus")
            }}</v-btn>
          </v-col>
        </v-col>
      </v-row>
      <!-- title future  -->
      <v-row class="align-center">
        <v-col cols="12" class="pb-0">
          <v-card-title
            class="pa-0 mb-n6 text-uppercase title-card"
            style="font-size: 14px; color: var(--white)"
            >{{ $i18n.t("nextDepositBonuses") }}
          </v-card-title>
        </v-col>
      </v-row>
      <!-- future slider  -->
      <v-row style="overflow: hidden">
        <v-col class="py-10 pr-0">
          <template v-if="nextDepositBonusActive">
            <swiper
              style="height: 450px"
              :slides-per-view="1"
              :breakpoints="{
                '320': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '425': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '600': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '768': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '1024': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
              }"
            >
              <swiper-slide
                class="width-slide"
                v-for="(futureCard, index) in arrayNextDepositBonuses"
                :key="'futureCard_' + index"
              >
                <!-- <bonus-future-one
                  :index="index"
                  :bonusId="futureCard.id"
                  :cardFuture="futureCard"
                > 
              </bonus-future-one>-->
                <deposit-bonus
                  :index="index"
                  :bonusId="futureCard.id"
                  :cardDeposit="futureCard"
                ></deposit-bonus>
              </swiper-slide>
            </swiper>
          </template>
          <v-col
            v-else
            class="d-flex flex-column mx-auto"
            style="max-width: 600px"
          >
            <span
              class="mb-3"
              style="color: var(--white); font-size: 22px; font-weight: 800"
            >
              {{ $t("noActivBonuses") }}
            </span>
            <v-btn class="all-buttons-fw" to="/profile/promotions">{{
              $t("selectBonus")
            }}</v-btn>
          </v-col>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-col class="d-flex flex-column mx-auto" style="max-width: 600px">
        <span
          class="mb-3"
          style="color: var(--white); font-size: 22px; font-weight: 800"
        >
          {{ $t("noActivBonuses") }}
        </span>
        <v-btn class="all-buttons-fw" to="/profile/promotions">{{
          $t("selectBonus")
        }}</v-btn>
      </v-col>
    </template>
    <template>
      <!-- title future  -->
      <v-row class="align-center">
        <v-col cols="12" class="">
          <v-card-title
            class="pa-0 mb-n6 text-uppercase title-card"
            style="font-size: 14px; color: var(--white)"
            >{{ $i18n.t("tournaments") }}
          </v-card-title>
        </v-col>
      </v-row>
      <!-- tournaments slider  -->
      <Preloader
        class="d-flex justify-center"
        v-if="loadingTournaments"
      ></Preloader>
      <v-row
        style="overflow: hidden"
        v-else-if="tournaments.items.length && !loadingTournaments"
      >
        <v-col class="py-10 pr-0">
          <template>
            <swiper
              style="height: 450px"
              :slides-per-view="1"
              :breakpoints="{
                '320': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '425': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '600': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '768': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '1024': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
              }"
            >
              <swiper-slide
                class="width-slide"
                v-for="tournament in tournaments.items"
                :key="tournament.id"
              >
                <bonus-tournament
                  :state="() => $store.state.tournaments.playerTournaments"
                  :tournament="tournament"
                ></bonus-tournament>
              </swiper-slide>
            </swiper>
          </template>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="d-flex flex-column mx-auto" style="max-width: 600px">
          <span
            class="mb-3"
            style="color: var(--white); font-size: 22px; font-weight: 800"
          >
            {{ $t("noTournamentJoin") }}
          </span>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import Preloader from "@/components/Preloader.vue";
import DepositBonus from "@/components/Profile/Promo/BonusCards/DepositBonus/DepositBonus.vue";
import SpinsBonus from "@/components/Profile/Promo/BonusCards/SpinsBonus/SpinsBonus.vue";
import BonusTournament from "@/components/Profile/Promo/BonusCards/BonusTournament/BonusTournament.vue";
import BonusFutureOne from "@/components/Profile/Promo/BonusCards/BonusFutureOne/BonusFutureOne.vue";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
import { mapGetters } from "vuex";
export default {
  name: "Bonuses",
  components: {
    Preloader,
    Swiper,
    SwiperSlide,
    DepositBonus,
    BonusTournament,
    SpinsBonus,
    // BonusFutureOne,
  },

  data() {
    return {
      loadingFlag: false,
      errorFlag: false,
      errorText: "",
      isBonusInfo: false,
      showTournamentDialog: false,
      loadingTournaments: false,
      selectedTournament: {},
      flipped: false,
    };
  },
  async mounted() {
    await this.toGetTournaments();
    await this.toGetBonuses();
  },
  methods: {
    async toGetTournaments() {
      this.loadingTournaments = true;
      try {
        await this.$store.dispatch("tournaments/awaitGetPlayerTournaments");
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingTournaments = false;
      }
    },
    async toGetBonuses() {
      this.loadingFlag = true;
      try {
        await this.$store.dispatch("bonuses/getUserBonuses");
      } catch (error) {
        console.error(error);
      }
      this.loadingFlag = false;
    },
  },
  computed: {
    ...mapGetters({
      spinsBonuses: "bonuses/getSpinsBonuses",
      depositBonuses: "bonuses/getDepositBonuses",
      tournaments: "tournaments/getPlayerTournaments",
    }),
    arraySpinsBonuses() {
      const freeRoundsSpinsBonuses = this.spinsBonuses.filter(
        (bonus) => bonus.type === "free_rounds" && bonus.status !== "created"
      );

      const activeSpinsWelcomeBonuses = this.spinsBonuses.filter(
        (bonus) => bonus.type === "welcome" && bonus.status !== "created"
      );

      freeRoundsSpinsBonuses.sort((a, b) =>
        ((a.status === "active") === b.status) === "active"
          ? 0
          : a.status === "active"
          ? -1
          : 1
      );
      activeSpinsWelcomeBonuses.sort((a, b) =>
        ((a.status === "active") === b.status) === "active"
          ? 0
          : a.status === "active"
          ? -1
          : 1
      );

      //  'id'
      const uniqueFreeRoundsBonuses = freeRoundsSpinsBonuses.filter(
        (freeRoundBonus) =>
          !activeSpinsWelcomeBonuses.some(
            (welcomeBonus) => welcomeBonus.id === freeRoundBonus.id
          )
      );

      if (
        freeRoundsSpinsBonuses.length > 0 &&
        activeSpinsWelcomeBonuses.length > 0
      ) {
        return [...activeSpinsWelcomeBonuses, ...uniqueFreeRoundsBonuses];
      } else if (activeSpinsWelcomeBonuses.length > 0) {
        return activeSpinsWelcomeBonuses;
      } else if (freeRoundsSpinsBonuses.length > 0) {
        return freeRoundsSpinsBonuses;
      } else {
        return [];
      }
    },
    arrayDepositBonuses() {
      return this.depositBonuses.filter(
        (bonus) => bonus.type !== "next_deposit"
      );
    },
    arrayNextDepositBonuses() {
      return this.depositBonuses.filter(
        (bonus) => bonus.type === "next_deposit"
      );
    },
    spinsBonusActive() {
      const activeSpinsBonuses = this.spinsBonuses.some(
        (bonus) => bonus.status !== "created"
      );
      return activeSpinsBonuses;
    },
    depositBonusActive() {
      return this.depositBonuses.some(
        (bonus) => bonus.status !== "created" && bonus.type !== "next_deposit"
      );
    },
    nextDepositBonusActive() {
      return this.arrayNextDepositBonuses.some(
        (bonus) => bonus.status !== "created" && bonus.type === "next_deposit"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.width-slide {
  width: 310px !important;
}

//========================================================================================================================================================

.title-card {
  @media (max-width: 500px) {
    font-size: 14px !important;
  }

  font-size: 18px !important;
}

.swiper-container {
  height: 100%;
}
</style>
