var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-to-color padding-ad"},[_c('div',{staticClass:"container-mod grid"},[_c('div',{staticClass:"grid__header header-grid"},[_c('v-btn',{staticClass:"header-grid__go-back",attrs:{"icon":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"color":"var(--white)"}},[_vm._v("arrow_back")])],1),_c('div',{staticClass:"header-grid__title"},[(
            (_vm.type === 'game-provider' || _vm.type === 'provider') &&
            _vm.info !== 'all' &&
            _vm.info !== 'all_games'
          )?_c('span',[_vm._v(_vm._s(_vm.getFormattedProviderName))]):(
            (_vm.type === 'game-provider' || _vm.type === 'provider') &&
            _vm.info === 'all_games'
          )?_c('span',[_vm._v(_vm._s(_vm.$t("all_games")))]):_vm._e(),(
            (_vm.type === 'game-provider' || _vm.type === 'provider') &&
            _vm.info === 'all'
          )?_c('span',[_vm._v(_vm._s(_vm.$t("providers")))]):_vm._e(),(_vm.type === 'game-block' && _vm.selectedBlock)?_c('span',[_vm._v(_vm._s(_vm.$t(_vm.selectedBlock.name)))]):_vm._e(),(_vm.type === 'games')?_c('span',[_vm._v(_vm._s(_vm.$t(_vm.info.toLocaleLowerCase())))]):_vm._e()])],1),_c('div',{staticClass:"grid__components components-grid"},[(_vm.isProviderList)?_c('GridProviders',{staticClass:"component-grid"}):_c('GridGames',{staticClass:"component-grid"})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }