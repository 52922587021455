<template>
  <div class="container-to-color padding-ad">
    <div class="container-mod grid">
      <div class="grid__header header-grid">
        <v-btn icon class="header-grid__go-back" @click="goBack">
          <v-icon color="var(--white)">arrow_back</v-icon>
        </v-btn>
        <div class="header-grid__title">
          <span
            v-if="
              (type === 'game-provider' || type === 'provider') &&
              info !== 'all' &&
              info !== 'all_games'
            "
            >{{ getFormattedProviderName }}</span
          >
          <span
            v-else-if="
              (type === 'game-provider' || type === 'provider') &&
              info === 'all_games'
            "
            >{{ $t("all_games") }}</span
          >
          <span
            v-if="
              (type === 'game-provider' || type === 'provider') &&
              info === 'all'
            "
            >{{ $t("providers") }}</span
          >
          <!-- <span v-if="type !== 'game-block'" >{{ $t(blockName) }}</span> -->
          <span v-if="type === 'game-block' && selectedBlock">{{
            $t(selectedBlock.name)
          }}</span>
          <span v-if="type === 'games'">{{
            $t(info.toLocaleLowerCase())
          }}</span>
        </div>
      </div>
      <div class="grid__components components-grid">
        <GridProviders class="component-grid" v-if="isProviderList" />
        <GridGames class="component-grid" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import GridProviders from "@/components/GridBlock/GridComponents/GridProviders.vue";
import GridGames from "@/components/GridBlock/GridComponents/GridGames.vue";
import { mapGetters } from "vuex";
export default {
  name: "GridBlock",
  components: {
    GridProviders,
    GridGames,
  },
  props: {
    type: {
      type: String,
      required: true,
      default: null,
    },
    info: {
      type: String,
      required: true,
      default: null,
    },
  },

  data() {
    return {};
  },
  watch: {},
  mounted() {},
  computed: {
    ...mapGetters({
      selectedBlock: "gameModule/getSelectedBlock",
      providerList: "getGameProviders",
      games: "gameModule/getCurrentGames",
      //gameBlocks: 'getGameBlocks',
    }),
    //selectedBlock(){
    // if (!this.gameBlocks.length) {
    //   return
    // }
    // const block = this.gameBlocks.find(block => block.id === this.info);
    // return block;
    //},
    isProviderList() {
      return (
        (this.type === "game-provider" || this.type === "provider") &&
        this.info === "all"
      );
    },
    getFormattedProviderName() {
      return this.info.replace(/_/g, " ");
    },
    visibleGames() {
      if (!this.games) {
        return [];
      }
      return this.games;
    },
    blockName: {
      get() {
        return localStorage.getItem("blockName") || "";
      },
      set(value) {
        localStorage.setItem("blockName", value);
      },
    },
  },
  methods: {
    async goBack() {
      await this.$router.back();
      // this.$store.commit("gameModule/HOLD_CURRENT_GAMES", null);
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}
.header-grid {
  height: 75px;
  margin: 0;
  padding: 20px 10px 0px;
  position: sticky;
  top: 35px;
  z-index: 60;
  display: flex;
  align-items: center;
  background-color: rgba(141, 36, 170, 0.3);
  backdrop-filter: blur(3px);
  .is-email-confirmation-bar-visible & {
    top: 89px;
    @media (max-width: 992px) {
      top: 130px;
    }
  }
  @media (max-width: 1500px) {
    padding: 10px 10px 0px;
    top: 50px;
  }

  @media (max-width: 500px) {
    padding: 0px 10px;
    top: 45px;
  }
  &__go-back {
    background-color: var(--dark-flamingo);
    border-radius: 6px;
    margin-right: 10px;
  }
  &__title {
    text-align: left;
    flex: 1 1 100%;
    text-transform: uppercase;
    color: var(--white);
    font-size: 24px;
    margin-right: 5px;
    @media (max-width: 992px) {
      font-size: 18px;
    }
  }
}
.padding-ad {
  padding-top: 56px !important;
  @media (max-width: 992px) {
    padding-top: 50px !important;
  }
  .is-email-confirmation-bar-visible & {
    padding-top: 70px !important;
    @media (max-width: 992px) {
      padding-top: 130px !important;
    }
  }
}
.components-grid {
  .component-grid {
    &._grid {
      grid-gap: 20px;
      grid-template-columns: repeat(5, 1fr);
      padding: 10px;
      @media (min-width: 500px) {
        display: grid;
      }
      @media (max-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 15px;
        width: 100%;
      }
      @media (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        width: 100%;
      }
      @media (max-width: 500px) {
        display: flex;
        flex-wrap: wrap;
      }
    }
    &._flex {
      display: flex;
      flex-wrap: wrap;
      position: relative;
    }
  }
}
</style>
