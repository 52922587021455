<template>
  <div>
    <div class="user-info">
      <v-card class="general-info">
        <v-container fluid>
          <v-row style="">
            <v-col style="max-width: max-content; min-width: 80px">
              <div
                class="player-image-wr"
                @click="selectAva"
                v-if="localUserInfo.avatar"
              >
                <div class="player-image">
                  <img
                    :src="`https://minio.takemybet.pro/avatars/${localUserInfo.avatar.image}`"
                    alt="avatar"
                  />
                </div>
                <span>+</span>
              </div>

              <Avatars v-if="isAva"></Avatars>
            </v-col>
            <v-col
              style="color: var(--white)"
              class="status_text text-left pl-0"
            >
              <span style="font-size: 18px" class="d-flex align-center">
                {{ userInfo.username }}
                <span
                  class="ml-2"
                  v-if="
                    localUserInfo.doc1_verified === 2 &&
                    localUserInfo.doc2_verified === 2 &&
                    localUserInfo.doc3_verified === 2
                  "
                  style="width: 24px; height: 24px"
                >
                  <img
                    src="@/assets/images/badge.svg"
                    style="max-width: 100%; max-height: 100%"
                    alt="badge-verif"
                  />
                </span>
              </span>
              <span> </span>
              <div>
                <span> id: </span>
                <span>
                  {{ localUserInfo.id }}
                </span>
              </div>
              <router-link
                to="/profile/info/verification"
                class="d-flex align-center"
                style="color: var(--red); text-decoration: none"
                :style="{
                  color:
                    (localUserInfo.doc1_verified === 1 ||
                      localUserInfo.doc2_verified === 1 ||
                      localUserInfo.doc3_verified === 1) &&
                    localUserInfo.doc1_verified !== 3 &&
                    localUserInfo.doc2_verified !== 3 &&
                    localUserInfo.doc3_verified !== 3 &&
                    localUserInfo.doc1_verified !== 0 &&
                    localUserInfo.doc2_verified !== 0 &&
                    localUserInfo.doc3_verified !== 0
                      ? 'var(--yellow)'
                      : localUserInfo.doc1_verified === 3 ||
                        localUserInfo.doc2_verified === 3 ||
                        localUserInfo.doc3_verified === 3
                      ? 'var(--red)'
                      : localUserInfo.doc1_verified === 2 &&
                        localUserInfo.doc2_verified === 2 &&
                        localUserInfo.doc3_verified === 2
                      ? 'var(--green-success)'
                      : 'var(--red)',
                }"
              >
                <v-icon style="color: inherit; margin-right: 3px">
                  add_moderator
                </v-icon>
                <div class="font-weight-bold">
                  {{ $i18n.t("verification") }}
                </div>
                <v-spacer></v-spacer>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  v-if="
                    localUserInfo.doc1_verified === 3 ||
                    localUserInfo.doc2_verified === 3 ||
                    localUserInfo.doc3_verified === 3 ||
                    localUserInfo.doc1_verified === 0 ||
                    localUserInfo.doc2_verified === 0 ||
                    localUserInfo.doc3_verified === 0
                  "
                />
                <v-icon
                  color="var(--yellow)"
                  v-if="
                    (localUserInfo.doc1_verified === 1 ||
                      localUserInfo.doc2_verified === 1 ||
                      localUserInfo.doc3_verified === 1) &&
                    localUserInfo.doc1_verified !== 3 &&
                    localUserInfo.doc2_verified !== 3 &&
                    localUserInfo.doc3_verified !== 3 &&
                    localUserInfo.doc1_verified !== 0 &&
                    localUserInfo.doc2_verified !== 0 &&
                    localUserInfo.doc3_verified !== 0
                  "
                  >timer</v-icon
                >
                <v-icon
                  color="var(--green-success)"
                  v-if="
                    localUserInfo.doc1_verified === 2 &&
                    localUserInfo.doc2_verified === 2 &&
                    localUserInfo.doc3_verified === 2
                  "
                  >done</v-icon
                >
              </router-link>
            </v-col>
          </v-row>
          <v-row class="text-left" style="color: var(--white)">
            <v-col class="pt-0 py-1">
              <v-row class="ma-0">
                <v-col class="pa-0 d-flex align-center justify-space-between">
                  <span> {{ $i18n.t("balance") }}: </span>
                  <span style="white-space: nowrap">
                    {{ localUserInfo.balance }} {{ localUserInfo.currency }}
                  </span>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0 d-flex align-center justify-space-between">
                  <span> {{ $i18n.t("bonus_balance") }}: </span>
                  <span style="white-space: nowrap">
                    {{ localUserInfo.bonus_balance }}
                    {{ localUserInfo.currency }}
                  </span>
                </v-col>
              </v-row>
              <v-row class="ma-0" v-if="localUserInfo.wager_amount">
                <v-col class="pa-0 d-flex align-center justify-space-between">
                  <span> {{ $i18n.t("wagerAmount") }}: </span>
                  <span style="white-space: nowrap">
                    {{
                      `${getUserWager} ${localUserInfo.currency} / ${getUserAmountWager} ${localUserInfo.currency}`
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="pt-0 d-flex flex-column"
              style="text-align: left; color: var(--white); cursor: pointer"
            >
              <!-- <template v-if="betAmount && nextBonusAmount"></template> -->
              <div
                @click="$router.push('/profile/promo-my/level')"
                class="mb-1 d-flex flex-column"
              >
                <span>{{ $i18n.t("toNextStatusLevel") }}</span>
                <span>
                  {{
                    `${betAmount} ${localUserInfo.currency} / ${nextBonusAmount} ${localUserInfo.currency}`
                  }}
                </span>
              </div>
              <!-- v-if="nextBonusAmount" -->
              <v-progress-linear
                @click="$router.push('/profile/promo-my/level')"
                style="
                  border-radius: 16px;
                  width: 100%;
                  border: solid 1px var(--red);
                "
                height="12px"
                background-color="none"
                color="var(--red)"
                :value="progressValue"
              ></v-progress-linear>

              <!-- <template v-else>
                  <v-skeleton-loader
          type="text"
        ></v-skeleton-loader>
              </template> -->
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card class="change-data">
        <v-form v-model="isValidForm" ref="userForm">
          <v-container fluid>
            <div class="col__padding-bottom">
              <!-- Email -->
              <v-text-field
                ref="fields"
                @input="activeInput('email')"
                hide-details
                outlined
                :error="isEmailToUnique || isEmailNoValid"
                :success="isEmailUnique"
                :label="
                  !isEmailToUnique && !isEmailNoValid
                    ? 'E-mail'
                    : isEmailToUnique
                    ? $i18n.t('emailNoUniq')
                    : $i18n.t('regEmailError')
                "
                :disabled="userInfo.email !== null && !editingMode"
                :style="
                  userInfo.email !== null && !editingMode ? 'opacity: 0.7' : ''
                "
                v-model="localUserInfo.email"
                style="
                  min-width: 200px;
                  margin: 0 auto;
                  text-overflow: ellipsis;
                "
                dense
                color="var(--white)"
                class="mb-3 input-colors white-autofill"
              >
                <!-- <template v-slot:append>
              <div class="pen__change-t" @click="enableEditing" v-if="userInfo.email !== null && userNoInstant"
                :style="editingMode ? 'display:none' : ''"><svg fill="#fff" height="25px" width="25px" version="1.1"
                  id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 502.001 502.001" xml:space="preserve" stroke="#fff">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <g>
                        <g>
                          <path
                            d="M489.809,32.002l-19.797-19.798C462.142,4.333,451.679,0,440.551,0s-21.59,4.333-29.459,12.202l-14.99,14.99 l-1.959-1.959c-3.905-3.904-10.235-3.905-14.143,0L62.146,343.088l0.011,0.004c-0.911,0.91-1.658,1.992-2.169,3.215 l-29.102,69.719L0.782,488.148c-1.562,3.742-0.71,8.056,2.157,10.923c1.913,1.914,4.472,2.93,7.073,2.93 c1.297,0,2.605-0.252,3.851-0.772l72.123-30.105c0.002-0.001,0.004-0.002,0.005-0.003l69.712-29.099 c1.223-0.51,2.305-1.257,3.215-2.168l0.004,0.011L476.778,122.01c1.875-1.875,2.929-4.419,2.929-7.071 c0-2.652-1.054-5.196-2.929-7.071l-1.959-1.959l14.99-14.989C506.052,74.676,506.052,48.246,489.809,32.002z M28.611,473.399 L43.596,437.5l20.915,20.914L28.611,473.399z M84.466,450.085l-32.541-32.54l20.772-49.763l61.532,61.531L84.466,450.085z M151.852,418.65L83.36,350.159l271.839-271.84l68.492,68.492L151.852,418.65z M437.834,132.669l-68.492-68.492l17.73-17.73 l68.492,68.492L437.834,132.669z M475.666,76.776L460.822,91.62l-50.431-50.432l14.844-14.844 c4.091-4.091,9.53-6.344,15.316-6.344s11.227,2.253,15.317,6.344l19.797,19.797C484.111,54.588,484.111,68.33,475.666,76.776z">
                          </path>
                          <path
                            d="M255.258,199.397L110.627,344.029c-3.905,3.905-3.905,10.237,0,14.143c1.953,1.953,4.512,2.929,7.071,2.929 s5.118-0.977,7.071-2.929l144.632-144.633c3.905-3.905,3.905-10.237,0-14.142C265.495,195.492,259.165,195.492,255.258,199.397z">
                          </path>
                          <path
                            d="M300.255,154.4l-18.213,18.213c-3.905,3.905-3.905,10.237,0,14.143c1.953,1.952,4.512,2.929,7.071,2.929 s5.118-0.977,7.071-2.929l18.213-18.213c3.906-3.905,3.906-10.237,0.001-14.143C310.492,150.496,304.162,150.496,300.255,154.4z">
                          </path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg></div>
            </template> -->
              </v-text-field>
              <!-- Nickname -->
              <v-text-field
                :label="
                  !isUsernameToUnique && !isUsernameNoValid
                    ? $i18n.t('userNameChars')
                    : isUsernameToUnique
                    ? $i18n.t('userNameNoUniq')
                    : $i18n.t('regUsernameError')
                "
                @input="activeInput('username')"
                :maxlength="12"
                hide-details
                :error="isUsernameToUnique || isUsernameNoValid"
                :success="isUsernameUnique"
                :disabled="userHaveUsername && !editingModeUserName"
                :style="
                  userInfo.username !== null && !editingModeUserName
                    ? 'opacity: 0.7'
                    : ''
                "
                outlined
                v-model="localUserInfo.username"
                style="margin: 0 auto"
                dense
                color="var(--white)"
                class="mb-3 input-colors white-autofill"
              >
                <template v-slot:append>
                  <div
                    class="pen__change-t"
                    @click="enableEditingUserName"
                    v-if="userHaveUsername"
                    :style="editingModeUserName ? 'display:none' : ''"
                  >
                    <svg
                      fill="#fff"
                      height="25px"
                      width="25px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 502.001 502.001"
                      xml:space="preserve"
                      stroke="#fff"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <g>
                          <g>
                            <g>
                              <path
                                d="M489.809,32.002l-19.797-19.798C462.142,4.333,451.679,0,440.551,0s-21.59,4.333-29.459,12.202l-14.99,14.99 l-1.959-1.959c-3.905-3.904-10.235-3.905-14.143,0L62.146,343.088l0.011,0.004c-0.911,0.91-1.658,1.992-2.169,3.215 l-29.102,69.719L0.782,488.148c-1.562,3.742-0.71,8.056,2.157,10.923c1.913,1.914,4.472,2.93,7.073,2.93 c1.297,0,2.605-0.252,3.851-0.772l72.123-30.105c0.002-0.001,0.004-0.002,0.005-0.003l69.712-29.099 c1.223-0.51,2.305-1.257,3.215-2.168l0.004,0.011L476.778,122.01c1.875-1.875,2.929-4.419,2.929-7.071 c0-2.652-1.054-5.196-2.929-7.071l-1.959-1.959l14.99-14.989C506.052,74.676,506.052,48.246,489.809,32.002z M28.611,473.399 L43.596,437.5l20.915,20.914L28.611,473.399z M84.466,450.085l-32.541-32.54l20.772-49.763l61.532,61.531L84.466,450.085z M151.852,418.65L83.36,350.159l271.839-271.84l68.492,68.492L151.852,418.65z M437.834,132.669l-68.492-68.492l17.73-17.73 l68.492,68.492L437.834,132.669z M475.666,76.776L460.822,91.62l-50.431-50.432l14.844-14.844 c4.091-4.091,9.53-6.344,15.316-6.344s11.227,2.253,15.317,6.344l19.797,19.797C484.111,54.588,484.111,68.33,475.666,76.776z"
                              ></path>
                              <path
                                d="M255.258,199.397L110.627,344.029c-3.905,3.905-3.905,10.237,0,14.143c1.953,1.953,4.512,2.929,7.071,2.929 s5.118-0.977,7.071-2.929l144.632-144.633c3.905-3.905,3.905-10.237,0-14.142C265.495,195.492,259.165,195.492,255.258,199.397z"
                              ></path>
                              <path
                                d="M300.255,154.4l-18.213,18.213c-3.905,3.905-3.905,10.237,0,14.143c1.953,1.952,4.512,2.929,7.071,2.929 s5.118-0.977,7.071-2.929l18.213-18.213c3.906-3.905,3.906-10.237,0.001-14.143C310.492,150.496,304.162,150.496,300.255,154.4z"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </template>
              </v-text-field>
              <!-- Phone -->
              <!-- hide-details  -->
              <v-text-field
                v-maska
                data-maska="################"
                :label="getPhoneLabelUniq"
                outlined
                prefix="+"
                :disabled="userHaveTel && !editingModeTel"
                :error="isTelNotUniq || isTelNotValid"
                hide-details
                :style="userHaveTel && !editingModeTel ? 'opacity: 0.7' : ''"
                @input="activeInput('tel')"
                v-model="localUserInfo.tel"
                dense
                color="var(--white)"
                class="white-autofill input-sign mb-3"
              >
                <template v-slot:append>
                  <div
                    class="pen__change-t"
                    @click="enableEditingTel"
                    v-if="userHaveTel"
                    :style="editingModeTel ? 'display:none' : ''"
                  >
                    <svg
                      fill="#fff"
                      height="25px"
                      width="25px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 502.001 502.001"
                      xml:space="preserve"
                      stroke="#fff"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <g>
                          <g>
                            <g>
                              <path
                                d="M489.809,32.002l-19.797-19.798C462.142,4.333,451.679,0,440.551,0s-21.59,4.333-29.459,12.202l-14.99,14.99 l-1.959-1.959c-3.905-3.904-10.235-3.905-14.143,0L62.146,343.088l0.011,0.004c-0.911,0.91-1.658,1.992-2.169,3.215 l-29.102,69.719L0.782,488.148c-1.562,3.742-0.71,8.056,2.157,10.923c1.913,1.914,4.472,2.93,7.073,2.93 c1.297,0,2.605-0.252,3.851-0.772l72.123-30.105c0.002-0.001,0.004-0.002,0.005-0.003l69.712-29.099 c1.223-0.51,2.305-1.257,3.215-2.168l0.004,0.011L476.778,122.01c1.875-1.875,2.929-4.419,2.929-7.071 c0-2.652-1.054-5.196-2.929-7.071l-1.959-1.959l14.99-14.989C506.052,74.676,506.052,48.246,489.809,32.002z M28.611,473.399 L43.596,437.5l20.915,20.914L28.611,473.399z M84.466,450.085l-32.541-32.54l20.772-49.763l61.532,61.531L84.466,450.085z M151.852,418.65L83.36,350.159l271.839-271.84l68.492,68.492L151.852,418.65z M437.834,132.669l-68.492-68.492l17.73-17.73 l68.492,68.492L437.834,132.669z M475.666,76.776L460.822,91.62l-50.431-50.432l14.844-14.844 c4.091-4.091,9.53-6.344,15.316-6.344s11.227,2.253,15.317,6.344l19.797,19.797C484.111,54.588,484.111,68.33,475.666,76.776z"
                              ></path>
                              <path
                                d="M255.258,199.397L110.627,344.029c-3.905,3.905-3.905,10.237,0,14.143c1.953,1.953,4.512,2.929,7.071,2.929 s5.118-0.977,7.071-2.929l144.632-144.633c3.905-3.905,3.905-10.237,0-14.142C265.495,195.492,259.165,195.492,255.258,199.397z"
                              ></path>
                              <path
                                d="M300.255,154.4l-18.213,18.213c-3.905,3.905-3.905,10.237,0,14.143c1.953,1.952,4.512,2.929,7.071,2.929 s5.118-0.977,7.071-2.929l18.213-18.213c3.906-3.905,3.906-10.237,0.001-14.143C310.492,150.496,304.162,150.496,300.255,154.4z"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </template>
              </v-text-field>
              <div class="d-flex align-center">
                <!-- Name -->
                <v-text-field
                  :label="$i18n.t('name')"
                  @input="activeInput('name')"
                  hide-details
                  outlined
                  v-model="localUserInfo.name"
                  style="margin: 0 auto"
                  dense
                  color="var(--white)"
                  class="white-autofill mb-3 mr-2"
                  v-maska
                  data-maska="a"
                  data-maska-tokens="a:[a-zA-Z]:multiple"
                >
                </v-text-field>
                <!-- Last name -->
                <v-text-field
                  :label="$i18n.t('last_name')"
                  @input="activeInput('last_name')"
                  hide-details
                  outlined
                  v-model="localUserInfo.last_name"
                  style="margin: 0 auto"
                  dense
                  color="var(--white)"
                  class="white-autofill mb-3"
                  v-maska
                  data-maska="a"
                  data-maska-tokens="a:[a-zA-Z]:multiple"
                >
                </v-text-field>
              </div>
            </div>
            <div class="col__padding-top">
              <div>
                <div class="d-flex align-center">
                  <!-- Gender -->
                  <v-select
                    class="mb-3 selectMain mr-2"
                    dense
                    hide-details
                    :disabled="userInfo.sex !== null"
                    @change="activeInput('sex')"
                    :items="userGenders"
                    ref="mySelectS"
                    :menu-props="{
                      bottom: true,
                      offsetY: true,
                      contentClass: 'selectMain',
                    }"
                    v-model="localUserInfo.sex"
                    :label="$t('sex')"
                    color="white"
                    outlined
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:selection="{ item }">
                      <div class="d-flex align-center">
                        <img
                          :src="item.image"
                          width="24"
                          height="24"
                          class="mr-2"
                        />
                        {{ item.text }}
                      </div>
                    </template>
                    <template v-slot:item="{ item }">
                      <div class="d-flex align-center">
                        <img
                          :src="item.image"
                          width="24"
                          height="24"
                          class="mr-2"
                        />
                        <v-list-item-content>
                          <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                      </div>
                    </template>
                  </v-select>
                  <!-- Birthday -->
                  <v-menu
                    ref="menu"
                    :disabled="userHaveDateBirth"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    bottom
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :style="userHaveDateBirth ? 'opacity: 0.7' : ''"
                        v-model="localUserInfo.birthday"
                        :disabled="userHaveDateBirth"
                        :label="$i18n.t('birthdate')"
                        hide-details
                        dense
                        class="white-autofill mb-3"
                        color="white"
                        readonly
                        outlined
                        v-on="on"
                      >
                        <v-icon
                          color="var(--white)"
                          slot="append"
                          style="background: none !important; font-size: 24px"
                          class="providers_icon"
                          @click="menu = true"
                        >
                          date_range
                        </v-icon>
                      </v-text-field>
                    </template>

                    <v-date-picker
                      v-model="localUserInfo.birthday"
                      class="schedule_icons"
                      :max="minDate"
                      :show-current="
                        localUserInfo.birthday !== null ? 'year' : ''
                      "
                      locale="en"
                      color="var(--red)"
                      no-title
                      style="min-width: 100%"
                      @change="activeInput('birthday'), (menu = false)"
                      :active-picker.sync="activePicker"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <!-- Country -->
                <template v-if="localUserInfo.address">
                  <!-- <v-text-field  
              :label="$i18n.t('country')" 
              @input="activeInput('address.country')" 
              hide-details 
              outlined
              v-maska
              data-maska="a"
              data-maska-tokens="a:[a-zA-Z]:multiple"
                v-model="localUserInfo.address.country" style="margin: 0 auto" dense color="var(--white)" class="white-autofill mb-3">
              </v-text-field> -->
                  <v-select
                    class="mb-3 selectMain"
                    dense
                    height="10"
                    hide-details
                    @change="activeInput('address.country')"
                    @click="getAllCountries"
                    :items="countries.items"
                    :menu-props="{
                      bottom: true,
                      offsetY: true,
                      contentClass: 'selectMain _center',
                    }"
                    v-model="localUserInfo.address.country"
                    :loading="loadingCountryList"
                    :label="$t('country')"
                    color="white"
                    outlined
                    item-text="country_name"
                    item-value="country_name"
                  >
                    <template v-slot:selection="{ item }">
                      <div class="d-flex align-center">
                        <!-- <img
                  :src="item.image"
                  width="24"
                  height="24"
                  class="mr-2"
                /> -->
                        {{ item.country_name }}
                      </div>
                    </template>
                    <template v-slot:item="{ item }">
                      <div class="d-flex align-center">
                        <!-- <img
                    :src="item.image"
                    width="24"
                    height="24"
                    class="mr-2"
                  /> -->
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.country_name
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </div>
                    </template>

                    <template v-slot:progress>
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </template>
                    <template v-slot:no-data>
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-center">
                              <span v-if="!loadingCountryList">{{
                                $t("noData")
                              }}</span>
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                v-else
                              >
                              </v-progress-circular>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </template>
                    <template v-slot:append-item>
                      <div
                        class="d-flex align-center justify-center my-1"
                        v-if="
                          !loadingCountryList &&
                          countries &&
                          countries.items &&
                          countries.items.length > 0 &&
                          countries.page !== countries.pages
                        "
                      >
                        <!-- <v-btn class="all-buttons-fw_green" 
                :loading="loadingPage" 
                 @click="getAllCountries('one-more')"
                  >
              {{ $i18n.t('showMore') }}
                </v-btn> -->
                        <div v-intersect="showMoreIntersect"></div>
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          v-if="loadingPage"
                        ></v-progress-circular>
                      </div>
                    </template>
                  </v-select>

                  <div class="d-flex align-center">
                    <!-- City -->
                    <v-text-field
                      :label="$i18n.t('city')"
                      @input="activeInput('address.city')"
                      hide-details
                      outlined
                      v-maska
                      data-maska="a"
                      data-maska-tokens="a:[a-zA-Z]:multiple"
                      v-model="localUserInfo.address.city"
                      style="margin: 0 auto"
                      dense
                      color="var(--white)"
                      class="white-autofill mb-3"
                    >
                    </v-text-field>
                    <v-text-field
                      :label="$i18n.t('postalCode')"
                      @input="activeInput('address.postal_code')"
                      hide-details
                      outlined
                      v-maska
                      data-maska="################"
                      v-model="localUserInfo.address.postal_code"
                      style="margin: 0 auto"
                      dense
                      color="var(--white)"
                      class="white-autofill ml-2 mb-3"
                    >
                    </v-text-field>
                  </div>
                  <!-- Address -->
                  <v-text-field
                    :label="$i18n.t('address')"
                    @input="activeInput('address.address')"
                    hide-details
                    outlined
                    v-maska
                    data-maska="a"
                    data-maska-tokens="a:[a-zA-Z]:multiple"
                    v-model="localUserInfo.address.address"
                    style="margin: 0 auto"
                    dense
                    color="var(--white)"
                    class="white-autofill mb-3"
                  >
                  </v-text-field>
                  <!-- Postal Code -->
                </template>
              </div>
              <v-btn
                style=""
                class="all-buttons-fw all-buttons-fw_green"
                :loading="loadingFlag"
                :disabled="!isFieldsDirty"
                dark
                @click="allUpdate"
                v-if="!successInfoUpdate"
              >
                {{ $i18n.t("saveChanges") }}
              </v-btn>
              <v-btn
                color="green"
                class="all-buttons-fw all-buttons-fw_green"
                v-if="successInfoUpdate"
              >
                <v-icon dark> done </v-icon>
              </v-btn>
            </div>
          </v-container>
        </v-form>
      </v-card>
    </div>
    <!-- Buttons -->
    <v-row class="mt-0">
      <v-col class="text-center">
        <!-- Logout -->
        <v-btn class="all-buttons-t grow" color="var(--white)" @click="logOut">
          {{ $i18n.t("logOutFromAcc") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { logOut } from "@/store/clear_store";
import { vMaska } from "maska";
import axios from "axios";
import { mapGetters } from "vuex";
import Avatars from "../Avatars/Avatars";
import { stopTimer } from "../../../timerWorker";
export default {
  directives: { maska: vMaska },
  name: "PlayerInfo",
  components: {
    Avatars,
  },
  computed: {
    nextBonusAmount() {
      return this.$store.state.levels.nextBonusAmount;
    },
    betAmount() {
      return this.$store.state.levels.betAmount;
    },
    progressValue() {
      if (this.nextBonusAmount > 0 && this.betAmount != null) {
        return (this.betAmount / this.nextBonusAmount) * 100;
      }
      return 0;
    },
    isAva: {
      get() {
        return this.$store.state.regComponents.isAvaReg;
      },
      set(value) {
        this.$store.commit("regComponents/setAvaReg", value);
      },
    },
    //========================================================================================================================================================
    ...mapGetters({
      userInfo: "getUserInfo",
      countries: "countries/getCountries",
      currentCountry: "countries/getCurrentCountry",
    }),
    getPhoneLabelUniq() {
      if (this.isTelNotUniq) {
        return this.$i18n.t("phone_not_uniq");
      } else if (this.isTelNotValid) {
        return `${this.$i18n.t("invalidPhone")}(${this.$i18n.t(
          "min_nine_chars"
        )})`;
      } else {
        return this.$i18n.t("phone");
      }
    },
    getUserWager() {
      const userWager = this.localUserInfo.wagering;
      if (userWager !== null && userWager !== undefined) {
        return userWager;
      } else {
        return 0;
      }
    },
    getUserAmountWager() {
      const userWager = this.localUserInfo.wager_amount;
      if (userWager !== null && userWager !== undefined) {
        return userWager;
      } else {
        return 0;
      }
    },
    staticUserInfo() {
      return this.$store.state.staticUserInfo;
    },
    minDate() {
      let date = new Date();
      date.setFullYear(date.getFullYear() - 18);
      return date.toISOString();
    },
    userHaveUsername() {
      if (this.staticUserInfo !== null) {
        return this.staticUserInfo.username !== null;
      } else {
        return false;
      }
    },
    userHaveEmail() {
      if (this.staticUserInfo !== null) {
        return this.staticUserInfo.email !== null;
      } else {
        return false;
      }
    },
    userHaveDateBirth() {
      if (this.staticUserInfo !== null) {
        return this.staticUserInfo.birthday !== null;
      } else {
        return false;
      }
    },
    userHaveUserSex() {
      if (this.staticUserInfo !== null) {
        return this.staticUserInfo.sex !== null;
      } else {
        return false;
      }
    },
    userHaveTel() {
      if (this.staticUserInfo !== null) {
        return this.staticUserInfo.tel !== null;
      } else {
        return false;
      }
    },
    userNoInstant() {
      return this.$store.state.isUserNoInstant !== false;
    },
    phoneRules() {
      return [(v) => /^\d{9,}$/g.test(v) || this.$i18n.t("invalidPhone")];
    },
  },
  data() {
    return {
      isValidForm: true,
      activePicker: null,
      date: "",
      menu: false,
      localUserInfo: {},
      userGenders: [
        {
          text: this.$t("Male"),
          value: "male",
          image: require("@/assets/images/genders/mars.svg"),
        },
        {
          text: this.$t("Female"),
          value: "female",
          image: require("@/assets/images/genders/venus.svg"),
        },
        {
          text: this.$t("Other"),
          value: "other",
          image: require("@/assets/images/genders/mars-and-venus.svg"),
        },
      ],
      successInfoUpdate: false,
      editingMode: false,
      editingModeUserName: false,
      editingModeTel: false,
      isFieldsDirty: false,
      isUsernameUnique: false,
      isEmailUnique: false,
      isUsernameToUnique: false,
      isEmailToUnique: false,
      isTelNotUniq: false,
      isTelNotValid: false,
      isEmailNoValid: false,
      isUsernameNoValid: false,
      loadingFlag: false,
      loadingCountryList: false,
      loadingPage: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    await next((vm) => {
      const holdData = JSON.parse(JSON.stringify(vm.userInfo));
      //console.log('holdData', holdData);
      vm.localUserInfo = holdData;
    });
  },
  beforeRouteLeave(to, from, next) {
    (this.editingMode = false), (this.editingModeUserName = false);
    this.editingModeTel = false;
    this.isFieldsDirty = false;
    this.isUsernameUnique = false;
    this.isEmailUnique = false;
    this.isUsernameToUnique = false;
    this.isEmailToUnique = false;
    this.isTelNotUniq = false;
    this.isTelNotValid = false;
    this.isEmailNoValid = false;
    this.isUsernameNoValid = false;
    next();
  },
  created() {},
  async mounted() {
    // this.$store.watch(
    //   (state, getters) => getters.getUserInfo,
    //   () => {
    //     this.user = Object.assign({}, this.$store.getters.getUserInfo);
    //   },
    // ).bind(this);
    if (!this.currentCountry && this.userInfo.country_code !== "NN") {
      this.loadingCountryList = true;
      await this.$store.dispatch(
        "countries/getCurrentCountry",
        this.userInfo.country_code
      );
      this.loadingCountryList = false;
    }
    const holdData = JSON.parse(JSON.stringify(this.userInfo));
    this.localUserInfo = holdData;
    this.localUserInfo.address.country = this.currentCountry;
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    localUserInfo: {
      handler: function (oldValue, newValue) {
        //if (newValue.tel === '') {
        //  this.isFieldsDirty = true;
        //}
      },
      deep: true,
    },
    "localUserInfo.address.country": {
      handler(newValue, oldValue) {
        if (newValue && this.countries.items.length) {
          this.countries.items.find((item) => {
            item.country_name === newValue
              ? (this.localUserInfo.country_code = item.country_code)
              : null;
          });
        }
      },
    },
    // '$route.params.redirectByToast': {
    //     immediate: true,
    //     handler(newValue, oldValue) {
    //       if (newValue) {

    //         console.log('params');
    //       }
    //     }
    //   }
  },
  methods: {
    showMoreIntersect(entries, obs) {
      if (entries[0].isIntersecting && !this.loadingPage) {
        this.loadingPage = true;
        // call
        this.getAllCountries("one-more");
      }
    },
    async getAllCountries(condition) {
      try {
        let filters = {
          page: 1,
        };
        if (condition === "one-more") {
          this.loadingPage = true;
          filters.page = this.countries.page + 1;
          await this.$store.dispatch("countries/getCountries", {
            filters: filters,
            oneMore: true,
          });
          this.loadingPage = false;
        } else {
          if (
            this.countries &&
            this.countries.items &&
            this.countries.items.length > 0
          ) {
            this.loadingCountryList = false;
            return;
          }
          this.loadingCountryList = true;
          await this.$store.dispatch("countries/getCountries", {
            filters: filters,
            oneMore: false,
          });
        }
      } catch (error) {
        console.log("er", error);
      }
      this.loadingCountryList = false;
    },
    deepEqual(obj1, obj2) {
      if (obj1 === obj2) return true;

      if (obj1 == null && obj2 === "") return true;
      if (obj1 === "" && obj2 == null) return true;
      if (obj1 == null || obj2 == null) return false;
      if (typeof obj1 !== "object" || typeof obj2 !== "object") return false;

      let keys1 = Object.keys(obj1);
      let keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) return false;

      for (let key of keys1) {
        if (!keys2.includes(key)) return false;

        // empty string && null
        if (obj1[key] === "" && obj2[key] == null) continue;
        if (obj1[key] == null && obj2[key] === "") continue;

        if (!this.deepEqual(obj1[key], obj2[key])) return false;
      }

      return true;
    },
    selectAva() {
      this.$store.commit("regComponents/setAvaReg", true);
      this.$store.commit("regComponents/setRegAfterEmail", true);
    },
    async usernameUnique(username) {
      let res = false;
      if (this.staticUserInfo.username === username) {
        res = true;
        return res;
      }
      // debugger;
      try {
        let notification;
        const hasValidLength = /^[a-zA-Z\d]{3,12}$/.test(username);
        const containsLetter = /[a-zA-Z]/.test(username);
        if (!hasValidLength || !containsLetter) {
          this.isUsernameNoValid = true;
          this.isUsernameToUnique = false;
          this.isUsernameUnique = false;
          notification = {
            type: "error",
            message: this.$t("check_valid_or_letter"),
          };
          this.$store.dispatch("alerts/add", notification);
          return res;
        }
        const result = await this.$store.dispatch(
          "checkUsernameUniqueness",
          username
        );
        if (result) {
          this.isUsernameUnique = true;
          this.isUsernameToUnique = false;
          this.isUsernameNoValid = false;
          res = true;
        } else {
          this.isUsernameUnique = false;
          this.isUsernameToUnique = true;
          this.isUsernameNoValid = false;
          notification = {
            type: "error",
            message: this.$t("username_no_uniq"),
          };
          this.$store.dispatch("alerts/add", notification);
        }
        return res;
      } catch (error) {
        console.log(error);
      }
      return res;
    },
    async emailUnique(email) {
      let res = false;
      if (email === null) {
        res = true;
        return res;
      } else if (this.staticUserInfo.email === email) {
        res = true;
        return res;
      }
      if (this.localUserInfo && email && email.trim().length >= 1) {
        let notification;
        try {
          const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
          const isEmailNoValid = emailRegex.test(email);

          if (!isEmailNoValid) {
            this.isEmailNoValid = true;
            this.isEmailToUnique = false;
            this.isEmailUnique = false;
            return res;
          }

          const result = await this.$store.dispatch(
            "checkEmailUniqueness",
            email
          );

          if (result) {
            this.isEmailNoValid = false;
            this.isEmailUnique = true;
            this.isEmailToUnique = false;
            res = true;
          } else {
            this.isEmailNoValid = false;
            this.isEmailUnique = false;
            this.isEmailToUnique = true;
            notification = {
              type: "error",
              message: this.$t("email_no_uniq"),
            };
            this.$store.dispatch("alerts/add", notification);
          }
          return res;
        } catch (error) {
          console.log(error);
          return res;
        }
      } else {
        res = true;
      }
      return res;
    },

    activeInput(fieldName) {
      // const fieldValue = this.getFieldValue(fieldName);
      this.isFieldsDirty = !this.deepEqual(
        this.staticUserInfo,
        this.localUserInfo
      );
      if (fieldName === "tel") {
        this.isTelNotUniq = false;
        this.isTelNotValid = false;
      }
      if (fieldName === "email") {
        this.isEmailNoValid = false;
        this.isEmailUnique = false;
        this.isEmailToUnique = false;
      }
      if (fieldName === "username") {
        this.isUsernameUnique = false;
        this.isUsernameToUnique = false;
        this.isUsernameNoValid = false;
      }
    },
    getFieldValue(fieldName) {
      const fieldNames = fieldName.split(".");
      let value = this.localUserInfo;

      for (const name of fieldNames) {
        value = value[name];
      }

      return value;
    },
    enableEditingUserName() {
      this.editingModeUserName = !this.editingModeUserName;
    },
    enableEditingTel() {
      this.editingModeTel = !this.editingModeTel;
    },
    checkPhoneRules() {
      const phoneNumber = this.localUserInfo.tel;
      let res = false;
      if (!phoneNumber || phoneNumber.trim().length === 0) {
        res = true;
        return res;
      }

      const phoneNumberTrimmed = phoneNumber.trim();
      if (phoneNumberTrimmed.length >= 9) {
        res = true;
        return res;
      } else if (
        phoneNumberTrimmed.length >= 1 &&
        phoneNumberTrimmed.length <= 9
      ) {
        this.toScrollPage();
        this.isTelNotValid = true;
      } else {
        res = true;
      }
      return res;
    },
    async allUpdate() {
      this.loadingFlag = true;
      const userUniq = await this.usernameUnique(this.localUserInfo.username);
      const emailUniq = await this.emailUnique(this.localUserInfo.email);
      const phoneValid = await this.checkPhoneRules();
      //console.log('userUniq', userUniq);
      //console.log('emailUniq', emailUniq);
      //console.log('phoneValid', phoneValid);
      if (userUniq && emailUniq && phoneValid) {
        await this.awaitUpdateInfo();
      } else {
        this.toScrollPage();
      }
      this.loadingFlag = false;
    },
    trimEmptyStrings(obj) {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          if (typeof value === "object") {
            // Recursion
            this.trimEmptyStrings(value);
          } else if (typeof value === "string" && value.trim() === "") {
            obj[key] = null;
          }
        }
      }
    },
    filterNullValues(obj) {
      const newObj = {};
      console.log("obj", obj);
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (key === "tel") {
            newObj[key] = obj[key];
            //if (obj[key] === null) {
            //}
          } else {
            if (obj[key] !== null) {
              if (typeof obj[key] === "object") {
                // Recursion
                newObj[key] = this.filterNullValues(obj[key]);
              } else {
                newObj[key] = obj[key];
              }
            }
          }
        }
      }
      return newObj;
    },
    async awaitUpdateInfo() {
      this.loadingFlag = true;
      console.log(
        "this.localUserInfo.country_code",
        this.localUserInfo.country_code
      );
      let notification;
      this.trimEmptyStrings(this.localUserInfo);
      const trimmedUserInfo = this.filterNullValues(this.localUserInfo);
      console.log("trimmedUserInfo", trimmedUserInfo);
      const [res, err] = await this.$store.dispatch(
        "updateUserInfo",
        trimmedUserInfo
      );
      if (res) {
        // this.successInfoUpdate = true;
        notification = {
          type: "success",
          message: this.$t("success_update"),
        };
        await this.$store.dispatch("awaitGetUserInfo");
        this.isEmailNoValid = false;
        this.isEmailUnique = false;
        this.isTelNotValid = false;
        this.isEmailToUnique = false;
        this.isUsernameUnique = false;
        this.isUsernameToUnique = false;
        this.isUsernameNoValid = false;
        this.isTelNotUniq = false;
        this.editingMode = false;
        this.editingModeUserName = false;
        this.editingModeTel = false;
      } else if (err === "Phone number already exists") {
        this.isTelNotUniq = true;
        this.toScrollPage();
        notification = {
          type: "error",
          message: this.$t("error_check_data"),
        };
      } else {
        notification = {
          type: "error",
          message: this.$t("justError"),
        };
      }
      this.$store.dispatch("alerts/add", notification);
      this.loadingFlag = false;
      this.isFieldsDirty = false;
      // setTimeout(() => {
      //   this.successInfoUpdate = false;
      // }, 3000);
    },
    toScrollPage() {
      this.isFieldsDirty = false;
      const emailField = this.$refs.fields.$el;
      const headerHeight = 70;
      if (emailField) {
        const elementRect = emailField.getBoundingClientRect();
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        window.scrollTo({
          top: elementRect.top + scrollTop - headerHeight,
          behavior: "smooth",
        });
      }
      this.loadingFlag = false;
    },
    async logOut() {
      logOut();
      this.$store.commit("setIsInstaReg", false);
      const remainTime = localStorage.getItem("remainingTimeInSeconds");
      if (remainTime > 0) {
        stopTimer();
        this.$store.dispatch("updateUserInfo", { time_limit: remainTime });
        setTimeout(() => {
          localStorage.removeItem("remainingTimeInSeconds");
          localStorage.removeItem("endTime");
        }, 50);
      }
      await this.$store.dispatch("logOut");
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep input:-webkit-autofill,
::v-deep input:-webkit-autofill:hover,
::v-deep input:-webkit-autofill:focus,
::v-deep input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--light-flamingo) inset !important;
  position: relative;
  padding-top: 2px !important;
  margin-top: 6px !important;
}
.pen__change-t {
  @media (any-hover: hover) {
    cursor: pointer;
  }
}

.col__padding-top {
  @media (max-width: 479px) {
    padding-top: 0;
  }
}

.col__padding-bottom {
  @media (max-width: 479px) {
    padding-bottom: 0;
  }
}

.tel {
  &::after {
    content: "+";
    position: absolute;
    width: 10px;
    height: 10px;
    top: 9px;
    left: 12px;
  }

  .v-input__control {
    .v-input__slot {
      padding-left: 20px;
    }
  }
}

.info__btn {
  &_green {
    min-width: 160px !important;
  }
}

.player-image-wr {
  position: relative;
  //background-color: var(--red);
  cursor: pointer;
  //margin-left: 5px;
  .player-image {
    width: 80px;
    height: 80px;
    transition: transform 0.3s;
    border: 1px solid var(--red);
    border-radius: 16px;
    overflow: hidden;
  }
  img {
    color: var(--white);
    display: flex;
    max-width: 100%;
    max-height: 100%;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    z-index: 5;
    border-radius: 16px;
    background-color: var(--red);
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: -5px;
    transition: all 0.3s;
    right: -5px;
  }
  @media (any-hover: hover) {
    &:hover {
      span {
        transform: rotate(-90deg);
      }
    }
  }
}
.user-info {
  display: flex;
  @media (min-width: 993px) {
    align-items: flex-start;
    gap: 30px;
  }
  @media (max-width: 992px) {
    flex-direction: column;
    gap: 15px;
  }
}
.general-info {
  display: flex;
  flex-direction: column;
  background-color: var(--dark-flamingo) !important;
  border-radius: 16px !important;
  @media (min-width: 993px) {
    flex: 0 1 400px;
  }
}
.change-data {
  background-color: var(--flamingo-for-red) !important;
  border-radius: 16px !important;
  flex: 1 1 auto;
}
.status_text {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  @media (max-width: 850px) {
    font-size: 12px;
    line-height: 1.5;
  }
}
</style>
