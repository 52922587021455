<template>
  <v-container>
    <v-col class="title__link-v" style="max-width: 700px; margin: 0 auto">
      <v-row>
        <v-col class="title__link" style="">
          <div
            style="
              display: flex;
              color: var(--white);
              justify-content: center;
              align-items: center;
            "
          >
            <v-icon color="white" style="margin-right: 3px">
              account_balance
            </v-icon>
            {{ $i18n.t("balance") }}
          </div>
          <v-divider dark style="margin: 5px 0"></v-divider>
        </v-col>
      </v-row>
    </v-col>
    <div class="balanceCard">
      <v-row class="balanceCard__title">
        <p>{{ $i18n.t("balanceCurrency") }}:</p>
        <b>
          {{ $store.getters.getUserInfo.currency }}
        </b>
      </v-row>
      <v-row class="balanceCard__row">
        <v-col class="balanceCard__col">
          <div class="balanceCard__quanity">
            <b style="font-weight: normal" class="balanceCard__balance">
              {{ $store.getters.getUserInfo.balance }}
            </b>
            <b class="balanceCard__currency">
              {{ $store.getters.getUserInfo.currency }}
            </b>
          </div>
        </v-col>
        <v-col class="balanceCard__actions">
          <v-btn
            dark
            class="all-buttons-t balanceCard__button"
            to="/profile/cashbox/withdraw"
          >
            {{ $i18n.t("deposit2") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="balanceCard__row">
        <v-col class="balanceCard__col">
          <div class="balanceCard__content">
            <span>{{ $i18n.t("withdrawAvaliableAmount") }}:</span>
          </div>
          <div class="balanceCard__quanity">
            <b class="balanceCard__balance">
              {{ $store.getters.getUserInfo.balance }}
            </b>
            <b class="balanceCard__currency">
              {{ $store.getters.getUserInfo.currency }}
            </b>
          </div>
        </v-col>
        <v-col class="balanceCard__actions">
          <v-btn
            dark
            class="all-buttons-t balanceCard__button"
            to="/profile/cashbox/withdraw"
          >
            {{ $i18n.t("withdraw") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!--<v-row class="balanceCard_row">
      <v-col  class="info_col">
        <v-icon color="var(&#45;&#45;main-yellow)" size="30" style="transform: rotate(45deg)">
          star
        </v-icon>
        {{$i18n.t('bonuses')}}: <b style="font-weight: normal" class="yellow_text">{{$store.getters.getUserInfo.bonusBalance}}</b>
        <b style="font-family: 'Helvetica Neue';color: var(&#45;&#45;main-yellow)">
          {{ $store.getters.getUserInfo.currency }}
        </b>
      </v-col>
      <v-col class="button_col">
        <v-btn dark class="balance__btn" style="width: 100%"  to="/profile/promo/bonus">
          {{$i18n.t('details')}}
        </v-btn>
      </v-col>
    </v-row>-->
  </v-container>
</template>

<script>
export default {
  name: "Balance",
};
</script>

<style lang="scss" scoped>
.balanceCard {
  max-width: 600px;
  margin: 0 auto;
  color: var(--white);
  &__title {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: var(--white);
    text-transform: uppercase;
    margin-top: 10px;

    justify-content: space-between;
    align-items: center;
    @media (max-width: 992px) {
      margin-top: 20px;
    }
    p {
      padding: 12px;
      margin: 0;
    }
    b {
      padding: 12px;
    }
  }
  &__row {
    display: flex;
    flex-direction: column;
  }

  &__col {
    align-items: center;
  }

  &__content {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;
  }

  &__image {
    margin-right: 5px;
  }

  &__quanity {
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    font-size: 50px;
    line-height: 1;
  }

  &__balance {
    font-weight: normal !important;
  }

  &__currency {
    font-weight: normal !important;
  }

  &__actions {
    padding: 0 12px;
    margin-bottom: 15px;
  }

  &__button {
    width: 100%;
    font-size: 16px !important;
  }
}
.all-buttons-t {
}
</style>
