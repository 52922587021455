<template>
  <div :class="needClass">
    <div class="flipper">
      <div class="front">
        <slot name="front"></slot>
      </div>
      <div class="back">
        <slot name="back"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "levelFlip",
  props: ["index"],
  data() {
    return {
      needClass: "flip-container",
    };
  },
  watch: {
    flipClass() {
      if (this.$store.state.levels.lvlItems[this.index].flip) {
        this.needClass = "flip-container flipped";
        setTimeout(() => {
          this.needClass = "flip-container flipped flipped-display";
        }, 125);
      } else {
        this.needClass = "flip-container";
      }
    },
  },
  computed: {
    flipClass() {
      return this.$store.state.levels.lvlItems[this.index].flip;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.front {
  position: relative;
}
.flip-container {
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  -o-perspective: 1000px;
  perspective: 1000px;
  display: flex;
  flex: 1 1 100%;
  transition: all 0.3s;
}
.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  position: relative;
  display: flex;
  width: 100%;
}
.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: all 0.6s;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  width: 100%;
}
.back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  position: absolute;
  .flipped-display & {
    position: static;
  }
}
.flip-container.flipped .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.flip-container.flipped .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.front {
  z-index: 2;
  .flipped-display & {
    //display: none;
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
}
</style>
