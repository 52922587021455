<template>
  <div class="wrapper-alert">
    <transition-group name="notification">
      <v-alert
        border="left"
        class="custom-alert"
        v-for="item in notificationArray"
        :key="item.id"
        :type="item.type"
        dense
        :class="getClassObject(item)"
      >
        <div class="d-flex flex-column">
          <span>{{ item.message }}</span>
          <small>
            {{ item.detail }}
          </small>
        </div>
      </v-alert>
    </transition-group>
    <div v-if="!hideNotif.includes($route.name)">
      <transition-group name="notification">
        <v-alert
          border="left"
          v-for="Eitem in eternalNotificationArray"
          class="eternal-notif"
          :key="Eitem.id"
          @click="eternalClick(Eitem)"
          :type="Eitem.type"
          dense
          :class="getClassObject(Eitem)"
        >
          <span class="eternal-notif__message">{{ Eitem.message }}</span>
          <v-icon class="eternal-notif__close" @click="toDeleteEternal(Eitem)"
            >close</v-icon
          >
        </v-alert>
      </transition-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alerts",
  data() {
    return {
      hideNotif: [
        "DepositMainPage",
        "CashoutPage",
        "Game",
        "ProfileInfo",
        "Sport",
      ],
    };
  },
  computed: {
    notificationArray() {
      return this.$store.state.alerts.alerts;
    },
    eternalNotificationArray() {
      return this.$store.state.alerts.eternalNotifications;
    },
  },
  methods: {
    getClassObject(item) {
      return {
        notification: item.type,
        _eternal: item.click && item.click.length > 0,
      };
    },
    eternalClick(Eitem) {
      if (Eitem.click === "goToProfile") {
        if (this.$route.path !== "/profile/info/data") {
          this.$router.push({
            path: "/profile/info/data",
            params: {
              redirectByToast: true,
            },
          });
        }
      }
      this.$store.dispatch("alerts/deleteEternal", Eitem);
    },
    toDeleteEternal(item) {
      localStorage.setItem("deletedNotification", JSON.stringify(item));
      // console.log('item', localStorage.getItem('deletedNotification'));
      this.$store.dispatch("alerts/deleteEternal", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-alert {
  position: fixed;
  width: 360px;
  height: 500px;
  z-index: 209;
  top: 0px;
  right: 0;
  pointer-events: none;
  .is-email-confirmation-bar-visible & {
    top: 15px;
    @media (max-width: 992px) {
      top: 30px;
    }
  }
}

.notification {
  //position: absolute;
  pointer-events: all;
  top: 55px;
  color: var(--white);
  right: -8px;
  width: calc(100% - 30px);
  margin-bottom: 8px;
  z-index: 2;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s, visibility 0.3s, top 0.3s;
  @media (min-width: 500.98px) {
    width: 350px;
    right: 10px;
  }
  @media (min-width: 959.98px) {
    top: 75px;
  }
  @media (min-width: 992.98px) {
    top: 85px;
  }
  &._eternal {
    cursor: pointer;
  }
}
.notification-enter-active,
.notification-leave-active {
  transition: opacity 0.3s, top 0.3s linear;
}

.notification-enter,
.notification-leave-to {
  opacity: 0;
  top: 65px;
  @media (min-width: 959.98px) {
    top: 85px;
  }
  @media (min-width: 992.98px) {
    top: 95px;
  }
}

.notification-leave-active {
  opacity: 0;
  top: 65px;
  @media (min-width: 959.98px) {
    top: 85px;
  }
  @media (min-width: 992.98px) {
    top: 95px;
  }
}

.notification-enter-to,
.notification-leave {
  opacity: 1;
  top: 55px;
  @media (min-width: 959.98px) {
    top: 75px;
  }
  @media (min-width: 992.98px) {
    top: 85px;
  }
}
</style>
