<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "Tournament",
};
</script>

<style lang="scss" scoped></style>
