import axios from "axios";

const state = {
  progressValue: 0, // Progress percentage
  nextBonusAmount: 0, // Amount required for the next bonus
  betAmount: 0, // Total bets
  lvlItems: [], // Levels data
};

const mutations = {
  SET_LVL_ITEMS(state, lvlItems) {
    state.lvlItems = lvlItems;
  },
  TOGGLE_CARD(state, index) {
    if (state.lvlItems[index]) {
      state.lvlItems[index].flip = !state.lvlItems[index].flip;
    }
  },
  CLOSE_CARD(state, index) {
    if (state.lvlItems[index]) {
      state.lvlItems[index].flip = false;
    }
  },
  SET_NEXT_BONUS_AMOUNT(state, amount) {
    state.nextBonusAmount = amount;
  },
  SET_BET_AMOUNT(state, amount) {
    state.betAmount = amount;
  },
  UPDATE_PROGRESS_VALUE(state) {
    if (state.nextBonusAmount > 0 && state.betAmount > 0) {
      state.progressValue = (state.betAmount / state.nextBonusAmount) * 100;
    } else {
      state.progressValue = 0;
    }
  },
};

const getters = {
  progressValue: (state) => state.progressValue,
  lvlItems: (state) => state.lvlItems,
  nextBonusAmount: (state) => state.nextBonusAmount,
  betAmount: (state) => state.betAmount,
};

const actions = {
  // Initialize levels locally
  initializeLevels({ commit }, { levels, currentLevel, userCurrency }) {
    const lvlItems = levels.map((level) => ({
      ...level,
      next_level_amount: level.next_level_amount,
      showIcon: level.order === currentLevel,
      flip: false,
    }));
    commit("SET_LVL_ITEMS", lvlItems);

    // Set data for the next level
    const nextLevel = Number(currentLevel) + 1;
    const nextLevelData = lvlItems.find((level) => level.order === nextLevel);
    const nextBonusAmount = nextLevelData ? nextLevelData.next_level_amount : 0;

    commit("SET_NEXT_BONUS_AMOUNT", nextBonusAmount);
    commit("UPDATE_PROGRESS_VALUE");
  },

  // Fetch levels from the server
  async awaitGetLevels({ commit, rootGetters }, currentLevel) {
    try {
      const userCurrency = rootGetters["getUserInfo"].currency;
      const response = await axios.get("/api/levels", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      const lvlItems = response.data
        .map((level) => ({
          ...level,
          next_level_amount: level.next_level_amount[userCurrency],
          showIcon: level.order === currentLevel,
          flip: false,
        }))
        .filter((level) => typeof level.next_level_amount !== "undefined");

      commit("SET_LVL_ITEMS", lvlItems);

      // Set data for the next level
      const nextLevel = Number(currentLevel) + 1;
      const nextLevelData = lvlItems.find((level) => level.order === nextLevel);
      const nextBonusAmount = nextLevelData
        ? nextLevelData.next_level_amount
        : 0;
      commit("SET_NEXT_BONUS_AMOUNT", nextBonusAmount);
      commit("UPDATE_PROGRESS_VALUE");
    } catch (error) {
      console.error("Error fetching levels:", error);
    }
  },

  // Fetch user's total bet amount
  async fetchBetAmount({ commit }, userId) {
    try {
      const response = await axios.get(
        `/stat-api/game_sessions/sum_bet_by_user_id/${userId}`
      );
      const betAmount = response.data || 0;

      commit("SET_BET_AMOUNT", betAmount);
      commit("UPDATE_PROGRESS_VALUE");
    } catch (error) {
      console.error("Error fetching bet amount:", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
