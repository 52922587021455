<template>
  <FlipCardTournament :flipped="tournament.flipped">
    <template slot="front">
      <BonusTournamentFront
        @show-tournament-dialog="toShowTournamentDialog"
        :state="state"
        :tournament="tournament"
      ></BonusTournamentFront>
    </template>
    <template slot="back">
      <BonusTournamentBack
        :state="state"
        :tournament="tournament"
      ></BonusTournamentBack>
    </template>
  </FlipCardTournament>
</template>

<script>
import BonusTournamentBack from "./BonusTournamentBack.vue";
import BonusTournamentFront from "./BonusTournamentFront.vue";
import FlipCardTournament from "./FlipCardTournament.vue";
export default {
  name: "BonusTournament",
  components: {
    BonusTournamentFront,
    BonusTournamentBack,
    FlipCardTournament,
  },
  props: ["tournament", "state"],
  data() {
    return {};
  },
  methods: {
    toShowTournamentDialog() {
      this.$emit("show-tournament-dialog", this.tournament);
    },
  },
};
</script>

<style lang="scss" scoped></style>
