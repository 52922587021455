<template>
  <div class="header_wrapper">
    <Aside />

    <HeaderNotifications />
    <v-app-bar
      fixed
      v-if="headerVisible"
      class="app__bar d-block"
      height="56px"
      style="z-index: 110"
      :style="{ marginTop: `${Number(headerNotifLength)}px` }"
    >
      <button
        type="button"
        @click="openMenu"
        class="drawer_button all-shadow-hover"
      >
        <font-awesome-icon icon="list" style="color: var(--white)" />
      </button>
      <button
        type="button"
        @click="openMenu"
        class="drawer-button-pc all-shadow-hover"
        :class="{ 'drawer-button-pc_active': isPcMenuActive }"
      >
        <span></span>
      </button>
      <v-col style="" class="desktop logo logo_pc">
        <router-link to="/">
          <img
            :src="`https://minio.takemybet.pro/ui-style/${logo}`"
            class="brand__logo brand__logo_pc"
            @click="scrollToTop"
          />
        </router-link>
      </v-col>
      <v-col
        class="header_logo_mobile pa-0"
        :class="{ header_logo_mobile_nologin: user.id === null }"
      >
        <a href="/">
          <img
            :src="`https://minio.takemybet.pro/ui-style/${logo}`"
            width="150"
            height="150"
            class="brand__logo_pc"
          />
        </a>
      </v-col>
      <div
        v-click-outside="clickOutsideInput"
        class="all-shadow-hover search search-pc"
        @click="openSearchMenu"
        :style="{
          'background-color': searchPC
            ? 'var(--light-flamingo)'
            : 'var(--dark-flamingo)',
        }"
      >
        <v-icon class="mr-2" color="white"> search </v-icon>

        <v-text-field
          hide-details
          ref="searchInput"
          :placeholder="$i18n.t('searchPlaceHolder')"
          background-color="none"
          dense
          color="var(--white)"
          class="search-pc__input"
          @input="debounceFilters"
          v-model.lazy="searchGame"
          @click="openSearchNavbar"
        >
        </v-text-field>
        <search-navbar
          :loadingFlag="loadingSearch"
          :isClickedInput="isClickedInput"
          @clear-input="clearSearchInput"
          v-if="searchPC"
        />
      </div>

      <v-col class="header_buttons_col" v-if="user.id == null">
        <v-row style="margin: 0; align-items: center">
          <v-col
            style="padding: 0px; text-align: right !important"
            class="header__buttons-t"
          >
            <div style="flex: 1 1 100%">
              <router-link to="/reg" style="text-decoration: none">
                <v-btn
                  style=""
                  class="all-buttons-t header_regButton header-mobile-reg"
                >
                  <div
                    v-if="$i18n.locale === 'en'"
                    class="header_regButton_star"
                  >
                    <img
                      src="@/assets/images/white-heart.svg"
                      width="15px"
                      height="15px"
                      class="white-heart"
                    />
                  </div>
                  <div class="header-mobile-reg__text">
                    {{ $i18n.t("registration") }}
                  </div>
                </v-btn>
              </router-link>
            </div>

            <v-btn
              style=""
              class="all-buttons-t all-buttons-t_enter header_enterButton header-mobile-log"
              @click="signIn"
            >
              <div class="header-mobile-log__text">
                {{ $i18n.t("enter") }}
              </div>
            </v-btn>
          </v-col>
          <div
            class="px-0 py-0 header-search-button all-shadow-hover search search-mobile"
            @click="openSearchMenu"
          >
            <v-icon color="white" class="icons__buttons icons__buttons-search">
              search
            </v-icon>
          </div>
        </v-row>
      </v-col>
      <v-row class="registred_buttons_dt" v-if="user.id != null">
        <v-row style="margin-left: auto" class="registred_buttons_dt">
          <div class="user-stack">
            <v-col
              v-for="(item, index) in headerButtons"
              :key="index"
              style=""
              class="py-0 pr-0 d-flex"
            >
              <component :is="item.component"></component>
            </v-col>
            <v-col class="status_col text-right py-0">
              <span @click="$router.push('/profile/promo-my/level')"
                >{{ $i18n.t("lvl") }}: <b>{{ user.level.order }} </b></span
              >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-progress-linear
                    @click="achieveRedirect"
                    rounded
                    style="
                      border-radius: 16px;
                      width: 120px;
                      margin-left: auto;
                      border: solid 1px var(--red);
                    "
                    height="12px"
                    background-color="none"
                    :value="progressValue"
                    color="var(--red)"
                    v-on="on"
                  >
                  </v-progress-linear>
                  <!--  -->
                </template>
                <span
                  >{{ $i18n.t("level") }}: <b>{{ user.level.order }} </b></span
                >
              </v-tooltip>
            </v-col>
          </div>
          <v-col class="text-right py-0 pr-0" style="max-width: max-content">
            <router-link to="/deposit" style="text-decoration: none">
              <v-btn style="" class="all-buttons-t header_regButton">
                <v-col class="balance_col pa-0">
                  {{ user.balance }}{{ user.currency }}
                </v-col>
                <v-col
                  v-if="user.id !== null"
                  style="word-break: break-all"
                  class="header_regButton_balance balance_mobile pa-0"
                >
                  {{ user.balance }}
                  <p class="mb-0">{{ user.currency }}</p>
                </v-col>

                <div class="header_regButton_plus">
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="#fff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-plus-circle"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="16"></line>
                      <line x1="8" y1="12" x2="16" y2="12"></line>
                    </g>
                  </svg>
                </div>
              </v-btn>
            </router-link>
          </v-col>
          <button
            type="button"
            class="header-search-button all-shadow-hover search search-mobile"
            @click="openSearchMenu"
          >
            <v-icon color="white" class="icons__buttons icons__buttons-search">
              search
            </v-icon>
          </button>
        </v-row>
      </v-row>
      <v-col
        class="header_buttons_col registred_buttons_mobile"
        v-if="user.id != null"
        style="padding-left: 0px"
      >
        <v-row style="margin: 0 auto">
          <v-col style="padding: 0px; text-align: right !important">
            <router-link to="/deposit" style="text-decoration: none">
              <!-- <v-btn style="" class="header_regButton">
                        {{ $i18n.t('deposit1') }}
                      </v-btn> -->
              <v-btn style="" class="all-buttons-t header_regButton">
                <v-col
                  v-if="user.id !== null"
                  style="padding: 0; word-break: break-all"
                  class="header_regButton_balance balance_mobile"
                >
                  {{ user.balance }}
                  <p style="margin-bottom: 0">{{ user.currency }}</p>
                </v-col>

                <div class="header_regButton_plus">
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="#fff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-plus-circle"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="16"></line>
                      <line x1="8" y1="12" x2="16" y2="12"></line>
                    </g>
                  </svg>
                </div>
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>

      <AlertSuccess></AlertSuccess>

      <AlertInfo></AlertInfo>
      <AlertError></AlertError>
    </v-app-bar>
    <mobile-search></mobile-search>

    <header-menu />

    <!-- <transition name="slide">
      <v-card
        style=""
        v-if="iosTooltipFlag"
        class="helpTooltip_card siteBg"
        dark
      >
        <v-card-title class="pb-0">
          <v-row align="center">
            <v-col class="pa-0" style="white-space: nowrap; font-size: 17px">
              {{ $i18n.t("downloadIosPopupTitle") }}
            </v-col>
            <v-col style="max-width: max-content">
              <v-icon size="25pt" @click="iosTooltipFlag = false">close</v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-row align="center">
            <v-col class="pa-0">
              {{ $i18n.t("downloadIosPopup1") }}
              <img
                src="@/assets/images/Safari-icon.png"
                height="50"
                width="50"
              />
              <br />
              {{ $i18n.t("downloadIosPopup2") }}
              <img src="@/assets/images/share_ios.png" height="42" width="30" />
              <br />
              {{ $i18n.t("downloadIosPopup3") }}
              <img src="@/assets/images/iphone_screen1.jpg" width="100%" />
              {{ $i18n.t("downloadIosPopup4") }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </transition>
    <transition name="slide">
      <v-card
        style=""
        v-if="androidTooltipFlag"
        class="helpTooltip_card siteBg"
        dark
      >
        <v-card-title class="pb-0">
          <v-row align="center">
            <v-col class="pa-0" style="white-space: nowrap; font-size: 17px">
              {{ $i18n.t("downloadIosPopupTitle") }}
            </v-col>
            <v-col style="max-width: max-content">
              <v-icon size="25pt" @click="androidTooltipFlag = false"
                >close</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-row align="center">
            <v-col class="pa-0">
              {{ $i18n.t("downloadIosPopup1") }}
              <img
                src="@/assets/images/Safari-icon.png"
                height="50"
                width="50"
              />
              <br />
              {{ $i18n.t("downloadIosPopup2") }}
              <img src="@/assets/images/share_ios.png" height="42" width="30" />
              <br />
              {{ $i18n.t("downloadIosPopup3") }}
              <img src="@/assets/images/iphone_screen1.jpg" width="100%" />
              {{ $i18n.t("downloadIosPopup4") }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </transition> -->
  </div>
</template>

<script>
import HeaderMenu from "./HeaderMenu";

import MobileSearch from "@/components/SearchGames/MobileSearch";
import searchNavbar from "@/components/SearchGames/searchNavbar";
import AlertSuccess from "@/components/Profile/Alerts/AlertSuccess";
import AlertInfo from "@/components/Profile/Alerts/AlertInfo";
import AlertError from "@/components/Profile/Alerts/AlertError";
import Aside from "@/components/Header/Aside";
import HeaderNotifications from "@/components/Profile/Alerts/HeaderNotifications";
import { mapGetters } from "vuex";
import { isAndroid, isIOS, isSafari } from "mobile-device-detect";
import { debounce } from "lodash";
import NotificationDropdown from "@/components/Header/HeaderButtons/notificationDropdown";
import UserDropdown from "@/components/Header/HeaderButtons/userDropdown";
// names
import axios from "axios";
export default {
  name: "Header",
  components: {
    MobileSearch,
    searchNavbar,
    AlertSuccess,
    AlertInfo,
    AlertError,
    Aside,
    HeaderNotifications,
    NotificationDropdown,
    UserDropdown,
    HeaderMenu,
  },
  data() {
    return {
      navDrawer: false,
      isPcMenuActive: false,
      //user: this.user,
      iosTooltipFlag: false,
      headerButtons: [
        {
          component: NotificationDropdown,
        },
        {
          component: UserDropdown,
        },
      ],
      androidTooltipFlag: false,
      searchGame: "",
      mobileSearchEmpty: true,
      isClickedInput: false,
      isClickedOutside: false,
      isOpen: false,
      isNotif: false,
      isVerification: false,
      loadingSearch: false,
      isOpenCash: false,
      isOpenInfo: false,
      isOpenBonuses: false,
      isResponsibleGamblingOpen: false,
      menuOpen: false,
      isAllPromotionsOpen: false,
      isMenuOpen: false,
      selectedItem: null,
      mobileSearch: false,
    };
  },
  computed: {
    ...mapGetters({
      logo: "styles/getLogo",
      headerVisible: "headerVisible",
      user: "getUserInfo",
      screenParams: "screenParams",
    }),
    searchPC() {
      return this.$store.state.search.searchPC;
    },
    headerNotifLength() {
      if (this.screenParams.width > 992) {
        return this.$store.state.alerts.headerNotifications.length * 33;
      } else {
        return this.$store.state.alerts.headerNotifications.length * 80;
      }
    },
    nextBonusAmount() {
      return this.$store.state.levels.nextBonusAmount;
    },
    betAmount() {
      return this.$store.state.levels.betAmount;
    },
    progressValue() {
      if (this.nextBonusAmount > 0 && this.betAmount != null) {
        return (this.betAmount / this.nextBonusAmount) * 100;
      }
      return 0;
    },
    successWagerUpdate() {
      return this.$store.state.successWagerUpdate;
    },
    infoWagerUpdate() {
      return this.$store.state.infoWagerUpdate;
    },
  },
  created() {
    this.$root.$on("gameClicked", this.gameSearchCleanup);
  },
  methods: {
    scrollToTop() {
      if (this.$route.path === "/") {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    redirectToLink(link) {
      console.log(link);
      this.$router.push(link);
    },
    //========================================================================================================================================================
    signIn() {
      if (this.$route.path !== "/enter") {
        this.$router.push("/enter");
      }

      this.$store.commit("setSignInModal", true);
    },
    //========================================================================================================================================================
    // Languages select
    changeLanguage(code) {
      const isCurrentLang = localStorage.getItem("locale");
      if (isCurrentLang !== code.language_code) {
        this.$store.dispatch(
          "localization/awaitGetSelectLocal",
          code.language_code
        );
        location.reload();
      }
      //location.reload();
    },
    //========================================================================================================================================================
    // Open Burger Menu
    openMenu() {
      this.navDrawer = true;
      this.$store.commit("SET_NAV_DRAWER", true);
      //this.clearAnimations();
      //this.animateRowComponents();
    },
    //========================================================================================================================================================
    // Play bonus game
    openSearchNavbar() {
      this.$store.commit("search/setSearchPC", true);
      this.isClickedInput = true;
    },
    clickOutsideInput() {
      if (this.searchPC) {
        this.isClickedInput = false;
      }
    },
    gameSearchCleanup() {
      this.searchGame = "";
      this.mobileSearchEmpty = true;
    },
    async clearGames() {
      await this.$store.dispatch("awaitGetGames", { provider: "", game: "" });
    },
    clearSearchInput() {
      this.searchGame = "";
    },
    debounceFilters: debounce(async function () {
      await this.filterGames();
    }, 750),
    async filterGames() {
      let gameName = this.searchGame;
      if (gameName.trim() === "") {
        this.mobileSearchEmpty = true;
      } else {
        this.mobileSearchEmpty = false;
      }
      this.loadingSearch = true;
      this.selectedGamesFilter = "";
      if (gameName === null) {
        gameName = "";
      }
      this.$store.commit("search/setSearchName", gameName);

      await this.$store.dispatch("gameModule/getSearchedGames", {
        name: gameName,
      });
      this.loadingSearch = false;
    },
    openSearchMenu() {
      this.$store.commit("search/setSearchMobile", true);
    },
    //========================================================================================================================================================
    // Menu List Open
    //========================================================================================================================================================
    // Logout
    //========================================================================================================================================================

    //========================================================================================================================================================
    //========================================================================================================================================================
    async redirectToRouteThree(pathThree) {
      this.navDrawer = false;
      setTimeout(() => {
        this.isOpenCash = false;
        this.isOpen = false;
        this.isResponsibleGamblingOpen = false;
        this.isMenuOpen = false;
        this.isNotif = false;
        this.isOpenInfo = false;
      }, 350);

      try {
        await this.$router.push(pathThree);
      } catch (error) {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      }
    },
    async achieveRedirect() {
      await this.redirectToRouteThree("/profile/promo-my/level");
    },
    //========================================================================================================================================================
    //========================================================================================================================================================
    //  Dump
    //async downloadCasino() {
    //  if (isIOS) {
    //    this.iosTooltipFlag = true;
    //  } else if (isAndroid) {
    //    //this.androidTooltipFlag = true;
    //    //const win = window.open(this.$store.getters.apkUrl, '_blank');
    //    //win.focus();
    //  }
    //},

    //telegramRedirect() {
    //  window.location.href = 'https://t.me/joinchat/AAAAAFEjZCGfsC08QS38ow'
    //},

    //changeHeaderVisability() {
    //this.$store.commit('set', { type: 'headerVisible', items: !this.headerVisible });
    /*let routerContainer = document.getElementById('routerView');
      console.log('router ', routerContainer)
      if (routerContainer !== null) {
        if (this.headerVisible) {
          routerContainer.style.paddingTop = '0px!important';
        }
      }*/

    //}
  },
};
</script>

<style lang="scss" scoped>
//

.search {
  &-mobile {
    @media (min-width: 992.98px) {
      display: none !important;
    }
  }
  &-pc {
    @media (max-width: 992px) {
      display: none !important;
    }
    align-items: center;
    color: var(--white);
    position: relative;
    border-radius: 6px;
    display: flex;
    height: 36px;
    padding: 10px !important;
    max-width: 500px;
    flex: 1 1 auto;
    margin-left: 15px;
    &__input {
    }
    i {
      font-size: 24px;
    }
  }
}

.user-stack {
  display: flex;
  align-items: center;
  @media (max-width: 992px) {
    display: none;
  }
}

.menu-wrapper {
  position: relative;
  display: inline-block;
}

.menu-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--white);
  font-weight: 600;
}

.menu-item {
  padding: 8px 16px;
  cursor: pointer;
}

.menu-item:hover {
}

.v-expansion-panel:before {
  box-shadow: none;
}

.hover-underline {
  text-decoration: none;
  transition: all 0.3s;

  @media (any-hover: hover) {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.svg-inline--fa {
  margin-left: 5px;
}

.brand {
  &__logo {
    &_pc {
      width: 200px;
      height: 50px;

      @media (max-width: 700px) {
        width: 160px;
        height: 50px;
      }
    }
  }
}

.v-list-item__content {
  margin: 0 !important;
}

.theme--light.v-list {
  background-color: var(--main-flamingo) !important;
  box-shadow: 0 0 5px var(--main-flamingo);
  border: 2px solid var(--red);
}

.v-input {
  .v-app-bar--is-scrolled & {
  }

  &.v-select--is-menu-active {
    border: 2px solid transparent !important;
  }
}

.v-input__slot {
  @media (any-hover: hover) {
    &:hover {
      border: none !important;
    }
  }
}

.desktop {
  max-width: 80px;
  min-width: 80px;
  align-self: center;

  //margin: 8px 0 0 0;
  @media (max-width: 992.98px) {
    display: none !important;
  }
  @media (max-width: 430px) {
    // margin: 15px 0 0 0;
    max-width: 180px;
    min-width: 180px;
  }
}

.helpTooltip_card {
  position: fixed;
  bottom: 0;
  height: 400px;
  width: 100vw;
  z-index: 2222;
}

.custom-height .v-select__selections {
  max-height: 6px;
  /* Change this to your preferred height */
}

.selectMenuClass {
  border: solid 1px var(--red);
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.1rem;
}

.siteBg {
  //background: url('../assets/images/mafia_site_bg.png');
}

.welcome-enter {
  transform: translateX(10px);
  opacity: 0;
}

.welcome-enter-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.welcome-leave-active,
.welcome-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: margin-bottom 0.8s ease-out;
}

/*
  you set the css property before transition starts
  */
.slide-enter,
.slide-leave-to {
  margin-bottom: -400px;
}

/*
  you set the css property it will be when transition ends
  */
.slide-enter-to,
.slide-leave {
  margin-bottom: 0px;
}

.headerHideBtn {
  position: fixed;
  background-color: var(--red);
  left: 0px;
  top: 0px;
  z-index: 10;
}

.visibleBtnPos {
  top: 100px !important;
}

.invisibleBtnPos {
  top: 50px !important;
}

.header_wrapper {
  overflow-x: hidden !important;
}

.header_logo_mobile {
  display: none;
  max-width: max-content;
  z-index: 5;
  left: 90px;
  @media (max-width: 500px) {
    left: 80px;
  }

  @media (max-width: 992.98px) {
    display: block;
    position: absolute;

    transform: translate(-50%, 0px);
    top: 5px;
  }
  @media (max-height: 500px) and (orientation: landscape) {
    left: 75px;
  }
  &_nologin {
    @media (max-width: 992px) {
      left: 50%;
    }
  }
}
.logo {
  padding: 0;
  &_pc {
    position: relative;
    top: 18px;
  }
}
.brand__logo_pc {
  width: 80px;
  height: 80px;

  @media (max-width: 500px) {
    width: 65px;
    height: 65px;
  }
  @media (max-height: 500px) and (orientation: landscape) {
    width: 65px;
    height: 65px;
  }
}

.app__bar {
  background-color: var(--main-flamingo) !important;
  transition: all 0.3s;
  padding: 0 10px;
  display: flex;
  align-items: center;
}
.drawer__button {
  border-color: yellow;
  width: 40px !important;
  height: 40px !important;
  min-width: 42px;
}

.sub-menu__item {
  background-color: var(--main-flamingo) !important;
}

.brand__logo {
  top: 3px;
  position: relative;
  width: 80px;
  height: 80px;

  @media (max-width: 992.98px) {
    display: none;
  }
}

.badge_class {
  span {
    .v-badge__wrapper {
      color: black;

      .v-badge__badge {
        color: black !important;
      }
    }
  }
}

.balance_col {
  color: var(--white);
  font-weight: 600;
  font-size: 16px;
}

.status_col {
  cursor: pointer;
  color: var(--white);
  display: flex;
  flex-direction: column;
}

.header_row {
  //padding-top: 26px;
  .header_buttons_col {
    text-align: center;
  }
}

.header_regButton {
  &_balance {
    font-weight: 500 !important;
  }

  &_plus {
    width: 20px;
    height: 20px;

    @media (max-width: 700px) {
      width: 18px;
      height: 18px;
    }
    @media (min-width: 1140.98px) {
      margin-left: 10px;
    }
    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &_nav {
    padding: 0 15px !important;
    font-size: 14px !important;
  }
}

.balance_mobile {
  display: none;

  @media (max-width: 1140px) {
    max-width: none !important;
    width: auto !important;
    display: block;
    font-size: 16px;
    display: flex;
    color: var(--white);
    justify-content: flex-start;
    align-items: center;
    flex: 0 1 auto;
    margin: 0 5px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
  }
}

.header_regButton_star {
  margin: 0 5px 0 0;
  display: flex;
  align-items: center;
}

.header_enterButton {
  margin-left: 10px;
  min-width: 80px !important;
}

.header_enterButton_margin {
  margin-left: 10px;

  @media (max-width: 475px) {
    margin-left: 5px;
  }

  &_nav {
    margin: 0 15px 0 0;
    padding: 0 15px !important;
    font-size: 14px !important;
  }
}
//========================================================================================================================================================
.header-mobile-reg {
  @media (min-width: 320px) {
  }
  @media (max-width: 500px) {
    max-width: 100px;
    width: 100%;
  }
  &__text {
    @media (max-width: 500px) {
      font-size: 10px;
    }
  }
}
.header-mobile-log {
  @media (min-width: 320px) {
  }
  @media (max-width: 500px) {
    max-width: 95px;
    width: 100%;
  }

  &__text {
    @media (max-width: 500px) {
      font-size: 10px;
    }
  }
}

.header-search-button {
  margin-left: 10px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--red);
  flex: 0 0 36px;
  cursor: pointer;
  height: 36px;
  @media (max-width: 768px) {
    margin-left: 5px;
  }
  .v-icon {
    width: auto;
    height: auto;
    margin: 0px;
    font-size: 24px !important;
  }
}

.header_enterTitle {
  font-size: 14px;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-right: 5px;
  font-weight: 100 !important;
}

.registred_buttons_mobile {
  display: none;
}

.registred_buttons_dt {
  justify-content: flex-end;
  align-items: center;
  margin: 0 !important;
}

@media (max-width: 992px) {
  .player_col {
    display: none;
  }

  .status_col {
    display: none;
  }
}

//==================================================================================================
.header__buttons-t {
  align-items: center;
  display: flex;
  @media (max-width: 800px) {
    flex-direction: row-reverse;
  }
}

.header_div_wrap {
  padding: 0 30px;

  @media (min-width: 1180px) {
    padding: 0 10px;
  }
  @media (max-width: 500px) {
    padding: 0 23px 0 23px;
  }
}

@media (max-width: 1140px) {
  .header_div_wrap {
    max-width: 100vw;
  }

  .header_regButton {
    .header_regButton_star {
      @media (max-width: 500px) {
        //display: none;
      }
    }
  }

  .balance_col {
    display: none;
  }

  .new_message_col {
    display: none;
  }

  .registred_buttons_dt {
    display: flex;
  }
}

@media (max-width: 900px) {
  .auth_methods {
    display: none !important;
  }
}

.header {
  &__translate-t {
    @media (max-width: 750px) {
      display: none;
    }
  }
}
.drawer-button-pc {
  @media (max-width: 992.98px) {
    display: none;
  }
  margin-right: 12px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: var(--red);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  flex: 0 0 38px;
  height: 36px;
  width: 38px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s;
  span::before,
  span::after,
  &::after,
  &::before {
    background: #fff;
    border-radius: 4px;
    content: "";
    display: block;
    height: 2px;
    transition: opacity 0.2s ease-in-out, transform 0.25s ease-in-out;
    width: 14px;
  }

  span {
    transition: all 0.3s;
    background: transparent;
    height: 2px;
    position: relative;
    width: 100%;
    &::before,
    &::after {
      position: absolute;
      width: 100%;
    }
  }
  &_active {
    &::before,
    &::after {
      opacity: 0;
    }
    span {
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
.drawer_button {
  display: none;

  @media (max-width: 992px) {
    //max-width: 25px;
    // flex: 1 1 100%;
    //position: relative;
    //top: -2px;
    margin-right: 5px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--red);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    flex: 0 0 36px;
    height: 36px;
    width: 36px;
  }
  svg {
    margin: 0;
  }
}

.header_buttons_col {
  padding: 0px !important;
}

.header {
  &__buttons-t {
  }

  &__translate-t {
    @media (max-width: 750px) {
      display: none;
    }
  }
}

@media (max-width: 500px), (max-height: 500px) {
  .visibleBtnPos {
    top: 80px !important;
  }

  .registred_buttons_mobile {
    //display: flex;
  }

  .registred_buttons_dt {
    //display: none;
  }
}
</style>
