<template>
  <div class="d-flex align-center justify-center">
    <!-- <img src="../assets/images/cards-spades.svg" width="25px" height="25px" class="preloader__img preloader__img_f">
    <img src="../assets/images/cards-clubs.svg" width="25px" height="25px" class="preloader__img preloader__img_s"> 
    <img src="../assets/images/cards-diamonds.svg" width="25px" height="25px" class="preloader__img preloader__img_four"> 
    <img src="../assets/images/white-heart.svg" width="25px" height="25px" class="preloader__img preloader__img_t">  -->

    <!-- <img src="../assets/images/pre_loader.png" width="100px" height="100px" class="preloader_img"> -->
    <img
      class="logo__preloader"
      :src="`https://minio.takemybet.pro/ui-style/${logo}`"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Preloader",
  props: {
    disableScroll: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      logo: "styles/getLogo",
    }),
  },
  mounted() {
    if (this.disableScroll) {
      document.body.style.position = "fixed";
    }
  },
  destroyed() {
    if (this.disableScroll) {
      document.body.style.removeProperty("position");
    }
  },
};
</script>

<style scoped lang="scss">
.logo__preloader {
  animation: rippleLogo 2.5s infinite linear;
}
@keyframes rippleLogo {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(0.5);
  }
}
.preloader {
  //perspective: 1000px;
  //&__img {

  &_f {
    animation: flip 2s infinite;
  }

  &_s {
    animation: flip2 2s infinite;
    //animation-delay: 0.5s;
  }

  &_t {
    animation: flip4 2s infinite;
    //animation-delay: 1s;
  }
  &_four {
    animation: flip3 2s infinite;
    //animation-delay: 1s;
  }
  //}
}

//@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
//@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
//@keyframes flip {
//  0% {
//    transform: rotateY(0deg);
//  }
//  25% {
//    transform: rotateY(180deg);
//  }
//  100% {
//    transform: rotateY(180deg);
//  }
//}
//@keyframes flip2 {
//  0% {
//    transform: rotateY(0deg);
//  }
//  25% {
//    transform: rotateY(0deg);
//  }
//  50% {
//    transform: rotateY(180deg);
//  }
//  100% {
//    transform: rotateY(180deg);
//  }
//}
//@keyframes flip3 {
//  0% {
//    transform: rotateY(0deg);
//  }
//  50% {
//    transform: rotateY(0deg);
//  }
//  75% {
//    transform: rotateY(180deg);
//  }
//  100% {
//    transform: rotateY(180deg);
//  }
//
//}
//@keyframes flip4 {
//  0% {
//    transform: rotateY(0deg);
//  }
//  75% {
//    transform: rotateY(0deg);
//  }
//  100% {
//    transform: rotateY(180deg);
//  }
//
//}

//.preloader_img{
//  -webkit-animation:spin 4s linear infinite;
//  -moz-animation:spin 4s linear infinite;
//  animation:spin 4s linear infinite;
//}
</style>
