const createQueryString = (data) => {
  const params = new URLSearchParams();
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const value = data[key];
      if (value != null && value !== "") {
        params.append(key, value);
      }
    }
  }
  return `?${params.toString()}`;
};
export default createQueryString;
