<template>
  <div style="background-color: var(--main-flamingo)">
    <!-- <v-container class="reg_container">
    </v-container> -->
    <div class="container-mod hubba">
      <v-card class="all-card" color="var(--light-flamingo)">
        <div v-if="shouldShowElement">
          <v-card-title class="pa-3 pb-0">
            <!--
          <v-tabs
                  class="all_tab_headers"
                  v-model="tabs"
                  hide-slider
                  style="border-radius: 10px;"
                  background-color="#211A38"
                  active-class="active_tab"
                  fixed-tabs


          >
              <v-tab class="tab_header" style="color: #FFFFFF" to="/profile/info/data">
                  Мой профиль
              </v-tab>
              <v-tab style="max-width: 33%!important;color: #FFFFFF" class="tab_header" to="/profile/promo">
                  Промо
              </v-tab >
              <v-tab class="tab_header" style="color: #FFFFFF" to="/profile/cashbox">
                  Касса
              </v-tab>

          </v-tabs>
          padding: <padding-top> || <padding-right> || <padding-bottom> || <padding-left>
          -->
            <v-row class="border-row">
              <v-col
                :class="getLinkClass('info')"
                @click="$router.push('/profile/info')"
              >
                {{ $i18n.t("myProfile") }}
              </v-col>

              <v-col
                :class="getLinkClass('cashbox')"
                @click="$router.push('/profile/cashbox')"
              >
                {{ $i18n.t("cashbox") }}
              </v-col>

              <v-col
                :class="getLinkClass('responsible-gambling')"
                @click="
                  $router.push('/profile/responsible-gambling/self-exclusion')
                "
              >
                {{ $i18n.t("responsibleGambling") }}
              </v-col>
              <v-col
                :class="getLinkClass('promo-my')"
                @click="$router.push('/profile/promo-my')"
              >
                {{ $i18n.t("myPromo") }}
              </v-col>
              <v-col
                :class="getLinkClass('promotions')"
                @click="$router.push('/profile/promotions')"
              >
                {{ $i18n.t("all_bonuses") }}
              </v-col>
            </v-row>
            <!--<div style="background-color: rgb(121, 30, 0);height: 3px;width: 100%;margin-top: 20px">
          </div>-->
          </v-card-title>
          <v-divider dark class="divider_vertical mt-3"></v-divider>
        </div>
        <v-card-text style="padding-top: 10px">
          <!--<v-row class="stars_line">

          </v-row>-->

          <div>
            <router-view class="profile_router"> </router-view>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <ul class="pa-0 bg-bubbles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Profile",
  data() {
    return {
      tabs: "",
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
    }),
    shouldShowElement() {
      return this.screenWidth >= 993;
    },
  },
  created() {
    if (this.$router.currentRoute.hash.includes("#&gid")) {
      this.$router.replace({ path: this.$router.currentRoute.path });
    }
  },
  async mounted() {
    if (
      localStorage.getItem("jwt") &&
      this.$store.state.levels.lvlItems.length === 0
    ) {
      await this.$store.dispatch("levels/fetchBetAmount", this.userInfo.id);
      await this.$store.dispatch(
        "levels/awaitGetLevels",
        this.userInfo.level_name
      );
      setTimeout(() => {
        this.$store.commit("levels/UPDATE_PROGRESS_VALUE");
      }, 50);
    }
    window.addEventListener("resize", this.updateScreenWidth);
    if (typeof this.$route.params.tab != "undefined") {
      this.tabs = Number.parseInt(this.$route.params.tab);
      //console.log('tab = ',this.tabs)
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenWidth);
  },
  methods: {
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    getLinkClass(link) {
      const isActive = this.$route.path.includes(`/${link}`);

      return isActive ? "tab_header _active" : "tab_header";
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 1;

  li {
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    // background-color: rgba(255, 255, 255, 0.25);
    background: url("../assets/images/bubles/white-heart-bubble.svg") 0 / 100%
      no-repeat;
    bottom: -160px;
    opacity: 0.3;
    -webkit-animation: square 25s infinite;
    animation: square 25s infinite;

    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    &:nth-child(1) {
      left: 5%;
      background: url("../assets/images/bubles/cards-spades-bubble.svg") 0 /
        100% no-repeat;
    }

    &:nth-child(2) {
      left: 20%;
      background: url("../assets/images/bubles/cards-diamonds-bubble.svg") 0 /
        100% no-repeat;
      width: 80px;
      height: 80px;

      animation-delay: 2s;
      animation-duration: 17s;
    }

    &:nth-child(3) {
      left: 25%;
      animation-delay: 4s;
      background: url("../assets/images/bubles/cards-clubs-bubble.svg") 0 / 100%
        no-repeat;
    }

    &:nth-child(4) {
      left: 40%;
      width: 60px;
      height: 60px;
      background: url("../assets/images/bubles/cards-spades-bubble.svg") 0 /
        100% no-repeat;
      animation-duration: 22s;

      // background-color: rgba(255, 255, 255, 0.25);
    }

    &:nth-child(5) {
      left: 70%;
      background: url("../assets/images/bubles/cards-clubs-bubble.svg") 0 / 100%
        no-repeat;
    }

    &:nth-child(6) {
      left: 80%;
      width: 120px;
      height: 120px;

      animation-delay: 3s;
      // background-color: rgba(255, 255, 255, 0.25);
    }

    &:nth-child(7) {
      left: 32%;
      width: 160px;
      height: 160px;

      animation-delay: 7s;
    }

    &:nth-child(8) {
      left: 55%;
      width: 20px;
      height: 20px;

      animation-delay: 15s;
      animation-duration: 40s;
    }

    &:nth-child(9) {
      left: 25%;
      width: 10px;
      height: 10px;

      animation-delay: 2s;
      animation-duration: 40s;
      // background-color: rgba(255, 255, 255, 0.25);
    }

    &:nth-child(10) {
      left: 90%;
      width: 160px;
      height: 160px;

      animation-delay: 11s;
    }
  }
}
.hubba {
  @media (min-width: 992px) {
    position: relative;
    z-index: 2;
  }
}

@media (max-width: 992px) {
  @-webkit-keyframes square {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-2000px) rotate(600deg);
    }
  }
  @keyframes square {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-2000px) rotate(600deg);
    }
  }
}
@media (min-width: 992px) {
  @-webkit-keyframes square {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-1500px) rotate(600deg);
    }
  }
  @keyframes square {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-1500px) rotate(600deg);
    }
  }
}

.container-mod {
  @media (max-width: 500px) {
    padding: 0;
  }
}

.all-card {
  min-width: 100%;
  margin: 100px 0 20px 0;
  width: 100%;

  @media (min-width: 500.98px) {
    border-radius: 16px !important;
  }
  @media (max-width: 992px) {
    margin: 70px 0 20px 0;
  }
  @media (max-width: 500.98px) {
    margin: 55px 0 0px 0;
    border-radius: 0 !important;
  }
  .is-email-confirmation-bar-visible & {
    @media (max-width: 992px) {
      margin: 140px 0 20px 0;
    }
    @media (max-width: 500.98px) {
      margin: 130px 0 0px 0;
    }
  }

  transition: none !important;
  transition-timing-function: unset !important;
}

.border-row {
  border-radius: 16px 16px 0 0;
  overflow: hidden;
}
.tab_header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0 !important;
  color: var(--white);
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  border-radius: 0px;
  &:not(:last-child) {
    border-right: 1px solid var(--dark-d-flamingo);
  }

  background-color: var(--dark-flamingo);
  transition: font-weight 0.3s;
  @media (max-width: 400px) {
    font-size: 10px !important;
  }
  @media (any-hover: hover) {
    &:hover {
      font-weight: 900;
    }
  }
  &._active {
    font-weight: 900;
    background-color: var(--light-flamingo);
  }
}

.register_button {
  margin-top: 5px;
}

.enter_methods {
  margin-bottom: 20px;
}

.terms_col {
  max-width: 70%;
  height: 80px;
  font-size: 11px;
  text-align: left;
}

.button_tabs {
  display: none;
}

.textfield_col {
  max-width: 70%;
  height: 80px;
}

.profile_router {
  width: 100%;
  //background-color: var(--main-flamingo);
  position: relative;
  z-index: 5;
}

@media (max-width: 720px) {
  .reg_container {
    padding-top: 50px !important;
  }
}

@media (max-width: 1024px) {
  .textfield_col {
    max-width: 85%;
  }

  .banner_image {
    width: 250px;
    height: 150px;
  }

  .register_button {
    margin-top: 15px;
  }
}

@media (max-width: 500px) {
  .terms_col {
    max-width: 85%;
  }

  .register_button {
    margin-top: 20px;
  }

  .enter_methods {
    display: none;
  }
}
</style>
