<template>
  <div :class="{ _flex: visibleGames.length !== 0 || loadingStart }">
    <template v-if="visibleGames.length === 0 && !loadingStart">
      <no-work-page
        :textError="textError"
        :loadingFlag="loadingFlag"
        @refresh-request="fetchGames"
        class="pa-3"
      >
        <template v-slot:icon>
          <img
            :src="`https://minio.takemybet.pro/ui-style/${logo}`"
            width="90"
            height="90"
            alt="logo"
          />
        </template>
      </no-work-page>
    </template>

    <template v-else-if="visibleGames.length !== 0 && !loadingStart">
      <div
        v-for="(game, index) in visibleGames"
        :key="index"
        class="game__item"
      >
        <div class="game__image-container">
          <div class="badges" v-if="user.id !== null">
            <div
              class="badge badge__favourite"
              :class="{ 'is-favourite': game.is_favourite }"
              @click.stop="addGameToFavorite(game)"
            >
              <v-icon>mdi-heart</v-icon>
            </div>
          </div>
          <img :src="game.logo" class="game__image" />
          <div class="game__overlay">
            <v-btn
              class="game__play all-buttons-t all-buttons-fw_green"
              @click="playGame(game)"
            >
              <img
                src="@/assets/images/white-heart.svg"
                width="15px"
                height="15px"
                class="white-heart mr-1"
              />
              {{ $i18n.t("play") }}
            </v-btn>
            <v-btn
              class="game__demo all-buttons-t"
              @click="playGame(game, true)"
            >
              {{ $i18n.t("demo") }}
            </v-btn>
          </div>
        </div>
        <div class="game-mobile">
          <div class="game-mobile__image-container">
            <img :src="game.logo" class="game-mobile__image" />
          </div>
          <div class="game-mobile__overlay">
            <span class="game-mobile__name">
              {{ game.name }}
            </span>
            <v-btn
              class="game-mobile__play all-buttons-t all-buttons-fw_green"
              @click="playGame(game)"
            >
              <img
                src="@/assets/images/white-heart.svg"
                width="15px"
                height="15px"
                class="white-heart mr-1"
              />

              {{ $i18n.t("play") }}
            </v-btn>
            <v-btn
              class="game-mobile__demo all-buttons-t"
              @click="playGame(game, true)"
            >
              {{ $i18n.t("demo") }}
            </v-btn>
          </div>
        </div>
      </div>
      <v-row
        style="flex: 1 1 100%"
        v-if="
          paggination &&
          paggination.page < paggination.pages &&
          visibleGames.length > 0
        "
      >
        <v-col class="justify-center align-center d-flex">
          <v-btn
            :loading="loadingFlag || isIntersectGames"
            class="all-buttons-t all-buttons-fw_green mt-2"
            @click="showMoreGames"
          >
            {{ $i18n.t("moreGames") }}
          </v-btn>
        </v-col>
      </v-row>
      <div v-intersect="showMoreGamesIntersect"></div>
    </template>
    <template v-if="loadingStart">
      <div class="game__item" v-for="index in gamesToShowCount" :key="index">
        <v-skeleton-loader
          class="br-16"
          min-width="100%"
          height="165px"
          type="card"
        />
      </div>
    </template>
  </div>
</template>

<script>
import NoWorkPage from "@/components/NoWorkPage.vue";
import { mapGetters } from "vuex";
export default {
  name: "GridGames",
  components: {
    NoWorkPage,
  },
  data() {
    return {
      loadingFlag: false,
      loadingStart: true,
      startSize: null,
      isIntersectGames: false,
      isActive: false,
      typeOfAll: this.$route.params.type,
      infoOfAll: this.$route.params.info,
      textError: "no_games_available",
    };
  },
  props: {},
  async mounted() {
    if (this.typeOfAll === "game-block") {
      await this.$store.dispatch("gameModule/getSelectedBlock", this.infoOfAll);
    }
    this.fetchGames();
  },
  computed: {
    ...mapGetters({
      user: "getUserInfo",
      screenParams: "screenParams",
      games: "gameModule/getCurrentGames",
      paggination: "gameModule/getCurrentPaggination",
      logo: "styles/getLogo",
    }),
    visibleGames() {
      if (!this.games) {
        return [];
      }
      return this.games;
    },
    gamesToShowCount() {
      if (
        this.screenParams.height > this.screenParams.width &&
        this.screenParams.width > 600 &&
        this.screenParams.width < 1025
      ) {
        return 35;
      } else if (this.screenParams.width > 1025) {
        return 35;
      } else if (this.screenParams.width > 600) {
        return 32;
      } else if (this.screenParams.width > 375) {
        return 30;
      } else {
        return 30;
      }
    },
  },
  methods: {
    async addGameToFavorite(game) {
      game.is_favourite = !game.is_favourite;
      await this.$store.dispatch("favoriteGames/awaitToggleFavoriteGame", {
        id: game.id,
        is: game.is_favourite,
      });
      this.$store.commit("favoriteGames/APPEND_CURRENT_FAVORITE_GAME", {
        game: game,
        is: game.is_favourite,
      });
    },
    showMoreGamesIntersect(entries, observer) {
      // check instersect block
      if (entries[0].isIntersecting && this.isIntersectGames) {
        this.loadingFlag = true;
        // call
        this.showMoreGames();
      }
    },
    async showMoreGames() {
      this.loadingFlag = true;
      const params = this.generateParams(this.paggination.page + 1);

      const newGames = await this.$store.dispatch(
        "gameModule/getMoreGames",
        params
      );
      this.$store.commit("gameModule/APPEND_CURRENT_GAMES", newGames);

      if (!this.isIntersectGames) {
        this.isIntersectGames = true;
      }

      this.loadingFlag = false;
    },

    async fetchGames() {
      this.loadingStart = true;
      this.$emit("loading-flag", true);

      const params = this.generateParams();

      // Сбрасываем текущие игры и загружаем отфильтрованные
      this.$store.commit("gameModule/HOLD_CURRENT_GAMES", null);
      await this.$store.dispatch("gameModule/getFilteredGames", params);

      this.loadingStart = false;
      if (this.games.length > 0) {
        this.$emit("loading-flag", false);
      }
    },

    generateParams(page = 1) {
      const params = {
        size: this.gamesToShowCount,
        page,
        game_block_id: null,
        tournament_id: null,
        provider: null,
        game_type: null,
        sub: null,
      };

      switch (this.typeOfAll) {
        case "game-block":
          params.game_block_id = this.infoOfAll;
          break;
        case "game-provider":
          if (this.infoOfAll !== "all") {
            params.provider = this.infoOfAll;
          }
          break;
        case "provider":
          if (this.$route.params.info !== "all") {
            params.provider = this.$route.params.info;
            params.tournament_id = this.$route.params.id;
          }
          break;
        case "games":
          switch (this.infoOfAll) {
            case "SLOT":
              params.game_type = "SLOT";
              break;
            case "ROULETTE":
              params.game_type = "ROULETTE";
              break;
            case "LIVE_CASINO":
              params.game_type = "LIVE_CASINO";
              break;
            case "new":
              params.sub = "new";
              break;
            case "top":
              params.sub = "top";
              break;
          }
          break;
      }

      return params;
    },
    // typeOfGame() {
    //   const gameParam = this.$route.params.block;
    //   if (gameParam === 'game-provider') {
    //     return;
    //   }
    //   let params = {
    //     size: this.gamesToShowCount,
    //     game_type: null,
    //     provider: null,
    //     sub: null,
    //   };
    //   const gameParamParts = gameParam.split('-');
    //   if (gameParamParts.length === 2) {
    //     const gameParamProvider = gameParamParts[1];

    //     this.providerList.forEach(provider => {
    //       if (provider.text) {
    //         const lowerCaseProviderName = provider.text;

    //         if (lowerCaseProviderName === gameParamProvider || lowerCaseProviderName === 'all games') {
    //           params.provider = lowerCaseProviderName === 'all games' ? 'all' : lowerCaseProviderName;
    //           return this.params = params;
    //         }
    //       }
    //     });
    //   }

    //   return params;
    // },
    async playGame(game, demoFlag = false) {
      if (this.$store.getters.getUserInfo.id === null) {
        try {
          await this.$router.push("/reg");
        } catch (error) {
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        }
      } else {
        if (this.$store.getters.getUserInfo.balance == 0 && !demoFlag) {
          try {
            await this.$router.push("/deposit");
          } catch (error) {
            if (error.name !== "NavigationDuplicated") {
              throw error;
            }
          }
        } else {
          let params = { id: game.string_id };
          if (demoFlag) {
            params.demo = "demo";
          }
          await this.$router.push({ name: "Game", params });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin imageGame {
  max-width: 100%;
  max-height: 100%;
  border-radius: 16px;
  overflow: hidden;
  transition: opacity 0.3s;
}

@mixin logoLoading {
  position: relative;

  &::before {
    content: "";
    display: block;
    border-radius: 16px;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: var(--red);
    animation: disappear 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    opacity: 1;
    visibility: visible;
  }

  &::after {
    content: "";
    position: absolute;
    border-radius: 16px;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background: url("../../../assets/images/main_logo.png") center / 90px
      no-repeat;
    animation: disappear2 1s;
    opacity: 0.6;
    animation-delay: 1s;
    animation-fill-mode: forwards;

    @media (max-width: 992px) {
      background-size: 60px;
    }
  }
}
.badges {
  position: absolute;
  right: 15px;
  top: 15px;
  display: flex;
  align-items: center;
  z-index: 4;
}
.badge {
  background-color: hsla(0, 0%, 100%, 0.3);
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  position: relative;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, transform 0.3s;
  &:not(:last-child) {
    margin-right: 5px;
  }
  &__favourite {
    i {
      color: var(--white);
      font-size: 18px;
    }
    @media (any-hover: hover) {
      &:hover {
        background-color: var(--red);
      }
    }
    &.is-favourite {
      background-color: var(--red);
      transform: scale(0.9);
    }
  }
}
.game {
  position: relative;

  &__item {
    box-sizing: border-box;
    display: inline-block;
    flex: 0 1 auto;
    line-height: 0;
    padding: 10px;
    position: relative;
    transition: transform 0.3s;
    width: 14.2857142857%;

    @media (max-width: 1100px) {
      padding: 5px;
    }

    @media (orientation: landscape) and (min-width: 600px) and (max-width: 1024px) {
      width: 25%;
    }

    @media (max-width: 600px) {
      width: 33.33%;
      padding: 3px;
    }
  }

  &__image-container {
    @media (any-hover: none) {
      display: none;
    }

    @include logoLoading;
    @media (any-hover: hover) {
      &:hover {
        .game__overlay {
          opacity: 1;
          visibility: visible;

          &::before {
            left: 168%;
          }
        }

        .game__image {
          opacity: 0.5;
        }
      }
    }
  }

  &__image {
    @include imageGame;
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    align-items: center;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-align: center;
    border-radius: 16px;
    overflow: hidden;
    text-decoration: none;
    backdrop-filter: blur(2px);
    transition: all 0.3s ease-out;

    @media (min-width: 849.98px) {
      &::before {
        content: "";
        background-color: rgba(white, 0.4);
        width: 60%;
        height: 100%;
        top: 0px;
        left: -125%;
        transform: skew(45deg);
        z-index: 3;
        position: absolute;
        transition: left 0.5s ease-out, background-color 0.5s;
      }
    }
  }

  &__play {
    width: 100%;
  }

  &__demo {
    margin-top: 10px;
    width: 100%;
  }

  &__name {
    height: 25px;
    max-width: calc(100% - 20px);
    overflow: hidden;
    color: white;
    position: relative;
    top: -5px;
    animation: disName 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
    visibility: hidden;

    @keyframes disName {
      0% {
        opacity: 0;
        visibility: hidden;
      }

      100% {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.game-mobile {
  @media (any-hover: hover) {
    display: none;
  }

  @include imageGame;
  perspective: 1000px;
  transition: transform 0.8s;
  transform-style: preserve-3d;

  &:hover {
    transform: rotateY(180deg);

    .game-mobile__overlay {
      opacity: 1;
      visibility: visible;
    }
  }

  &__image-container {
    @include logoLoading;
    @include imageGame;
  }

  &__image {
    @include imageGame;
  }

  &__overlay {
    position: absolute;
    padding: 0 30px;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transform: rotateY(180deg);
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.7);
    transition: opacity 0.5s, visibility 0.5s;
    transition-delay: 0.1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
      padding: 0 20px;
    }
  }

  &__name {
    font-size: 14px;
    color: var(--white);
    line-height: 1;

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  &__play {
    margin-top: 5px;
    width: 100%;

    @media (max-width: 600px) {
      margin-top: 3px;
      height: 30px !important;
      min-height: 30px !important;
    }
  }

  &__demo {
    margin-top: 10px;
    width: 100%;

    @media (max-width: 600px) {
      margin-top: 5px;
      height: 30px !important;
      min-height: 30px !important;
    }
  }
}

//========================================================================================================================================================
// Kf
@keyframes disappear {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes disappear2 {
  0% {
    opacity: 0.6;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
