<template>
  <v-container fluid style="max-width: 900px">
    <!-- <v-row class="title__link-v flex-column">
      <v-col class="d-flex align-center justify-center">
        <font-awesome-icon
          class="ml-0"
          style="color: var(--white); margin-right: 3px; font-size: 20px"
          icon="trophy"
        />
        <span style="color: var(--white)"
          >{{ $i18n.t("level") }}: <b>{{ currentLevel }}</b></span
        >
      </v-col>
      <v-col class="pa-0">
        <v-divider dark></v-divider>
      </v-col>
    </v-row> -->

    <div style="color: var(--white)">
      {{ $i18n.t("profileStatusInfoText") }}
    </div>
    <div class="mx-auto" style="max-width: 550px">
      <v-row v-if="currentLevelNumber < lvlItems.length">
        <v-col class="text-left d-flex flex-column" style="color: var(--white)">
          <span>
            {{ $i18n.t("toNextStatusLevel") }}:
            <!--  -->
            <b v-if="nextBonusAmount !== null && betAmount !== null">
              {{ betAmount }} / {{ nextBonusAmount }} {{ user.currency }}</b
            >
            <b v-else>{{ $t("Loading") }}...</b>
          </span>
          <v-progress-linear
            v-if="nextBonusAmount !== null"
            style="
              border-radius: 16px;
              width: 100%;
              border: solid 1px var(--red);
            "
            height="12px"
            background-color="none"
            color="var(--red)"
            :value="progressValue"
          ></v-progress-linear>
          <span v-else>{{ $t("LoadingProgress") }}...</span>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="text-center font-weight-bold" style="color: var(--white)">
          {{ $t("max_level") }}
        </v-col>
      </v-row>

      <v-row class="lvl">
        <template v-if="lvlItems.length && !loadingFlag">
          <v-col
            class="lvl__col"
            cols="12"
            v-for="(lvlItem, index) in lvlItems"
            :key="index"
            :class="{
              _completedCol: Number(lvlItem.name) < currentLevelNumber,
              _completedCol_active:
                Number(lvlItem.name) < currentLevelNumber && activeLvl,
              _current: lvlItem.showIcon,
              _completed: Number(lvlItem.name) < currentLevelNumber,
            }"
          >
            <levelFlip :index="index">
              <template slot="front">
                <div @click="flipLevel(lvlItem, index)">
                  <transition name="scale-transition">
                    <span
                      class="lvl__flip-level"
                      @click.stop="flipCompletedLevel(lvlItem, index)"
                      v-if="
                        Number(lvlItem.name) >= currentLevelNumber || activeLvl
                      "
                    >
                      <font-awesome-icon
                        icon="arrow-right"
                        style="font-size: 12px"
                      />
                    </span>
                  </transition>
                  <v-container
                    @click="_completedColClick(lvlItem)"
                    class="lvl__container"
                  >
                    <div class="lvl__image">
                      <img
                        :src="`https://minio.takemybet.pro/levels/${lvlItem.logo}`"
                        :alt="lvlItem.name"
                      />
                    </div>
                    <v-col class="lvl__text">
                      <span
                        >{{ $t("lvl") }} {{ lvlItem.name }} -
                        {{ $t(lvlItem.description) }}</span
                      >
                      <span class="ma-0" v-if="lvlItem.name !== '1'">
                        <!-- {{
                        lvlItem.reward_description === "No reward"
                          ? $t("NoReward")
                          : lvlItem.reward_description
                      }}
                      {{ index > 0 ? $t("bonusesFree") : null }} -->
                        {{
                          `${lvlItem.next_level_amount} ${user.currency} - ${$t(
                            "level_amount"
                          )}`
                        }}
                      </span>
                    </v-col>
                    <div class="svg-current" v-if="lvlItem.showIcon">
                      <img src="@/assets/images/white-heart.svg" alt="heart" />
                    </div>
                  </v-container>
                </div>
              </template>
              <template slot="back">
                <v-container
                  @click="flipAllLevels(index)"
                  class="lvl__container back-lvl"
                  :class="{
                    '_current-back': lvlItem.showIcon,
                    '_completed-back': lvlItem.name < currentLevel,
                  }"
                >
                  <span class="lvl__flip-level lvl__flip-level_close">
                    <v-icon color="white" style="font-size: 18px">
                      close
                    </v-icon>
                  </span>
                  <div class="lvl__image">
                    <img
                      :src="`https://minio.takemybet.pro/levels/${lvlItem.logo}`"
                      :alt="lvlItem.name"
                    />
                  </div>
                  <div class="back-lvl__content">
                    <h3 class="back-lvl__title">{{ $t("level_bonuses") }}:</h3>
                    <div
                      class="back-lvl__info"
                      :class="{
                        _center:
                          !lvlItem.dep_bonus && !lvlItem.free_rounds_bonus,
                      }"
                    >
                      <div
                        v-if="lvlItem.dep_bonus || lvlItem.free_rounds_bonus"
                        class="d-flex flex-column align-start"
                        style="color: var(--white)"
                      >
                        <span
                          class="text-left"
                          v-if="lvlItem.dep_bonus !== null"
                        >
                          <span class="font-weight-bold"
                            >{{ $t("depositBonus") }}:
                          </span>
                          {{ formatDepositBonus(lvlItem.dep_bonus) }}
                        </span>
                        <span
                          class="text-left"
                          v-if="lvlItem.free_rounds_bonus !== null"
                        >
                          <span class="font-weight-bold"
                            >{{ $t("spinsBonuses") }}:</span
                          >
                          {{ formatSpinsBonus(lvlItem.free_rounds_bonus) }}
                        </span>
                      </div>
                      <b v-else class="font-weight-bold c-white">{{
                        $t("noBonus")
                      }}</b>
                    </div>
                  </div>
                </v-container>
              </template>
            </levelFlip>
          </v-col>
        </template>
        <template v-else>
          <v-col
            class="lvl__col"
            cols="12"
            v-for="(lvlItem, index) in 10"
            :key="index"
          >
            <v-container class="lvl__container fw">
              <v-skeleton-loader
                class="skeleton-level"
                width="100%"
                height="85px"
                type="image"
              ></v-skeleton-loader>
            </v-container>
          </v-col>
        </template>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import levelFlip from "@/components/FlipCard/levelFlip.vue";
export default {
  name: "Levels",
  components: {
    levelFlip,
  },
  data() {
    return {
      activeLvl: false,
      loadingFlag: false,
    };
  },
  async mounted() {
    this.loadingFlag = true;
    this.$store.dispatch("levels/initializeLevels", {
      levels: this.lvlItems,
      currentLevel: this.user.level.order,
      userCurrency: this.user.currency,
    });
    this.loadingFlag = false;
  },
  computed: {
    ...mapGetters({
      user: "getUserInfo",
    }),
    filteredAmount() {
      return this.lvlItems.next_level_amount;
    },
    nextBonusAmount: {
      get() {
        return this.$store.state.levels.nextBonusAmount;
      },
      set(value) {
        this.$store.commit("levels/setNextBonusAmount", value);
      },
    },
    betAmount: {
      get() {
        return this.$store.state.levels.betAmount;
      },
      set(value) {
        this.$store.commit("levels/setBetAmount", value);
      },
    },
    lvlItems: {
      get() {
        return this.$store.state.levels.lvlItems;
      },
      set(value) {
        this.$store.commit("levels/SET_LVL_ITEMS", value);
      },
    },
    currentLevel() {
      // Logic to determine current level based on user status
      return this.user.level.order;
    },
    currentLevelNumber() {
      // Logic to determine current level based on user status
      return Number(this.user.level.order);
    },
    progressValue() {
      if (this.nextBonusAmount > 0 && this.betAmount != null) {
        return (this.betAmount / this.nextBonusAmount) * 100;
      }
      return 0;
    },
  },
  methods: {
    getType(type) {
      switch (type) {
        case "welcome":
          return this.$t("welcome");
        case "next_deposit":
          return this.$t("next_deposit");
        case "no_deposit":
          return this.$t("no_deposit");
        case "free_rounds":
          return this.$t("spinsBonuses");
      }
    },
    formatDepositBonus(bonus) {
      if (!bonus || !bonus.currency_limits) {
        return;
      }
      const formattedBonus = Object.entries(bonus.currency_limits)
        .filter(
          ([currency, value]) => currency === this.user.currency && value !== 0
        )
        .map(
          ([currency, limits]) =>
            ` ${this.$t("min_dep")}: ${limits.min_dep} ${currency}, ${this.$t(
              "max_dep"
            )}: ${limits.max_dep} ${currency}`
        );
      const restOfName = [
        this.$t("bonus_percent") + ": " + `${bonus.bonus_percent}%`,
        this.$t("wager") + ": " + bonus.wager,
      ];
      return [...restOfName, ...formattedBonus].join(", ");
    },
    formatSpinsBonus(bonus) {
      if (
        !bonus ||
        !bonus.meta_settings ||
        !bonus.meta_settings.currency_bet_level
      ) {
        return;
      }

      // Extract currency-specific bet information
      const formattedBonus = Object.entries(
        bonus.meta_settings.currency_bet_level
      )
        .filter(
          ([currency, value]) => currency === this.user.currency && value !== 0
        )
        .map(
          ([currency, value]) => this.$t("bet") + ": " + value + " " + currency
        );

      // Other details for the bonus
      const restOfName = [
        bonus.game && bonus.game.game_name
          ? this.$t("game_name") + ": " + bonus.game.game_name
          : "",
        bonus.game && bonus.game.provider_name
          ? this.$t("provider") + ": " + bonus.game.provider_name
          : "",
        this.$t("spin") + ": " + bonus.count,
        this.$t("wager") + ": " + bonus.wager,
      ];

      // Filter out empty strings and combine details
      const filteredRestOfName = restOfName.filter((item) => item !== "");
      return filteredRestOfName.concat(formattedBonus).join(", ");
    },
    flipCompletedLevel(clickedLvlItem, index) {
      const filteredLevels = this.lvlItems.filter(
        (lvlItem) => Number(lvlItem.name) < this.currentLevelNumber
      );
      //console.log(filteredLevels);

      const found = filteredLevels.find((item) => {
        return item === clickedLvlItem;
      });

      if (!found) {
        this.$store.commit("levels/TOGGLE_CARD", index);
      }
      if (this.activeLvl) {
        this.$store.commit("levels/TOGGLE_CARD", index);
      }
    },
    flipLevel(level, index) {
      if (level.name >= this.currentLevel) {
        this.$store.commit("levels/TOGGLE_CARD", index);
      }
    },
    flipAllLevels(index) {
      this.$store.commit("levels/TOGGLE_CARD", index);
    },
    _completedColClick(lvlItem) {
      const filteredLevels = this.lvlItems.filter(
        (lvlItem) => Number(lvlItem.name) < this.currentLevelNumber
      );
      // console.log(filteredLevels);
      if (Number(lvlItem.name) < this.currentLevelNumber) {
        this.activeLvl = !this.activeLvl;
      }
      if (!this.activeLvl) {
        filteredLevels.forEach((lvlItem, index) => {
          this.$store.commit("levels/CLOSE_CARD", index);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.svg-current {
  border-radius: 50%;
  //padding: 3px;
  background-color: var(--dark-flamingo);
  position: absolute;
  animation: rippleLogo 2s infinite;
  top: 50%;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 18px;
    max-height: 18px;
  }
  svg {
    margin: 0;
    transform: translate(0px, 5px);
  }
  //@media (max-width:600px){
  //  top: 3px;
  //  right: 3px;
  //}
}
@keyframes rippleLogo {
  0% {
    transform: translate(0px, -50%) scale(1);
  }
  50% {
    transform: translate(0px, -50%) scale(1.1);
  }
  100% {
    transform: translate(0px, -50%) scale(1);
  }
}
@keyframes jump {
  0% {
    transform: translateZ(0) scale(1);
  }
  100% {
    transform: translate3d(0, -15px, 0) scale(1.1);
  }
}
.lvl {
  &__col {
    padding: 6px;
    position: relative;
    display: flex;
    $maxZIndex: 1;
    @for $i from 10 through $maxZIndex {
      &._completedCol:nth-child(#{$i}) {
        position: relative;
        z-index: $i;
      }
      &._completedCol {
        transition: margin-top 0.3s, opacity 0.3s;
        margin-top: -85px;
        cursor: pointer;
        &:first-child {
          margin-top: 0px;
        }
        &_active {
          margin-top: 0px;
          opacity: 0.5;
        }
      }
    }
  }

  &__container {
    position: relative;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
    background-color: var(--light-flamingo);
    border-radius: 16px;
    min-height: 85px;
    display: flex;
    //align-items: center;
    padding: 0;
    cursor: pointer;
    ._current & {
      background-color: #e589ff;
      border: 3px solid rgba(194, 11, 255, 0.9);
    }
    &._completed {
      cursor: default;
      opacity: 1;
    }
  }
  &__flip-level {
    position: absolute;
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translate(0px, -50%);
    right: -10px;
    z-index: 2;
    color: var(--white);
    cursor: pointer;
    background-color: var(--dark-flamingo);
    border-radius: 5px;
    &_close {
      background-color: var(--light-flamingo);
    }
  }
  &__image {
    display: flex;
    flex: 0 0 80px;
    border-radius: 16px 0 0 16px;
    overflow: hidden;
    ._current & {
      border-radius: 13px 0 0 13px;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    flex: 1 1 100%;
    font-weight: 500;
    justify-content: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    color: var(--white);
    text-transform: uppercase;
    span {
      //margin: 0 15px 0 0;
    }
    @media (max-width: 992px) {
      max-width: 420px;
    }
    @media (max-width: 768px) {
      padding-left: 3px;
      align-items: flex-start;
    }
    @media (max-width: 600px) {
      max-width: 250px;
    }
    @media (max-width: 400px) {
      max-width: 215px;
    }
    span {
      @media (max-width: 768px) {
        text-align: left;
      }
    }
  }
}
.back-lvl {
  background-color: var(--dark-flamingo);
  &._current-back {
    border: 3px solid rgba(194, 11, 255, 0.9);
  }
  &._completed-back {
    opacity: 1;
  }
  &__content {
    flex: 1 1 auto;
    margin: 3px 25px 3px 5px;
    @media (max-width: 992px) {
      margin-right: 10px;
    }
  }
  &__title {
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__info {
    display: flex;
    align-items: center;
    @media (max-width: 992px) {
      max-width: 420px;
    }
    &._center {
      justify-content: center;
    }
  }
}
.status_icon {
  background-color: var(--main-flamingo);
  width: 80px;
  height: 80px;

  margin-left: 5px;
}
.status_icon_dark {
  background-color: #513e92;
  width: 80px;
  height: 80px;
  margin-left: 5px;
}
</style>
