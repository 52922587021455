<template>
  <v-card class="bonus-back">
    <v-card-title class="bonus-back__header header-bonus">
      <div class="header-bonus__label">
        <font-awesome-icon style="font-size: 12px" icon="trophy" />
      </div>
      <div class="header-bonus__title">{{ $t("tournamentBonus") }}</div>
      <!-- @click="toggleCardFlip(index)"  -->
      <div @click="toggleFlip" class="header-bonus__label">
        <v-icon color="white" style="font-size: 18px"> close </v-icon>
      </div>
    </v-card-title>
    <v-card-text style="width: auto" class="bonus-back__main main-bonus">
      <v-list class="main-bonus__list">
        <div class="main-bonus__item">
          <div
            class="main-bonus__content"
            v-for="(tournamentInfo, key) in tournamentInfos"
            :key="'content_' + key"
          >
            <div class="main-bonus__title">
              {{ tournamentInfo.type }}
            </div>
            <div class="main-bonus__text">
              {{ tournamentInfo.info }}
            </div>
          </div>
        </div>
      </v-list>
      <v-tooltip top v-if="$route.path.includes('/promo-my/bonus')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :loading="loading"
            @click="toLeaveTournamnt"
            v-bind="attrs"
            v-on="on"
            class="all-buttons-t main-bonus__button mx-2"
          >
            {{ $t("leave") }}</v-btn
          >
        </template>
        <span>
          {{ $t("youWillLossTournamentProgress") }}
        </span>
      </v-tooltip>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BonusTournamentOneBack",
  data() {
    return {
      loading: false,
    };
  },
  props: {
    tournament: {
      type: Object,
      required: true,
    },
    state: {
      type: Function,
      required: true,
    },
  },
  methods: {
    async toLeaveTournamnt() {
      this.loading = true;
      let notification;
      try {
        await this.$store.dispatch(
          "tournaments/awaitLeaveTournament",
          this.tournament.id
        );
        notification = {
          type: "success",
          message: this.$t("successTournamentLeave"),
        };
      } catch (error) {
        notification = {
          type: "error",
          message: this.$t("justError"),
        };
      } finally {
        this.loading = false;
        this.$store.dispatch("alerts/add", notification);
      }
    },
    toggleFlip() {
      const { id, flipped } = this.tournament;
      this.$store.dispatch("tournaments/flipTournament", {
        state: this.state,
        id: id,
        flipped: !flipped,
      });
    },
  },
  computed: {
    ...mapGetters({
      user: "getUserInfo",
    }),
    tournamentInfos() {
      return [
        {
          type: this.$t("tournamentBonusPool"),
          info: `${this.tournament.prize_pool_amount} ${this.user.currency}`,
        },
        {
          type: this.$t("tournamentBonusPaid"),
          info: `${
            this.tournament.prize_distribution[
              this.tournament.prize_distribution.length - 1
            ].leaderboard_position
          } 
        (${this.$t("grandPrize")} - ${
            this.tournament.prize_distribution[0].prize_amount
          } ${this.user.currency})
          `,
        },
        // {
        //   type: this.$t("tournamentBonusType"),
        //   info: this.$t("tournamentBonusTypeText"),
        // },
        {
          type: this.$t("tournamentBonusFrequency"),
          info: this.$t(this.tournament.repeats),
        },
        // {
        //   type: this.$t("tournamentBonusContributing"),
        //   info: this.$t("tournamentBonusContributingText"),
        // },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.bonus-back {
  //background: linear-gradient(173.41deg,#0094ff 4.14%,#5607d6 87.72%)!important;
  //background: var(--dark-flamingo)!important;
  background: linear-gradient(to right, var(--white), var(--white)) !important;

  color: var(--white) !important;
  //height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px !important;
  &__header {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
  }
  &__content {
  }
  &__main {
    flex: 1 1 100%;
    position: relative;
    z-index: 2;
  }

  &__timer {
    position: relative;
    z-index: 2;
  }
}
.header-bonus {
  padding: 10px;
  background-color: var(--dark-flamingo);

  &__label {
    height: 24px;
    justify-content: center;

    position: relative;
    width: 24px;
    align-items: center;
    display: flex;
    position: relative;
    transition: background-color 0.3s;
    &:last-child {
      cursor: pointer;

      @media (any-hover: hover) {
        &:hover {
          &::before {
            background-color: hsla(0, 0%, 100%, 0.4);
          }
        }
      }
    }
    &::before {
      background-color: hsla(0, 0%, 100%, 0.2);
      border-radius: 50%;
      content: "";
      //height: calc(100% - 20px);
      height: 24px;
      width: 24px;
      position: absolute;
      //width: calc(100% - 20px);
    }
  }

  &__title {
    display: block;
    line-height: 1.5;
    max-width: calc(100% - 68px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
  }
}
.main-bonus {
  padding: 0;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  &__list {
    background-color: var(--white) !important;
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: inherit !important;
    flex: 1 1 100%;
  }

  &__item {
    line-height: 1.5;

    min-height: auto;
    flex-direction: column;
    flex: 1 1 100%;
  }
  &__content {
    padding: 10px 15px 9px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &:nth-child(even) {
      background: #f2f6f9 !important;
    }
    flex-wrap: nowrap;
  }
  &__title {
    flex: 0 1 50%;
    margin-bottom: 0 !important;
    color: var(--gray-text) !important;
    margin-right: 10px;

    font-size: 14px;
    word-wrap: normal;
  }

  &__text {
    flex: 0 1 50%;
    font-size: 14px;
    font-weight: 600;
    margin-left: auto;
    text-align: right;
    width: 100%;
    color: var(--black) !important;
    line-height: 1.2;
  }
}
.timer-bonus {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  &__icon {
  }

  &__text {
    font-weight: 700;
    color: var(--white);
  }
}
.bonus {
  &__header {
  }

  &__main {
  }
}
</style>
