<template>
  <div class="d-flex" style="overflow: hidden" v-if="filteredPayMethods">
    <div class="color">
      <v-container class="cashout__wrapper">
        <div style="position: relative; z-index: 4">
          <!-- Title -->
          <v-row class="mb-0">
            <v-col class="cashout__text">
              <span>{{ $i18n.t("cashoutTitle") }}</span>
            </v-col>
          </v-row>
          <!-- Title low balance -->
          <v-row v-if="user.balance === 0">
            <v-col style="color: white; font-size: 18px">
              {{ $i18n.t("tooSmallBalance") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <!-- Title select -->

              <!-- :src="method.logo" -->
              <!-- ??? text ??? -->
              <v-row v-if="withdrawType.fix && withdrawType.fix > 0">
                <v-col style="color: var(--black-text)">
                  <v-row>
                    <!-- <div>
                {{$i18n.t('Commission')}}: {{withdrawType.fee}}%
              </div> -->
                    <div>
                      + {{ withdrawType.fix }}<b>{{ user.currency }}</b>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
              <!-- Content -->
              <v-card class="deposit_row">
                <v-card-title
                  class="text-left pb-0 text-uppercase"
                  style="
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    color: var(--black-text);
                  "
                >
                  {{ $i18n.t("selectSumma") }}:
                </v-card-title>
                <!-- Input row -->
                <v-card-text>
                  <v-row>
                    <v-col style="color: var(--black-text)">
                      <!-- Input -->
                      <div class="input-wrapper">
                        <div class="input-mask">
                          <input
                            v-maska
                            data-maska="0.99"
                            data-maska-tokens="0:\d:multiple|9:\d:optional"
                            :maxlength="11"
                            class="pt-0 limit-input withdraw_amount black-input"
                            v-model="amount"
                            type="text"
                            @input="formattedInput"
                            placeholder="0.00"
                          />
                        </div>
                        <div
                          style="
                            font-size: 48px;
                            color: var(--dark-flamingo) !important;
                          "
                        >
                          {{ user.currency }}
                        </div>
                      </div>
                      <v-col
                        class="text-left px-0 py-1"
                        style="font-size: 12px"
                      >
                        {{ $i18n.t("from") }}
                        {{ filteredPayMethods[0].payment_min_limit }}
                        {{ $i18n.t("to") }}
                        {{ filteredPayMethods[0].payment_max_limit }}
                      </v-col>
                    </v-col>
                  </v-row>
                </v-card-text>
                <!-- Border -->
                <v-row class="mx-0">
                  <v-col class="pt-0 pb-1 px-4">
                    <v-divider
                      dark
                      style="
                        border: 1px solid var(--black-text);
                        border-radius: 5px;
                      "
                    ></v-divider>
                  </v-col>
                </v-row>
                <!-- Two balances -->
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="6"
                      class="text-left pt-1"
                      style="color: var(--black-text); font-size: 16px"
                    >
                      <div class="d-flex">
                        <b style="font-size: 14px; color: var(--black-text)">
                          {{ user.sum_available_for_withdraw }}
                        </b>
                        <b
                          class="ml-1"
                          style="font-size: 14px; color: var(--black-text)"
                        >
                          {{ user.currency }}
                        </b>
                      </div>
                      <p
                        class="mb-0 lh"
                        style="font-size: 12px"
                        v-html="$t('withdrawAvaliableAmount')"
                      ></p>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right pt-1 pl-0"
                      style="color: var(--black-text); font-size: 16px"
                    >
                      <div
                        class="d-flex align-center"
                        style="justify-content: flex-end"
                      >
                        <b style="font-size: 14px; color: var(--black-text)">
                          {{ user.bonus_balance }}
                        </b>
                        <b
                          class="ml-1"
                          style="font-size: 14px; color: var(--black-text)"
                        >
                          {{ user.currency }}
                        </b>
                      </div>
                      <p class="mb-0 with lh" style="font-size: 12px">
                        <span>
                          {{ $t("bonusBalanceWithWager1") }}
                        </span>
                        <span>
                          {{ $t("bonusBalanceWithWager2") }}
                        </span>
                        <span>
                          {{
                            (user.wagering !== null &&
                            user.wagering !== undefined
                              ? user.wagering
                              : 0) +
                            " " +
                            user.currency +
                            " / " +
                            (user.wager_amount !== null &&
                            user.wager_amount !== undefined
                              ? user.wager_amount
                              : 0) +
                            " " +
                            user.currency
                          }}
                        </span>
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>

                <!-- Button -->
                <v-card-text>
                  <v-row class="justify-center">
                    <v-col class="text-center">
                      <div
                        class="deposit-payment-wrapper small-text mt-0 align-start"
                      >
                        <div class="deposit-payment-time">
                          {{ $t("commision") }}:
                          {{ `${filteredPayMethods[0].fee}%` }}
                        </div>
                      </div>
                      <v-btn
                        v-if="!cashoutDisable && !successWithdraw"
                        class="all-buttons-t all-buttons-fw all-buttons-fw_green"
                        @click="getWithdrawUrl"
                        :disabled="disabledButton"
                        :loading="loading"
                        style=""
                      >
                        <span
                          >{{ $t("doWithdraw") }}:
                          {{
                            `${totalAmount.toFixed(2)} ${user.currency}`
                          }}</span
                        >
                        <!-- <span style="width: 25px;height: 25px"><img src="@/assets/images/dollar.svg" style="width: 100%;height: 100%;"/></span> -->
                      </v-btn>
                      <v-btn
                        v-if="cashoutDisable && !successWithdraw"
                        class="all-buttons-t all-buttons-fw"
                        :loading="loading"
                        style=""
                      >
                        <span
                          >{{ $t("not_enough_money") }} ({{
                            totalAmount.toFixed(2)
                          }})</span
                        >
                        <!-- <span style="width: 25px;height: 25px"><img src="@/assets/images/dollar.svg" style="width: 100%;height: 100%;"/></span> -->
                      </v-btn>
                      <v-btn
                        color="#00bd71"
                        class="button-adaptive"
                        v-if="successWithdraw"
                      >
                        <v-icon color="white"> done </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <!-- Limit Error -->
        <v-dialog
          v-model="wagerDialog"
          max-width="fit-content"
          persistent
          overlay-opacity="0.8"
        >
          <div style="margin-left: auto; width: max-content" class="pb-2">
            <v-btn color="var(--main-flamingo)" @click="moveBack">
              <v-icon color="white"> close </v-icon>
            </v-btn>
          </div>
          <v-card
            class="basic__card"
            style="
              background-color: var(--main-flamingo) !important;
              color: var(--black-text);
              max-width: 350px;
            "
          >
            <v-card-title>
              <v-row>
                <v-col style="text-align: center">
                  {{ $i18n.t("widthrawNotAvaliable") }}
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text style="color: var(--black-text); font-size: 16px">
              {{ $i18n.t("playWagerText") }}
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
      <ul class="bg-bubbles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { vMaska } from "maska";
import { mapGetters } from "vuex";
export default {
  directives: { maska: vMaska },
  name: "CashoutPage",
  data() {
    return {
      amount: "0.00",
      account: "",
      selectedAmount: "",
      withdrawType: "",
      successWithdraw: false,
      wagerDialog: false,
      image: null,
      errorText: "",
      errDialog: false,
      verifyFlag: false,
      loading: false,
      hideButton: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUserInfo",
      payMethods: "getPayMethods",
    }),
    amountFormated() {
      const cleanedAmount = this.amount.replace(/[^0-9.]/g, "");
      const amountNumber = parseFloat(cleanedAmount);
      if (!isNaN(amountNumber)) {
        const roundedAmount = Math.floor(amountNumber * 100) / 100;
        return Number(roundedAmount.toFixed(2));
      } else {
        return null;
      }
    },
    totalAmount() {
      const amount = Number(this.amount);
      const feePercentage = this.filteredPayMethods[0].fee / 100;
      const feeAmount = amount * feePercentage;
      const totalAmount = amount + feeAmount;
      return totalAmount;
    },
    feeAmount() {
      const amount = Number(this.amountFormated);
      const feePercentage = this.filteredPayMethods[0].fee / 100;
      const feeAmount = amount * feePercentage;
      return feeAmount;
    },
    filteredPayMethods() {
      // type="withdraw"
      if (this.payMethods.length) {
        return this.payMethods.filter((card) => card.type === "WITHDRAW");
      } else {
        return false;
      }
    },
    cashoutDisable() {
      const amountNumber = this.totalAmount;
      return amountNumber > this.user.sum_available_for_withdraw;
    },
    disabledButton() {
      const amountNumber = this.totalAmount;
      return amountNumber < 1;
    },
  },
  async created() {},
  async mounted() {
    if (!this.payMethods.length) {
      await this.$store.dispatch("getPayMethods");
    }
  },
  methods: {
    formattedInput() {
      const cleanedAmount = this.amount.toString().replace(/[^0-9.]/g, "");
      const amountNumber = parseFloat(cleanedAmount);

      if (isNaN(amountNumber) || amountNumber <= 0) {
        this.amount = "0.00";
        this.hideButton = true;
        return;
      }

      this.amount = amountNumber.toFixed(2);
      this.hideButton = false;

      const numberUserSum = Number(this.user.sum_available_for_withdraw);
      const totalAmount = this.totalAmount;
      const currentFee = this.getCurrentFee(numberUserSum);

      if (totalAmount > numberUserSum) {
        const notification = {
          type: "error",
          message: this.$t("errorCashOut"),
        };

        this.$store.dispatch("alerts/add", notification);

        if (numberUserSum > 1) {
          this.amount = (numberUserSum - currentFee).toFixed(2);
        } else {
          this.amount = "0.00";
        }

        this.hideButton = true;
      }
    },
    enterAllSum() {
      this.hideButton = true;
      const numberUserSum = Number(this.user.sum_available_for_withdraw);
      const currentFee = this.getCurrentFee(numberUserSum);
      this.amount = numberUserSum - currentFee;
    },
    getCurrentFee(userSum) {
      const amount = userSum;
      const feePercentage =
        this.filteredPayMethods[0].fee /
        (100 + Number(this.filteredPayMethods[0].fee));
      const currentFee = amount * feePercentage;
      return currentFee;
    },
    allowNumbers(event) {
      const allowedChars = /[0-9]/;

      if (
        !allowedChars.test(event.key) &&
        event.key !== "Backspace" &&
        event.key !== "ArrowLeft" &&
        event.key !== "ArrowRight" &&
        event.key !== "."
      ) {
        event.preventDefault();
      }
    },
    selectMethod(method) {
      if (this.user.balance > 0) {
        this.withdrawType = method;
      }
    },
    async moveBack() {
      await this.$router.go(-1);
    },
    amountChange() {
      if (this.amount > this.withdrawType.withdraw_max_limit) {
        this.amount = this.withdrawType.withdraw_max_limit;
      } else if (this.amount < this.withdrawType.withdraw_min_limit) {
        this.amount = this.withdrawType.withdraw_min_limit;
      }
    },
    async getWithdrawUrl() {
      this.amount = parseFloat(this.amount).toFixed(2);
      const amountNumber = parseFloat(this.amount);
      const holdGame = localStorage.getItem("redirectToGame");
      if (amountNumber > this.user.sum_available_for_withdraw) {
        const notification = {
          type: "error",
          message: this.$t("errorCashOut"),
        };
        this.$store.dispatch("alerts/add", notification);
        return;
      }
      if (this.amount > 0) {
        this.loading = true;
        this.verifyFlag = false;
        let [res, err] = await this.$store.dispatch("getWithdrawLink", {
          payWay: this.filteredPayMethods[0].id,
          amount: Number(this.amountFormated),
          fee_amount: this.feeAmount,
          payAccount: this.account,
        });
        if (res) {
          this.successWithdraw = true;
        } else {
          switch (err) {
            case "invalid sum":
              this.errorText = this.$i18n.t("invalidWithdrawSum");
              break;
            case "verification need":
              this.verifyFlag = true;
              this.errorText = this.$i18n.t("needVerify");
              break;
            default:
              this.errorText = err;
          }
          const notification = {
            type: "error",
            message: this.$t("justError"),
          };
          this.$store.dispatch("alerts/add", notification);
          this.loading = false;
          return;
        }
        this.loading = false;
        await this.$router.push(`/`);
        location.reload();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.lh {
  line-height: 1.2;
}
.with {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}
.bg-bubbles {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  li {
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    // background-color: rgba(255, 255, 255, 0.25);
    background: url("../assets/images/bubles/white-heart-bubble.svg") 0 / 100%
      no-repeat;
    bottom: -160px;
    opacity: 0.3;
    -webkit-animation: square 25s infinite;
    animation: square 25s infinite;

    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    &:nth-child(1) {
      left: 10%;
      background: url("../assets/images/bubles/cards-spades-bubble.svg") 0 /
        100% no-repeat;
    }

    &:nth-child(2) {
      left: 20%;
      background: url("../assets/images/bubles/cards-diamonds-bubble.svg") 0 /
        100% no-repeat;
      width: 80px;
      height: 80px;

      animation-delay: 2s;
      animation-duration: 17s;
    }

    &:nth-child(3) {
      left: 25%;
      animation-delay: 4s;
      background: url("../assets/images/bubles/cards-clubs-bubble.svg") 0 / 100%
        no-repeat;
    }

    &:nth-child(4) {
      left: 40%;
      width: 60px;
      height: 60px;
      background: url("../assets/images/bubles/cards-spades-bubble.svg") 0 /
        100% no-repeat;
      animation-duration: 22s;

      // background-color: rgba(255, 255, 255, 0.25);
    }

    &:nth-child(5) {
      left: 70%;
      background: url("../assets/images/bubles/cards-clubs-bubble.svg") 0 / 100%
        no-repeat;
    }

    &:nth-child(6) {
      left: 80%;
      width: 120px;
      height: 120px;

      animation-delay: 3s;
      // background-color: rgba(255, 255, 255, 0.25);
    }

    &:nth-child(7) {
      left: 32%;
      width: 160px;
      height: 160px;

      animation-delay: 7s;
    }

    &:nth-child(8) {
      left: 55%;
      width: 20px;
      height: 20px;

      animation-delay: 15s;
      animation-duration: 40s;
    }

    &:nth-child(9) {
      left: 25%;
      width: 10px;
      height: 10px;

      animation-delay: 2s;
      animation-duration: 40s;
      // background-color: rgba(255, 255, 255, 0.25);
    }

    &:nth-child(10) {
      left: 90%;
      width: 160px;
      height: 160px;

      animation-delay: 11s;
    }
  }
}

@-webkit-keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1500px) rotate(600deg);
  }
}
@keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1500px) rotate(600deg);
  }
}
.color {
  background-color: var(--main-flamingo);
  flex: 1 1 100%;
  position: relative;
}
.cashout__wrapper {
  -ms-overflow-style: none;
  overflow: hidden;
  padding-top: 90px;
  @media (max-width: 992px) {
    padding-top: 65px;
  }
  .is-email-confirmation-bar-visible & {
    padding-top: 100px;
    @media (max-width: 992px) {
      padding-top: 140px;
    }
  }
}
.cashout__text {
  text-align: center;
  padding-bottom: 0;
  font-size: 24px;
  color: var(--white);
  font-weight: 900;
  line-height: 1.2;
  text-transform: uppercase;
}

.button-adaptive {
  max-width: 100%;
  min-width: 100% !important;
  @media (min-width: 500px) {
    min-width: 350px !important;
    max-width: 350px;
  }
}
.v-btn--disabled {
  opacity: 0.5;
}
.col {
  &__fix {
    flex: 0 1 50%;

    &_big-t {
    }
  }
}

.container-mod {
  margin-top: 15px;
  max-width: 650px !important;

  @media (max-width: 500px) {
    padding: 0;
  }
}

.uploader {
  .full-width .full-height {
    background-color: var(--main-flamingo);
  }
}
</style>
<style lang="scss">
.deposit-payment-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: var(--white);
  font-family: "Inter", Helvetica, Arial, sans-serif;
}
.paymethod__card {
  border-radius: 10px;
  box-shadow: 2px 2px 2px 2px var(--black-text);
  background: var(--main-flamingo);
  width: 100%;

  @media (min-width: 500px) {
    margin: 0 auto;
    max-width: 200px;
    min-width: max-content;
  }

  color: white;
  border: solid 1px var(--black-text);

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;

  img {
    border-radius: 10px;
    width: 100px;
    height: 100px;
  }
}

.deposit_row {
  //background-color: linear-gradient(99.4deg, var(--light-blue) 0%, var(--blue) 100%), var(--black-text);
  //border-radius: 5px;
  margin: 0 auto;
  margin-top: 0px !important;
  margin-bottom: 12px !important;
  max-width: 390px !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--white) !important;
  box-shadow: 0 0 42px hsla(0, 0%, 100%, 0.05),
    0 30px 50px rgba(22, 20, 33, 0.3);
  border-radius: 16px !important;
  @media (min-width: 992px) {
    margin-top: 0 !important;
  }
}

.limits__row {
  justify-content: center;
  color: white;
  max-width: 350px;
  font-size: 14px;
  line-height: 15px;
  padding-top: 5px;
}

.avaliable_withdraw {
}

.withdraw_amount {
}

.v-text-field__slot input {
  color: var(--black-text);
}

.seleced_type {
  background-color: var(--black-text);
  box-shadow: 0px 0px 10px var(--black-text);
}

.withdraw_amount input[type="number"] {
  -moz-appearance: textfield;
}

.withdraw_amount input::-webkit-outer-spin-button,
.withdraw_amount input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.card_number input[type="number"] {
  -moz-appearance: textfield;
}

.card_number input::-webkit-outer-spin-button,
.card_number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.withdraw_btn_col {
  min-width: 180px;
}

.withdraw_type_btn {
  background: none;
  width: 100%;
}

@media (max-width: 500px) {
  .withdraw_btn_col {
    max-width: 150px;
    min-width: 50%;
  }

  .withdraw_type_btn {
    font-size: 13px !important;
  }
}
</style>
