<template>
  <div>
    <div class="page">
      <mobile-game-header
        v-if="!errorFlag && !errorBonusFlag"
        class="pc-hide"
        @fullscreen="toFullScreen"
        :IsFullScreen="isFullScreen"
        @menu-open="menuDrawer = !menuDrawer"
      />
      <div class="game">
        <v-skeleton-loader
          width="100%"
          height="100%"
          type="image"
          class="skeleton-full"
          v-if="loadingFlag"
        ></v-skeleton-loader>
        <iframe
          v-if="!errorFlag && !errorBonusFlag && !loadingFlag"
          :src="frameUrl"
          id="frame_id_mobile"
          class="game-frame"
        ></iframe>
      </div>
      <error-game v-if="errorFlag" />
      <bonus-error-game v-if="errorBonusFlag" />
      <div class="mobile-hide">
        <desktop-mini-footer
          :IsFullScreen="isFullScreen"
          @menu-open="menuDrawer = !menuDrawer"
          @fullscreen="toFullScreen"
        />
      </div>
      <v-dialog
        v-model="menuDrawer"
        transition="slide-x-transition"
        overlay-opacity="0.8"
        content-class="noBoxShadow zIndex"
        max-width="fit-content"
      >
        <v-row class="mx-auto">
          <v-col>
            <img
              :src="`https://minio.takemybet.pro/ui-style/${logo}`"
              class="logo"
              alt=""
            />
          </v-col>
        </v-row>
        <v-row class="my-0 mx-auto" style="width: 100%">
          <v-col class="py-2">
            <v-btn class="all-buttons-fw" @click="switchMode" v-if="demoFlag">
              {{ $i18n.t("playOnMoney") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="my-0 mx-auto" style="width: 100%">
          <v-col class="py-2">
            <v-btn class="all-buttons-fw" to="/">
              <v-icon class="mr-1">mdi-home</v-icon>
              <span>{{ $i18n.t("lobby") }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="my-0 mx-auto" style="width: 100%">
          <v-col class="py-2">
            <v-btn @click="goToCashBox('deposit')" class="all-buttons-fw">
              <v-icon class="mr-1">mdi-bank</v-icon>
              <span> {{ $i18n.t("deposit2") }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="my-0 mx-auto" style="width: 100%">
          <v-col class="py-2">
            <v-btn @click="goToCashBox('cashout')" class="all-buttons-fw">
              <v-icon class="mr-1">mdi-cash</v-icon>
              <span>{{ $t("doWithdraw") }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="my-0 mx-auto" style="width: 100%" v-if="!demoFlag">
          <v-col class="py-2">
            <v-btn
              :loading="loadingFlag"
              class="all-buttons-fw"
              @click="randomSlot"
            >
              <v-icon class="mr-1">mdi-clover</v-icon>
              <span>{{ $t("randomSlot") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-dialog>
    </div>
  </div>
</template>

<script>
//import DepositBonusCards from "../components/DepositBonusCards";
// import chat from "../../plugins/jivo-chat-functions";
import ErrorGame from "./ErrorGame";
import BonusErrorGame from "./BonusErrorGame";
import MobileGameHeader from "./MobileGameHeader.vue";
import DesktopMiniFooter from "./DesktopMiniFooter.vue";
import { mapGetters } from "vuex";
// import VueFlip from 'vue-flip';
// import Preloader from "@/components/Preloader";

export default {
  name: "Game",
  //components: {DepositBonusCards},
  components: {
    // Preloader,
    // 'vue-flip': VueFlip
    MobileGameHeader,
    ErrorGame,
    BonusErrorGame,
    DesktopMiniFooter,
  },
  data() {
    return {
      isIOS: /iPhone|iPad|iPod/i.test(navigator.userAgent),
      loadingCount: 0,
      loadingFlag: false,
      isFullScreen: false,
      menuDrawer: false,
      fullScreenFlag: false,
      dynamicIframeHeight: "100vh",
      gameBarItems: [
        {
          icon: "list",
          clickFunction: () => {
            this.menuDrawer = !this.menuDrawer;
          },
        },
        {
          icon: "fullscreen",
          clickFunction: this.toFullscreen,
        },
      ],
      displayingSpins: [
        { front: null, back: null },
        { front: null, back: null },
        { front: null, back: null },
        { front: null, back: null },
        { front: null, back: null },
      ],
      flipped: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
      currentIndex: 0,
      animationUpdate: null,
      frameUrl: null,
      gameUrls: {},
      mobileDialog: false,
      demoFlag: false,
      errorBonusFlag: false,
      errorFlag: false,
    };
  },
  computed: {
    progressValue: {
      get() {
        return this.$store.state.levels.progressValue;
      },
      set(value) {
        this.$store.commit("levels/setProgressValue", value);
      },
    },
    nextBonusAmount: {
      get() {
        return this.$store.state.levels.nextBonusAmount;
      },
      set(value) {
        this.$store.commit("levels/setNextBonusAmount", value);
      },
    },
    betAmount: {
      get() {
        return this.$store.state.levels.betAmount;
      },
      set(value) {
        this.$store.commit("levels/setBetAmount", value);
      },
    },
    windowWidth() {
      return window.innerWidth;
    },
    ...mapGetters({
      logo: "styles/getLogo",
      topSpins: "tickerTopSpins",
      user: "getUserInfo",
      screenParams: "screenParams",
    }),
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeFrame);
    clearInterval(this.animationUpdate);
    // chat.showChat();
    if (this.user.id !== null) {
      this.$store.dispatch("awaitGetUserInfo");
    }
  },
  async mounted() {
    // chat.hideChat();
    //if ('id' in this.$route.params && 'demo' in this.$route.params) {
    //  console.log(`id:${ this.$route.params.id}, demo:${ this.$route.params.demo}`);
    //  this.demoFlag = this.$route.params.demo === 'true';
    //  console.log('this.user.balance', this.user.balance);
    //  console.log('this.demoFlag', this.demoFlag);
    //  if (this.demoFlag === false && this.user.balance === '0.00') {
    //    await this.$router.push('/deposit')
    //  }
    //  console.log('Demo', this.demoFlag);
    //  await this.getGameUrl(this.$route.params.id);
    //} else {
    //  await this.$router.push('/');
    //}
    if ("random" in this.$route.params) {
      //console.log('random');
      this.getRandomGameUrl();
      return;
    }
    const [gameID, demoFlag] = this.getGameByUrl();
    //const gameProducer = localStorage.getItem('gameProducer');
    if (gameID) {
      this.demoFlag = demoFlag;
      await this.getGameUrl(gameID);
    } else {
      this.errorFlag = true;
    }
    // if (localStorage.getItem('lastGame') && localStorage.getItem('demoFlag')) {
    //} else {
    //  this.errorFlag = true;
    //}
    window.addEventListener("resize", this.resizeFrame);
    // this.updateTopSpins();
    // this.animationUpdate = setInterval(this.updateTopSpins, 10000);
    this.iframeRef = this.$refs.iframeRef;
  },
  async beforeRouteLeave(to, from, next) {
    try {
      // await this.$store.dispatch("awaitGetUserInfo");
      const userId = this.$store.getters.getUserInfo.id;
      if (userId) {
        await this.$store.dispatch("levels/fetchBetAmount", userId);
        await new Promise((resolve) => setTimeout(resolve, 50));
      }
      this.$store.commit("levels/UPDATE_PROGRESS_VALUE");
    } catch (error) {
      console.error(error);
    }
    next();
  },
  watch: {
    "$store.state.socket2"(newValue) {
      console.log("newValue", newValue);
      if (newValue) {
        console.log("newValue", newValue);
        this.exitFullscreen();
        this.$router.push("/maintenance");
      }
    },
    "$store.state.showTimeoutModal"(newValue) {
      if (newValue) {
        this.exitFullscreen();
        console.log("CLOSED");
      }
    },
    "$store.state.wagerLimitError"(newValue) {
      if (newValue) {
        this.exitFullscreen();
        console.log("CLOSED");
      }
    },
    "$store.state.lossLimitError"(newValue) {
      if (newValue) {
        this.exitFullscreen();
        console.log("CLOSED");
      }
    },
  },
  methods: {
    getGameByUrl() {
      const url = window.location.pathname;
      const parts = url.split("/");
      // const gameID = Number(parts[2]);
      const gameID = parts[2];
      const demoFlag = parts.length > 3 && parts[3] === "demo";

      // console.log('gameID', gameID, 'demoFlag', demoFlag);
      return [gameID, demoFlag];
    },
    deviceType() {
      const width = this.screenParams.width || window.innerWidth;
      if (width >= 1024) {
        return "1";
      } else if (width >= 481 && width < 1024) {
        return "3";
      } else {
        return "2";
      }
    },
    async randomSlot() {
      if (!this.user.id) {
        try {
          await this.$router.push("/reg");
        } catch (error) {
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        }
      } else {
        if (this.user.balance === 0) {
          try {
            await this.$router.push("/deposit");
          } catch (error) {
            if (error.name !== "NavigationDuplicated") {
              throw error;
            }
          }
        } else {
          this.getRandomGameUrl();
        }
      }
    },
    goToCashBox(link) {
      const id = this.$route.params.id;
      const flag = this.demoFlag;
      if (link === "cashout") {
        this.$router.push("/cashout");

        // localStorage
        localStorage.setItem("redirectToGame", JSON.stringify({ id, flag }));
        const storedData = JSON.parse(localStorage.getItem("redirectToGame"));
        //console.log(storedData.id, storedData.flag);
      } else if (link === "deposit") {
        this.$router.push("/deposit");
        // localStorage
        localStorage.setItem("redirectToGame", JSON.stringify({ id, flag }));
        const storedData = JSON.parse(localStorage.getItem("redirectToGame"));
        //console.log(storedData.id, storedData.flag);
      }
    },
    async achieveRedirect() {
      try {
        await this.$router.push("/profile/promo-my/level");
      } catch (error) {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      }
    },
    async getRandomGameUrl() {
      this.loadingFlag = true;
      this.menuDrawer = false;
      const res = await this.$store.dispatch("getRandomGameUrl", {
        locale: this.$i18n.locale,
        client_type: this.deviceType(),
      });

      if (!res.demo && !res.real) {
        this.errorFlag = true;
      } else {
        //localStorage.setItem('lastGame', res.game_id);
        //localStorage.setItem('demoFlag', false)
        let params = { id: res.game_string_id };
        await this.$router.push({ name: "Game", params });
        if (this.$isMobile()) {
          this.mobileDialog = true;
        }
        this.frameUrl = res.real;
      }
      this.loadingFlag = false;
    },
    async getGameUrl(id) {
      // console.log(`id:${ id}, demo:${ this.demoFlag}`);
      const [url, err] = await this.$store.dispatch("getGameUrl", {
        id: id,
        locale: this.$i18n.locale,
        demo_flag: this.demoFlag,
        client_type: this.deviceType(),
      });
      if (!url) {
        if (err === "BonusLiveError") {
          this.errorBonusFlag = true;
        } else {
          this.errorFlag = true;
        }
      } else {
        if (this.$isMobile()) {
          this.mobileDialog = true;
        }
        this.gameUrls = url;
        this.frameUrl = this.demoFlag ? this.gameUrls.demo : this.gameUrls.real;
      }
    },
    async switchMode() {
      this.loadingFlag = true;
      this.menuDrawer = false;
      this.demoFlag = !this.demoFlag;
      if (!this.demoFlag && this.user.balance === "0.00") {
        await this.$router.push("/deposit");
      } else {
        this.loadingCount = 0;
        const [gameID, demoFlag] = this.getGameByUrl();
        let params = { id: gameID };
        if (this.demoFlag) {
          params.demo = "demo";
        }
        await this.$router.push({ name: "Game", params });
        await this.getGameUrl(gameID);
      }
      this.loadingFlag = false;
    },
    // flipCards(noTimeOut = false) {
    //   /*console.log('flipping')
    //   this.flipped.forEach((el, i) => {
    //     setTimeout(() => {
    //       this.flipped[i] = !this.flipped[i];
    //       }, i * 500)
    //   });*/
    //   //console.log('flipping cards', noTimeOut)
    //   if (noTimeOut) {
    //     for (let i = 0; i < 5; i++) {
    //       this.flipped[i.toString()] = !this.flipped[i.toString()];
    //     }
    //   } else {
    //     for (let i = 0; i < 5; i++) {
    //       setTimeout(function () {
    //         this.flipped[i.toString()] = !this.flipped[i.toString()];
    //       }.bind(this), i * 1000);
    //     }
    //   }

    // },
    // updateTopSpins() {
    //   //check pos

    //   let side = this.flipped['0'] ? 'front' : 'back';
    //   for (let i = 0; i < 5; i++) {
    //     this.displayingSpins[i][side] = this.topSpins[(this.currentIndex + (i + 1)) % this.topSpins.length];
    //   }
    //   this.currentIndex = (this.currentIndex + 5) % this.topSpins.length;
    //   //console.log('spins updaated!', this.displayingSpins)
    //   this.flipCards();
    // },
    // openChat() {
    //   chat.openChat();
    //   this.menuDrawer = false;
    // },
    toggleFullScreen() {
      //console.log('full screen triggered',);
      // let frame = document.getElementById('frame_id');
      const iframeElement = document.getElementById("frame_id");
      const doc = document.documentElement;
      console.log("click");
      if (iframeElement) {
        if (iframeElement.requestFullscreen) {
          iframeElement.requestFullscreen();
        } else if (iframeElement.mozRequestFullScreen) {
          iframeElement.mozRequestFullScreen();
        } else if (iframeElement.webkitRequestFullscreen) {
          doc.webkitRequestFullscreen();
          iframeElement.webkitRequestFullscreen();
        } else if (iframeElement.msRequestFullscreen) {
          iframeElement.msRequestFullscreen();
        } else if (iframeElement.webkitEnterFullscreen) {
          doc.webkitRequestFullscreen();
          iframeElement.webkitEnterFullscreen();
        }
      }
      //if (!this.fullScreenFlag) {
      //  frame.style.position = 'fixed';
      //  frame.style.left = '0';
      //  frame.style.top = '0';
      //  frame.style.height = '100%';
      //  frame.style.zIndex = '180';
      //} else {
      //  frame.style.position = '';
      //}
      //this.fullScreenFlag = !this.fullScreenFlag;
    },
    resizeFrame() {
      //console.log('resize triggered',);
      let frame = document.getElementById("frame_id");
      if (frame !== null) {
        document.getElementById("frame_id").dispatchEvent(new Event("resize"));
      }
    },
    // async moveHome() {
    //   await this.$router.push('/');
    // },
    // async frameLoad(event, context) {
    //   //console.log('event = ',event);
    //   //console.log('context = ', context)
    //   const iframe = event.target;
    //   if (iframe.requestFullscreen) {
    //     iframe.requestFullscreen();
    //   } else if (iframe.mozRequestFullScreen) {
    //     iframe.mozRequestFullScreen();
    //   } else if (iframe.webkitRequestFullscreen) {
    //     iframe.webkitRequestFullscreen();
    //   } else if (iframe.msRequestFullscreen) {
    //     iframe.msRequestFullscreen();
    //   }
    //   iframe.style.width = '100%';
    //   iframe.style.height = '100vh';
    //   if (this.loadingCount === 2) {
    //     await this.moveHome();
    //   } else {
    //     this.loadingCount += 1;
    //   }
    // },
    // telegramRedirect() {
    //   window.location.href = 'https://t.me/joinchat/AAAAAFEjZCGfsC08QS38ow'
    // },
    toFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      var doc = window.document;
      var docEl = doc.documentElement;

      var requestFullScreen =
        docEl.requestFullscreen ||
        docEl.mozRequestFullScreen ||
        docEl.webkitRequestFullScreen;
      var cancelFullScreen =
        doc.exitFullscreen ||
        doc.mozCancelFullScreen ||
        doc.webkitExitFullscreen;

      if (
        !doc.fullscreenElement &&
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement
      ) {
        requestFullScreen.call(docEl);
      } else {
        cancelFullScreen.call(doc);
      }
    },
    exitFullscreen() {
      if (this.errorBonusFlag || this.errorFlag) {
        return;
      } else if (!this.isFullScreen) {
        return;
      }
      this.isFullScreen = false;
      var doc = window.document;
      var cancelFullScreen =
        doc.exitFullscreen ||
        doc.mozCancelFullScreen ||
        doc.webkitExitFullscreen;
      cancelFullScreen.call(doc);
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-hide {
  @media (max-width: 1024px) {
    display: none;
  }
}
.pc-hide {
  @media (min-width: 1024px) {
    display: none;
  }
}
.page {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.game {
  width: 100%;
  flex: 1 1 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  position: relative;
  overflow: hidden;
}
.game-frame {
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 10;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.button-icon-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .v-icon {
    flex: 0 0 24px;
  }
}
.closeIcon {
  background-color: var(--red);
  border-radius: 4px;
  flex: 0 0 35px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 5px;
  z-index: 300;
  //@media (max-width:768px){
  //  display: none;
  //}
  &_error {
    display: flex;
  }
  @media (any-hover: hover) {
    cursor: pointer;
  }
}

.game-bar {
  @media (min-width: 992px) {
    display: none;
  }
  background-color: transparent !important;
  pointer-events: none;

  border-radius: 0 !important;
  width: 100%;
  height: 40px;
  position: fixed;
  z-index: 300;
  display: flex;
  padding: 0;

  &::before {
    content: "";
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 35px;
    top: 0px;
    left: 0;
    background-image: linear-gradient(var(--main-flamingo), transparent);

    @media (orientation: landscape) and (min-width: 600px) {
      background-image: linear-gradient(
        to right,
        var(--main-flamingo),
        transparent
      );
      width: 35px;
      height: 100%;
      top: 0;
      left: 10px;
    }
  }

  flex-direction: column-reverse;

  &__navigation {
    padding: 0;
    display: flex;
    position: relative;
    z-index: 2;

    .logo-bar {
      width: 50px;
      height: 50px;
      position: absolute;
      right: 0;
      top: 3px;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      @media (orientation: landscape) and (min-width: 600px) {
        right: -15px;
        top: 3px;
      }
      pointer-events: all;
    }
  }

  &__row {
    display: flex;
    pointer-events: all;
    margin: 0;
    //flex-direction: column-reverse;
  }

  &__column {
    padding: 5px;
  }

  &__icon {
    padding: 5px;
    background-color: var(--red);
    border-radius: 16px;
    flex: 0 0 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    .v-icon {
      font-size: 20px;
    }
  }

  &__info {
  }
}

.info-game-bar {
  display: flex;
  position: absolute;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 5px;
  left: 53%;
  line-height: 1;
  transform: translate(-50%, 0px);
  font-size: 9px;
  max-width: 250px;
  color: var(--white);
  &__wager {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
  }

  &__level {
  }
}

.logo {
  width: 100px;

  @media (orientation: landscape) and (min-width: 500px) {
    display: none;
  }

  @media (max-height: 500px) {
    display: none;
  }
}

@media (orientation: landscape) and (min-width: 600px) {
  .game-bar {
    height: 100%;
    padding-top: 0;
    width: 40px;

    &__navigation {
      height: 100%;
    }

    &__row {
      justify-content: flex-end;
      flex-direction: column;
    }

    &__column {
      height: 50px;
      scrollbar-width: none;
    }

    &__icon {
      height: auto;
      justify-content: center;
      margin-bottom: 10px;
      width: 100%;
    }
  }
}

.noBloodButton {
  width: 100%;
  min-height: 40px !important;
  padding: 0 5px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  border-radius: 5px;

  @media (max-width: 600px) {
  }

  background-color: var(--red) !important;
  color: var(--white) !important;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 100%),
    0px 0.3em 0.7em -0.01em rgb(45 35 66 / 100%),
    inset 0px -0.01em 0px rgb(58 65 111 / 100%) !important;

  &:active {
    box-shadow: inset 0px 0.1em 0.6em #3c4fe0 !important;
    transform: translateY(0em);
  }

  @media (any-hover: hover) {
    &:hover {
      box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%),
        0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0 !important;
      transform: translateY(-0.1em);
    }
  }
}

.icon-button {
  position: absolute !important;
  top: -5px;
  left: 5px;

  &._font {
    left: 0px;
  }
}

.col__order-f {
  @media (max-width: 992px) {
    order: 1;
    position: relative;
    flex: 0 1 50%;
  }
}

.col__order-s {
  @media (min-width: 992px) {
    align-self: center;
    text-align: center;
    max-width: 225px;
  }

  @media (max-width: 992px) {
    display: none;
  }
}

.col__order-t {
  @media (max-width: 992px) {
    order: 3;
    flex: 0 1 50%;
  }
}
.row__adaptive-t {
  @media (max-width: 992px) {
    //display: none;
    flex-direction: column;
  }

  .logo-pc {
    @media (max-width: 992px) {
      display: none;
    }
  }
}

.viewport-wrapper {
  border-radius: 12px;
}

.game__cashboxBtn {
  @media (min-width: 992px) {
    width: 100%;
  }
}

.ticker__item {
  cursor: pointer;
  display: inline-block;
  color: white;
  font-size: 13px !important;
  padding: 0 0.5rem;
}

.ticker__item:hover {
  img {
    box-shadow: 0 0 20px var(--red);
  }
}

.all-buttons-fw {
  font-size: 14px !important;
  .v-btn__content {
    justify-content: flex-start;
    span {
      flex: 1 1 100%;
    }
  }
  @media (min-width: 992px) {
    &:not(:last-child) {
      margin-bottom: 6px;
    }

    width: 100%;
  }
}
.v-overlay--active {
  //background: url('../assets/images/mafia_site_bg.png');
}

.mobile_drawer_btn {
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: var(--red);
  z-index: 9999;
  box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 100%),
    0px 0.3em 0.7em -0.01em rgb(45 35 66 / 100%),
    inset 0px -0.01em 0px rgb(58 65 111 / 100%) !important;
}

.mobile_drawer_btn-fullscreen {
  bottom: 10px;
  left: auto;
  right: 10px;
}

.game_container {
  padding: 0px;
  //max-height: 100vh;
  height: 100%;
  //height: calc(var(--vh, 1vh) * 100);
  //overflow: hidden;
  //min-height: 100vh;
  background-color: var(--main-flamingo);
  &._error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.frame-game {
  width: 100%;
  height: 100%;
  border: none;
}
.game_frame {
  width: 100%;
  height: 100%;
  position: relative;
  @media (max-width: 992px) {
    padding-top: 20px;
  }
  @media (orientation: landscape) and (min-width: 600px) and (max-width: 992px) {
    padding-left: 20px;
    padding-top: 0;
  }
}
.game_container::-webkit-scrollbar-thumb {
  display: none !important;
}
.game_frame__mob {
  //object-fit: contain;
  //position: absolute;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  //@media (min-height:500px) {
  //  height: 90vh !important;
  //}

  &._isNoIos {
    //padding-top: 10px;

    @media (orientation: landscape) and (min-width: 600px) {
      //padding-left: 10px;
      padding-top: 0;
    }

    //@media (min-height:500px) {
    //  height: 100vh !important;
    //}
  }
}

.router_view {
  max-width: 100% !important;
  flex: 1 1 100% !important;
}

#app {
  background-image: none;
}

.ratio-16x9 {
  height: 100% !important;
}

body {
}

.bonus_card {
  height: 120px;

  width: 90%;
  background: linear-gradient(99.4deg, var(--light-blue) 0%, var(--blue) 100%),
    var(--red);
  border-radius: 5px;
  margin: 0 auto;
  margin-bottom: 15px;
  color: #ffffff;
}

@media (max-width: 600px), (max-height: 450px) {
  body {
    zoom: 99.99999;
  }

  html {
    height: 100%;
    position: fixed;
  }
}
</style>
