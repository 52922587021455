<template>
  <div>
    <v-col class="d-flex align-center justify-space-between">
      <v-card-title
        class="pa-0 text-uppercase title-card"
        style="color: var(--white)"
      >
        {{ $i18n.t("bonusesTitle") }}
      </v-card-title>
      <template v-if="$route.path !== '/promotions-welcome'">
        <v-btn @click="openPromocodeDialog" class="all-buttons-t">{{
          $t("promoTitle")
        }}</v-btn>
        <PromoCode></PromoCode>
      </template>
    </v-col>
    <!-- slider bonuses -->
    <v-row class="mt-0" style="overflow: hidden">
      <v-col class="py-10">
        <swiper
          style="height: 450px"
          :slides-per-view="1"
          :breakpoints="{
            '320': {
              slidesPerView: 'auto',
              spaceBetween: 10,
            },
            '425': {
              slidesPerView: 'auto',
              spaceBetween: 10,
            },
            '600': {
              slidesPerView: 'auto',
              spaceBetween: 10,
            },
            '768': {
              slidesPerView: 'auto',
              spaceBetween: 10,
            },
            '1024': {
              slidesPerView: 'auto',
              spaceBetween: 10,
            },
          }"
        >
          <swiper-slide
            v-for="(cardDeposit, index) in depositBonuses"
            :key="'welcomeCard_' + index"
          >
            <deposit-bonus
              v-if="user.id !== null"
              :index="index"
              :bonusId="cardDeposit.id"
              :cardDeposit="cardDeposit"
            >
            </deposit-bonus>
          </swiper-slide>
          <swiper-slide v-if="user.id === null">
            <bonus-no-login> </bonus-no-login>
          </swiper-slide>
          <swiper-slide
            v-for="(cardSpins, index) in spinsBonuses"
            :key="'spinsCard_' + index"
          >
            <spins-bonus
              :index="index"
              :bonusId="cardSpins.id"
              :cardSpins="cardSpins"
            ></spins-bonus>
          </swiper-slide>
          <swiper-slide v-for="tournament in tournaments" :key="tournament.id">
            <bonus-tournament
              :tournament="tournament"
              :state="() => $store.state.tournaments.availableTournaments"
              @show-tournament-dialog="
                $emit('show-tournament-dialog', tournament)
              "
            ></bonus-tournament>
          </swiper-slide>
        </swiper>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DepositBonus from "@/components/Profile/Promo/BonusCards/DepositBonus/DepositBonus.vue";
import SpinsBonus from "@/components/Profile/Promo/BonusCards/SpinsBonus/SpinsBonus.vue";
import BonusTournament from "@/components/Profile/Promo/BonusCards/BonusTournament/BonusTournament.vue";
import BonusFutureOne from "@/components/Profile/Promo/BonusCards/BonusFutureOne/BonusFutureOne.vue";
import BonusNoLogin from "@/components/Profile/Promo/BonusCards/BonusNoLogin/BonusNoLogin.vue";
import PromoCode from "@/components/Profile/Promo/PromoCode/Promocode.vue";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
import { mapGetters } from "vuex";
export default {
  name: "WelcomeMobile",
  components: {
    Swiper,
    SwiperSlide,
    DepositBonus,
    BonusTournament,
    SpinsBonus,
    PromoCode,
    //BonusFutureOne,
    BonusNoLogin,
  },
  props: {
    spinsBonuses: Array,
    depositBonuses: Array,
    tournaments: Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "getUserInfo",
    }),
  },
  methods: {
    openPromocodeDialog() {
      this.$store.commit("promoCode/setDialogPromo", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-slide {
  width: 310px !important;
}
.swiper-container {
  overflow: visible !important;
}
.title-card {
  font-weight: bold;
  font-size: 18px !important;
  @media (max-width: 500px) {
    font-size: 14px !important;
  }
}
</style>
