import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import chat from "../plugins/jivo-chat-functions";
import router from "../router/router";
import createQueryString from "@/plugins/queryString";
// import helpers from "../helpers";
import { i18nPromise } from "@/plugins/i18n";
// const i18nInstance = await i18nPromise("en");
// import i18n from "@/plugins/i18n";
import i18n from "@/plugins/i18n";
import * as querystring from "querystring";
//========================================================================================================================================================
// import modules
import promoCode from "./modules/promoCode";
import sports from "./modules/sports";
import avatars from "./modules/avatars";
import styles from "./modules/styles";
import tournaments from "./modules/tournaments";

import depositView from "./modules/depositView";
import customScripts from "./modules/customScripts";
import search from "./modules/search";

import currencyY from "./modules/currencies";
import localization from "./modules/localization";
import countries from "./modules/countries";

import realityCheck from "./modules/realityCheck";
import verification from "./modules/verification";

import levels from "./modules/levels";

import alerts from "./modules/alerts";
import notifications from "./modules/notifications";
import gameModule from "./modules/gameModule";
import favoriteGames from "./modules/favoriteGames";

import banners from "./modules/banners";
// user

// reg
import regComponents from "./modules/regComponents";

// Bonuses
import bonuses from "./modules/bonuses";

// Limits
import limitsUpdate from "./modules/limits/limitsUpdate";
import wagerLimit from "./modules/limits/wagerLimit";
import lossLimit from "./modules/limits/lossLimit";

//========================================================================================================================================================
//const requireModule = require.context("./modules", false, /\.js$/);
//const modules = {};
//
//requireModule.keys().forEach((fileName) => {
//  const moduleName = fileName.replace(/(\.\/|\.js)/g, "");
//  modules[moduleName] =
//    requireModule(fileName).default || requireModule(fileName);
//});
//========================================================================================================================================================

Vue.use(Vuex);
// axios.defaults.xsrfCookieName = "Authorization";
// axios.defaults.xsrfHeaderName = "Auth-token";
// const worker = new Worker("/timerWorker.js");
export const WS_URL = "wss://main.takemybet.pro/users/ws";

// const socket = new WebSocket(WS_URL);
let socket;

export default new Vuex.Store({
  state: {
    //maxSessionTime: localStorage.getItem("maxSessionTime") || null,
    maxSessionTime: localStorage.getItem("maxSessionTime"),
    depositLimit: parseFloat(localStorage.getItem("depositLimit")),
    //========================================================================================================================================================
    signInModal: false,
    modalToDeleteDoc: false,
    timerStarted: false,
    openMobileSearch: false,
    showTimeoutModal: false,
    emailIsUnique: false,
    promocodeIsExists: false,
    usernameIsUnique: false,
    isUserNoInstant: false,
    //========================================================================================================================================================
    lossLimitError: false,
    //========================================================================================================================================================
    wagerLimitError: false,
    //========================================================================================================================================================
    depositWindow: false,
    total: 0,
    current: 0,
    gameProviders: {
      items: [],
      page: 1,
      pages: 1,
      size: 1,
      total: 1,
    },
    currencies: [],
    gameBlocks: [],
    games: [],
    gamesCache: {
      all: [],
      roulette: [],
      hot: [],
      top: [],
      new: [],
      slots: [],
    },
    bigGames: [],
    registerFlag: false,
    userInfo: {
      balance: null,
      birthday: null,
      bonusBalance: null,
      email: null,
      avatar: null,
      avatar_id: null,
      time_limit: null,
      sex: null,
      id: null,
      name: null,
      status: null,
      last_name: null,
      promo: null,
      username: null,
      tel: null,
      status_verification: null,
      doc1: null,
      doc2: null,
      doc3: null,
      address: {
        city: null,
        postal_code: null,
        address: null,
        country: null,
      },
    },
    userPayments: [],
    currentPage: 1,
    itemsPerPage: 10,
    payMethods: [],
    regPageBonuses: [],
    tickerTopSpins: [],
    tickerVisible: true,
    headerVisible: true,
    currency: "$",
    apkUrl: "https://via.placeholder.com/300?text=apk_file",
    verifySended: false,
    userIpBlockInfo: {
      blocked: null,
      country: null,
    },
    socket: {
      isConnected: false,
      message: "",
      reconnectError: false,
    },
    newPlayers: [],
    topPlayers: [],
    //bonusWelcomeOneFlipped: false,
    bonusTournamentOneFlipped: false,
    bonusWelcomeCards: [
      { flipped: false },
      // { flipped: false }
    ],
    providerGames: [],
    screenParams: {},
    isInstaReg: false,
    regData: {
      id: "",
      pass: "",
    },
    isBlockedCountry: false,
    isBlockedProduct: false,
    staticUserInfo: {},
    userTest: undefined,
    navDrawer: false,
  },
  mutations: {
    SET_NAV_DRAWER(state, value) {
      state.navDrawer = value;
    },
    updateRegData(state, payload) {
      state.regData = payload;
    },
    setIsInstaReg(state, value) {
      state.isInstaReg = value;
    },
    setScreenParams(state, params) {
      state.screenParams = params;
    },
    //========================================================================================================================================================
    setProviderGames(state, games) {
      state.providerGames = games;
    },
    //========================================================================================================================================================
    setSignInModal(state, value) {
      state.signInModal = value;
    },
    //========================================================================================================================================================

    pushNewPlayers(state, newPlayers) {
      state.newPlayers.push(...newPlayers);
    },
    pushTopPlayers(state, topPlayers) {
      state.topPlayers.push(...topPlayers);
    },
    flipCard(state, index) {
      state.bonusWelcomeCards[index].flipped =
        !state.bonusWelcomeCards[index].flipped;
    },
    //========================================================================================================================================================
    //setBonusWelcomeOneFlipped(state, value) {
    //  state.bonusWelcomeOneFlipped = value;
    //},
    //========================================================================================================================================================
    setBonusTournamentOneFlipped(state, value) {
      state.bonusTournamentOneFlipped = value;
    },
    //========================================================================================================================================================
    setLossLimitError(state, value) {
      state.lossLimitError = value;
    },

    //========================================================================================================================================================
    setWagerLimitError(state, value) {
      state.wagerLimitError = value;
    },
    //========================================================================================================================================================
    setDepositWindowError(state, value) {
      state.depositWindow = value;
    },

    setModalToDeleteDoc(state, value) {
      state.modalToDeleteDoc = value;
    },
    setTimeoutModal(state, value) {
      state.showTimeoutModal = value;
    },
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget;
      state.socket.isConnected = true;
      const token = localStorage.getItem("jwt");
      // console.log(token);
      if (localStorage.getItem("jwt")) {
        event.currentTarget.send(JSON.stringify({ token: token }));
      }
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false;
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event);
    },
    SOCKET_ONMESSAGE(state, message) {
      state.socket.message = message; // сохраняем сообщение в состояние
      // здесь только обновление состояния
    },

    UPDATE_NEW_PLAYERS(state, player) {
      if (!state.newPlayers) {
        state.newPlayers = [];
      }
      const existingIndex = state.newPlayers.findIndex(
        (p) => p.username === player.username
      );
      if (existingIndex !== -1) {
        state.newPlayers.splice(existingIndex, 1);
      }
      state.newPlayers.unshift(player);
    },

    UPDATE_TOP_PLAYERS(state, players) {
      state.topPlayers = players;
    },
    SOCKET_RECONNECT(state, count) {
      console.info(state, count);
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
    set(state, { type, items }) {
      state[type] = items;
    },
    invertTickerState(state) {
      state.tickerVisible = !state.tickerVisible;
    },
    changeUserBalance(state, { amount }) {
      if ((amount < 0 && state.user.balance < Math.abs(amount)) || amount > 0) {
        state.user.balance += amount;
      }
    },
    setIsUserNoInstant(state, value) {
      state.isUserNoInstant = value;
    },
    setOpenMobileSearch(state, value) {
      state.openMobileSearch = value;
    },
    clearUserInfo(state) {
      state.userInfo = {
        balance: null,
        birthday: null,
        bonusBalance: null,
        email: null,
        avatar: null,
        time_limit: null,
        avatar_id: null,
        sex: null,
        id: null,
        name: null,
        status: null,
        last_name: null,
        username: null,
        tel: null,
        address: {
          city: null,
          postal_code: null,
          address: null,
          country: null,
        },
      };
      chat.resetChat();
      localStorage.removeItem("logged");
    },
    setEmailIsUnique(state, isUnique) {
      state.emailIsUnique = isUnique;
    },
    setUsernameIsUnique(state, isUnique) {
      state.usernameIsUnique = isUnique;
    },
    setPromocodeIsExists(state, isExists) {
      state.promocodeIsExists = isExists;
    },
  },
  getters: {
    gameArrayLength: (state) => state.games.length,
    getGameBlocks: (state) => state.gameBlocks,
    getProviderGames(state) {
      return state.providerGames;
    },
    //========================================================================================================================================================

    getGameProviders(state) {
      return state.gameProviders;
    },
    getGames(state) {
      return state.games;
    },
    getTotal(state) {
      return state.total;
    },
    getCurrent(state) {
      return state.currencies;
    },
    bigGames(state) {
      return state.bigGames;
    },
    getNewPlayer(state) {
      return state.newPlayer;
    },
    getTopPlayers(state) {
      return state.topPlayers;
    },
    getRegisterFlag(state) {
      return state.registerFlag;
    },
    getUserInfo(state) {
      return state.userInfo;
    },
    getPayMethods(state) {
      return state.payMethods;
    },
    getUserPayments(state) {
      return state.userPayments;
    },
    getRegPageBonuses(state) {
      return state.regPageBonuses;
    },
    tickerTopSpins(state) {
      return state.tickerTopSpins;
    },
    tickerVisible(state) {
      return state.tickerVisible;
    },
    headerVisible(state) {
      return state.headerVisible;
    },
    currency(state) {
      return state.currency;
    },
    apkUrl(state) {
      return state.apkUrl;
    },
    verifySended(state) {
      return state.verifySended;
    },
    userBlockInfo(state) {
      return state.userIpBlockInfo;
    },
    screenParams: (state) => state.screenParams,
  },
  actions: {
    async awaitGetDefaultLanguage({ commit }, countryCode) {
      try {
        const response = await axios.get(
          `/api/informational/default_language_code?country_code=${countryCode}`
        );
        if (response.status === 200 && response.data.default_language_code) {
          i18nPromise(response.data.default_language_code);
        }
      } catch (error) {
        console.error("Error getting default language:", error);
      }
    },
    async SOCKET_ONMESSAGE({ commit, dispatch }, message) {
      commit("SOCKET_ONMESSAGE", message); // update state

      try {
        const parsedMessage = message.data;

        switch (message.type) {
          case "new_player": {
            commit("UPDATE_NEW_PLAYERS", parsedMessage);
            break;
          }
          case "top_players": {
            if (Array.isArray(parsedMessage)) {
              commit("UPDATE_TOP_PLAYERS", parsedMessage); // мутация для топ игроков
            }
            break;
          }
          case "status_update": {
            if (parsedMessage && parsedMessage.type) {
              const notification = {
                type: "success",
                message: `New level: ${parsedMessage.new_status}`,
              };
              dispatch("alerts/add", notification); // вызываем dispatch для асинхронной логики
            } else {
              console.error("Invalid status update message:", parsedMessage);
            }
            break;
          }
          case "notification": {
            const notificationMessage = i18n.t("checkNewMessage");
            const notification = {
              type: "info",
              message: notificationMessage,
            };
            console.log("parsedMessage", parsedMessage);
            dispatch("alerts/add", notification);
            dispatch("notifications/pushNewMessage", message.data);

            break;
          }
          case "LEVEL": {
            const notification = {
              type: "info",
              message: message.message,
            };
            console.log("parsedMessage", parsedMessage);
            dispatch("alerts/add", notification);
            dispatch("notifications/pushNewMessage", message);

            break;
          }
          case "user_blocked": {
            localStorage.removeItem("jwt");
            localStorage.setItem("playerBlocked", "true");
            const tempEnd = message.data.block_end;
            if (tempEnd) {
              const date = new Date(tempEnd);
              const formattedDate =
                date.toLocaleDateString() + ", " + date.toLocaleTimeString();
              localStorage.setItem("playerBlockedDate", formattedDate);
            }
            window.location.reload();
            break;
          }
          case "tournament": {
            let notifType;
            let notifMessage;
            if (message.data.tournament_status === "ongoing") {
              notifType = "success";
              notifMessage = i18n.t("tournamentOngoing");
            } else if (message.data.tournament_status === "completed") {
              notifMessage = i18n.t("tournamentCompleted");
            } else {
              notifType = "info";
            }
            const notification = {
              type: notifType,
              message: notifMessage,
            };
            dispatch("alerts/add", notification);
            dispatch(
              "tournaments/awaitGetTournament",
              message.data.tournament_id
            );
            commit("tournaments/set", {
              type: "modalTournamentCompleted",
              items: true,
            });
            break;
          }
          case "maintenance":
            commit("set", {
              type: "isBlockedProduct",
              items: message.data.status,
            });
            break;
          default:
            console.error("Unknown message type:", message.type);
        }
      } catch (error) {
        console.error("Error parsing message:", error);
      }
    },
    updateScreenParams({ commit }) {
      const params = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      commit("setScreenParams", params);
    },
    async checkEmailUniqueness({ commit }, email) {
      let res = false;
      try {
        const response = await axios.get(`/api/users/is_unique`, {
          params: { email },
        });

        if (response.status === 200 && response.data.is_unique) {
          commit("setEmailIsUnique", true);
          res = true;
        } else {
          commit("setEmailIsUnique", false);
        }
      } catch (error) {
        console.error(error);
        commit("setEmailIsUnique", false);
      }
      return res;
    },
    async checkUsernameUniqueness({ commit }, username) {
      let res = false;
      try {
        const response = await axios.get(`/api/users/is_unique`, {
          params: { username },
        });

        if (response.status === 200 && response.data.is_unique) {
          commit("setUsernameIsUnique", true);
          res = true;
        } else {
          commit("setUsernameIsUnique", false);
        }
      } catch (error) {
        console.error(error);
        commit("setUsernameIsUnique", false);
      }
      return res;
    },
    async activatePromocode({ commit }, promocode) {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      };
      await axios
        .post(`/api/promocodes/activate/${promocode}`, null, {
          headers,
        })
        .then((response) => {})
        .catch((error) => {
          // console.log(error);
          throw error;
        });
    },
    initializeWebSocket({ commit, dispatch }) {
      socket = new WebSocket(WS_URL);
      socket.addEventListener("open", (event) => {
        // console.error("WebSocket open:", event);
        commit("SOCKET_ONOPEN", event);
      });

      socket.addEventListener("message", (event) => {
        const message = JSON.parse(event.data);
        // console.error("WebSocket message:", event);
        dispatch("SOCKET_ONMESSAGE", message);
      });

      socket.addEventListener("close", (event) => {
        // console.error("WebSocket close:", event);
        commit("SOCKET_ONCLOSE", event);
      });
      socket.addEventListener("error", (event) => {
        console.error("WebSocket error:", event);
      });
    },
    async GetProviders({ commit }, filters) {
      const queryString = createQueryString(filters);
      let endpoint = `/api/games/providers?size=100`;
      let config = {};

      if (localStorage.getItem("jwt")) {
        endpoint = `/api/games/my/providers${queryString}`;
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        };
      }
      const response = await axios
        .get(endpoint, config)
        .then((response) => {
          if (response.status === 200) {
            // var providers = [];
            const providers = {
              items: response.data.items
                .filter((prov) => prov.name !== "All Games")
                .map((providerData) => ({
                  value: providerData.name,
                  text: providerData.name,
                  logo: providerData.logo,
                  enabled_games_count: providerData.enabled_games_count,
                  string_id: providerData.string_id,
                })),
              page: response.data.page,
              pages: response.data.pages,
              size: response.data.size,
              total: response.data.total,
            };
            // for (let i = 0; i < response.data.length; i++) {
            //   providers.push({
            //     string_id: response.data[i].string_id,
            //     value: response.data[i].name,
            //     text: response.data[i].name,
            //     enabled_games_count: response.data[i].enabled_games_count,
            //     // logo: require(`@/assets/images/game_providers_select/${response.data[i].Provider.logo}`),
            //     logo: response.data[i].logo,
            //   });
            // }
            commit("set", {
              type: "gameProviders",
              items: providers,
            });
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
    },
    // awaitGetGames
    async awaitGetGames({ commit, state }, data) {
      let queryString = `/api/games/filter?size=35`;

      const params = [];
      if (data.provider && data.provider !== "All Games") {
        params.push(`provider=${encodeURIComponent(data.provider)}`);
      }
      if (data.providerNext && data.providerNext !== "All Games") {
        params.push(`provider=${encodeURIComponent(data.providerNext)}`);
      }
      if (data.providerPrevious && data.providerPrevious !== "All Games") {
        params.push(`provider=${encodeURIComponent(data.providerPrevious)}`);
      }
      if (data.game) {
        params.push(`name=${encodeURIComponent(data.game)}`);
      }
      if (params.length) {
        queryString += `&${params.join("&")}`;
      }
      await axios
        .get(queryString)
        .then((response) => {
          if (response.status === 200) {
            const games = response.data.items
              .filter(({ Game }) => Game.active === true)
              .map(({ Game }) => Game);
            commit("set", { type: "games", items: games });
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
    },

    async getTickerData() {
      //getTopSpins
      await axios
        .get(`/api/getTopSpins`)
        .then((response) => {
          //console.log('awaitGetGames response = ',response)
          if (response.status === 200) {
            this.state.tickerTopSpins = response.data;
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
    },
    async getGameBlocks({ commit }) {
      //getTopSpins
      await axios
        .get(`/api/games/blocks`)
        .then((response) => {
          //console.log('awaitGetGames response = ',response)
          if (response.status === 200) {
            const blockData = response.data.map((block) => {
              return {
                block_order: block.block_order,
                games: [],
                // gamesLoaded: false,
                loadingBlock: true,
                id: block.id,
                is_active: block.is_active,
                name: block.name,
              };
            });

            commit("set", { type: "gameBlocks", items: blockData });
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
    },
    // async getGamesByType({ commit, state }, data) {
    //   let url = "";
    //   let type = data.type;
    //   switch (type) {
    //     case "roulette":
    //       url = "game_type=roulette";
    //       break;
    //     case "hot":
    //     case "top":
    //       url = "sub=top";
    //       break;
    //     case "new":
    //       url = "sub=new";
    //       break;
    //     case "slots":
    //       url = "game_type=slots";
    //       break;
    //     default:
    //       url = "game_type=slots";
    //   }
    //   url = url + "&size=2147483640";
    //   if (state.gamesCache[type].length === 0) {
    //     await axios
    //       .get(`/api/games/filter?${url}`)
    //       .then((response) => {
    //         if (response.status === 200) {
    //           let [smallGames, bigGames] = helpers.randomGamesArraySplit(
    //             response.data.items
    //           );
    //           state.gamesCache[type] = response.data.items;
    //           smallGames = smallGames.map(({ Game }) => Game);
    //           bigGames = bigGames.map(({ Game }) => Game);
    //           commit("set", { type: "bigGames", items: bigGames });
    //           commit("set", { type: "games", items: smallGames });
    //         }
    //       })
    //       .catch((error) => {
    //         console.log("error = ", error);
    //       });
    //   } else {
    //     let [smallGames, bigGames] = helpers.randomGamesArraySplit(
    //       state.gamesCache[type]
    //     );
    //     smallGames = smallGames.map(({ Game }) => Game);
    //     bigGames = bigGames.map(({ Game }) => Game);
    //     commit("set", { type: "bigGames", items: bigGames });
    //     commit("set", { type: "games", items: smallGames });
    //   }
    // },
    async awaitGetNewPlayer({ commit }) {
      await axios
        .get(`/api/users/get_new_players`)
        .then((response) => {
          if (response.status === 200) {
            commit("pushNewPlayers", response.data);
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
    },
    async awaitGetTopPlayers({ commit }) {
      await axios
        .get(`/api/users/get_top_players`)
        .then((response) => {
          if (response.status === 200) {
            commit("pushTopPlayers", response.data);
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
    },
    async cancelUserBonus({ dispatch }) {
      let res = false;
      await axios
        .post(`/api/stopBonus`, {})
        .then((response) => {
          //console.log('activateUserBonus', response.data)
          if (response.status === 200) {
            res = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      if (res) {
        await dispatch("getUserBonuses");
      }
      return res;
    },
    async getRegPageBonuses({ commit }) {
      await axios
        .get(`/api/getBonuses`)
        .then((response) => {
          //console.log('getBonuses response = ', response)
          if (response.status === 200) {
            commit("set", { type: "regPageBonuses", items: response.data });
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
    },
    async getPayMethods({ commit }) {
      await axios
        .get(`/api/payments/systems`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            const paymentSystems = response.data.map(
              (item) => item.PaymentSystem
            );
            //const filteredPaymentSystems = paymentSystems.filter(
            //  (system) => system.currency === currency
            //);
            //console.log("filteredPaymentSystems", filteredPaymentSystems);
            commit("set", {
              type: "payMethods",
              items: paymentSystems,
            });
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
    },
    async getDepositLink({ commit, dispatch }, data) {
      let res = false;
      let err = "";
      const jwt = localStorage.getItem("jwt"); // Get JWT from local storage

      await axios
        .post(
          `/cashbox-api/methods/deposit`,
          {
            amount: data.amount,
            fee_amount: data.fee_amount,
            payment_id: data.payment_id,
            first_deposit_bonus_enabled: data.first_deposit_bonus_enabled,
          },
          {
            headers: {
              Authorization: `Bearer ${jwt}`, // Add auth header with JWT token
            },
          }
        )
        .then((response) => {
          if (response.status === 200 && "url" in response.data) {
            res = response.data.url;
          }
        })
        .catch((error) => {
          console.log("error = ", error);
          if (error.response && error.response.status === 400) {
            commit("limitsUpdate/setDepositLimitError", true);
          }
          if (error.response && error.response.status === 500) {
            err = "Server error";
          }
        });
      return [res, err];
    },

    async getWithdrawLink({ commit }, data) {
      let res = false;
      let err = false;
      let json = {
        payment_id: data.payWay,
        amount: data.amount,
        fee_amount: data.fee_amount,
        account: data.payAccount,
        //currency: data.currency,
      };
      await axios
        .post(`/cashbox-api/methods/withdraw`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            res = true;
            commit("changeUserBalance", json.paySum);
          } else if (response.data.description) {
            err = response.data.description;
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
      return [res, err];
    },

    async logOut({ commit, dispatch, state }) {
      let userInfoTemplate = {
        balance: null,
        birthday: null,
        bonusBalance: null,
        email: null,
        avatar: null,
        avatar_id: null,
        time_limit: null,
        tel: null,
        sex: null,
        id: null,
        name: null,
        status: null,
        last_name: null,
        username: null,
        currencies: null,
      };
      dispatch("alerts/deleteAllEternals");
      dispatch("alerts/deleteAllHeader");
      commit("set", { type: "userInfo", items: userInfoTemplate });
      state.staticUserInfo = null;
      chat.resetChat();
      localStorage.removeItem("logged");
      localStorage.removeItem("jwt");
      window.location.reload();
      localStorage.clear();
      localStorage.setItem("cookieAccepted", "true");
      localStorage.setItem("visited", "true");
    },
    async singIn({ state, commit, dispatch }, data) {
      let res = false;
      await axios
        .post(
          `/api/auth/token`,
          querystring.stringify({
            username: data.username,
            password: data.password,
            fingerprint: JSON.stringify(data.fingerprint),
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response) {
            res = true;
            localStorage.removeItem("jwt");
            localStorage.setItem("jwt", response.data.access_token);
          }
        })
        .catch((error) => {
          throw error;
        });

      if (res) {
        await dispatch("awaitGetUserInfo");
      }
    },
    async signInGoogle({ dispatch }, data) {
      let res = false;
      await axios
        .post(`/api/auth/google`, { token: data.token })
        .then((response) => {
          if (response.status === 200) {
            res = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      await dispatch("awaitGetUserInfo");
      return res;
    },
    // refClick({ commit }, data) {
    //   let res = false;
    //   console.log(data);
    //   axios
    //     .post(`/api/click`, data)
    //     .then((response) => {
    //       if (response.status === 200) {
    //         res = true;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    //   return res;
    // },
    async GetCurrencies({ commit }) {
      await axios
        .get(`/api/informational/all_currencies`)
        .then((response) => {
          if (response.status === 200) {
            const currencies = response.data.currencies.map((currency) => ({
              text: currency,
              selected: false,
              selectToDeposit: false,
            }));
            commit("set", { type: "currencies", items: currencies });
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
    },
    async awaitEmailReg({ dispatch }, data) {
      let res = false;
      let error = "";
      let json = {
        promo: data.promo,
        username: data.username,
        email: data.email,
        password: data.pass,
        currency: data.currency,
        show_info: data.show_info,
        avatar_id: 1,
        fingerprint: JSON.stringify(data.fingerprint),
      };
      //console.log(json);
      await axios
        .post(`/api/reg/email`, json)
        .then((response) => {
          if (response.status === 201 && "token" in response.data) {
            localStorage.setItem("jwt", response.data.token);
            res = true;
            //console.log("res", res);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
      if (res) {
        await dispatch("awaitGetUserInfo");
        return [true, ""];
      } else {
        // console.log("i18", i18nInstance);
        return [false, error];
      }
    },
    async awaitClickReg({ commit, dispatch }, data) {
      let res = false;
      let error = "";
      let id = "";
      let pass = "";
      let json = {
        fingerprint: JSON.stringify(data.fingerprint),
        captcha: data.captcha,
        currency: data.currency,
        show_info: data.show_info,
      };
      await axios
        .post(`/api/reg/click`, json)
        .then((response) => {
          if (response.status === 201 && "token" in response.data) {
            id = response.data.clickId;
            pass = response.data.clickPass;
            res = true;
            localStorage.setItem("jwt", response.data.token);
          }
        })
        .catch((err) => {
          error = err;
          console.log(err);
        });
      if (res) {
        await dispatch("awaitGetUserInfo");
        return [res, id.toString(), pass];
      } else {
        return [false, false, false, error];
      }
    },
    async getTeleRegLink({ commit }, data) {
      let json = {
        affiliateId: localStorage.getItem("affiliate_id")
          ? localStorage.getItem("affiliate_id")
          : "",
        linkId: localStorage.getItem("link_id")
          ? localStorage.getItem("link_id")
          : "",
        fingerprint: JSON.stringify(data.fingerprint),
        tel: data.tel,
        currency: data.currency,
      };
      let link = false;
      let error = false;
      await axios
        .post(`/api/reg/telegram`, json)
        .then((response) => {
          //console.log('activateUserBonus', response.data)
          if (response.status === 200) {
            if ("telegram" in response.data) {
              link = response.data.telegram;
            }
            if ("description" in response.data) {
              error = true;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return [link, error];
    },
    async awaitGetUserInfo({ commit, state }) {
      await axios
        .get(`/api/users/me`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          //console.log('awaitGetUserInfo response = ',response)
          if (response.status === 200 && "id" in response.data) {
            let user = response.data;
            const balanceInDollars = user.balance;
            user.balance = balanceInDollars.toFixed(2);
            commit("set", { type: "userInfo", items: user });
            state.staticUserInfo = user;
            chat.setUserInfo(response.data);
            localStorage.setItem("logged", "true");
            //console.log("user.birthday????", user.birthday);
            if (user.birthday != null && !isNaN(Date.parse(user.birthday))) {
              let date = new Date(user.birthday);
              user.birthday = date
                .toISOString()
                .slice(0, 10)
                .replace(/-/g, "-");
              //console.log("user.birthday", user.birthday);
            } else {
              //let underage = new Date();
              //underage.setFullYear(underage.getFullYear() - 18);
              //user.birthday = underage
              //  .toISOString()
              //  .slice(0, 10)
              //  .replace(/-/g, "-");
              //console.log("user.birthday2", user.birthday);
            }
          } else if (response.data.description === "invalid token") {
            commit("clearUserInfo");
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
      //await dispatch('getUserBonuses');
    },
    async plusLvl({ commit }, userId) {
      try {
        await axios.put(`api/users/${userId}/status`, "3", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
      } catch (error) {
        console.error("Ошибка при отправке запроса:", error);
      }
    },
    async updateUserInfo({ commit }, data) {
      let res = false;
      let err = "";
      let json = {
        sex: data.sex,
        name: data.name,
        username: data.username,
        last_name: data.last_name,
        birthday: data.birthday,
        email: data.email,
        // tel: data.tel ? data.tel : null,
        tel: data.tel,
        address: data.address,
        country_code: data.country_code,
        avatar_id: data.avatar_id,
        time_limit: data.time_limit,
        //address: {
        //  city: data.city,
        //  postal_code: data.postal_code,
        //  address: data.address,
        //  country: data.country,
        //},
      };

      await axios
        .put(`/api/users/me`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            res = true;
            if (response.data.status == "success") {
              commit("set", { type: "userInfo", items: data });
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            err = error.response.data.detail;
          }
        });
      return [res, err];
    },
    async awaitUpdatePlayerInfo({ commit }, data) {
      let res = false;
      let err = "";
      await axios
        .put(`/api/users/me`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            res = true;
            if (response.data.status == "success") {
              commit("set", { type: "userInfo", items: data });
            }
          }
        })
        .catch((error) => {
          err = error;
        });
      return [res, err];
    },
    async updateUserPassword({ commit }, data) {
      let res = false;
      let json = {
        old_password: data.oldPass,
        new_password: data.newPass,
      };
      await axios
        .put(`/api/users/me/password`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            data.id = null;
            commit("set", { type: "userInfo", items: data });
            localStorage.removeItem("jwt");
            res = true;
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
      return res;
    },
    async selfLimit({ commit }, data) {
      let res = false;
      //let json = {
      //  dep_limit: data.dep_limit,
      //  dep_block_start_date: data.dep_block_start_date,
      //  dep_block_end: data.dep_block_end,
      //  loss_limit: data.loss_limit,
      //  loss_limit_start_date: data.loss_block_start_date,
      //  loss_limit_end: data.loss_block_end,
      //  wagering_limit: data.wager_limit,
      //  wagering_limit_start_date: data.wager_block_start_date,
      //  wagering_limit_end: data.wager_block_end,
      //};
      await axios
        .post(`/api/users/me/set_limit`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          res = response.status === 200 || response.status === 201;
        })
        .catch((error) => {
          console.log(error);
        });

      return res;
    },
    async selfBlock({ commit }, data) {
      let res = false;
      let json = {
        block: data.block,
        block_end: data.block_end,
      };
      await axios
        .post(`/api/users/me/set_block`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          res = response.status === 200 || response.status === 201;
        })
        .catch((error) => {
          console.log(error);
        });

      return res;
    },
    async getUserPaymentsHistory({ commit }) {
      await axios
        .get(`/api/users/user_payments`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })

        .then((response) => {
          if (response.status === 200) {
            let results = response.data.map((item) => {
              return {
                ...item.UserPayment,
                name: item.name,
              };
            });
            results.sort(
              (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
            );
            commit("set", { type: "userPayments", items: results });
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
    },
    async getGameUrl({ commit, dispatch }, data) {
      let url = false;
      let err = null;
      await axios
        .get(`/api/games/session_url/${data.id}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            language: data.locale, // use the current locale as the language parameter
            demo_flag: data.demo_flag,
            client_type: data.client_type,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            url = response.data;
          }
        })
        .catch((error) => {
          if (
            error.response.data.detail === "Bonus balance cannot be present"
          ) {
            err = "BonusLiveError";
          }
        });

      return [url, err];
    },
    async getRandomGameUrl({ commit, dispatch }, data) {
      let url = false;
      await axios
        .get(`/api/games/session_url/random/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            language: data.locale, // use the current locale as the language parameter
            client_type: data.client_type,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            url = response.data;
          }
        })
        .catch((error) => {});
      return url;
    },

    async getTvBetToken() {
      let res = false;
      await axios
        .get(`/api/getTvbetToken`)
        .then((response) => {
          //console.log('getGameLink no token response = ',response)
          if (response.status === 200) {
            res = response.data;
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
      return res;
    },
    async uploadDocs({ commit }, data) {
      let res = false;
      await axios
        .post(`/api/users/upload_docs`, data.formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          //console.log('activateUserBonus', response.data)
          if (response.status === 200) {
            res = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    },
    async deleteDocs({ commit }, data) {
      let res = false;
      await axios
        .post(`/api/users/delete_doc`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          //console.log('activateUserBonus', response.data)
          if (response.status === 200) {
            res = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    },
    async checkUserIpBlock({ commit }) {
      let blocked = false;
      let userCountry = null;
      await axios
        .get(`/api/check_ip`)
        .then((response) => {
          if (response.status === 200 && response.data.block) {
            blocked = response.data.block;
            userCountry = response.data.geo ? response.data.geo : null;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      if (process.env.NODE_ENV === "development") {
        blocked = false;
      }

      return [blocked, userCountry];
    },
    async sendResetCode({ commit }, data) {
      let res = false;
      let error_type = false;
      await axios
        .post(`/api/emailRecovery`, { email: data.email })
        .then((response) => {
          if (response.status === 200) {
            res = true;
            if (response.data.status && response.data.status === "400") {
              error_type = "email";
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return [res, error_type];
    },
    async resetPass({ commit }, data) {
      let res = false;
      await axios
        .post(`/api/newPass`, { pin: data.code, newPass: data.newPass })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.status &&
            response.data.status == "ok"
          ) {
            res = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    },
  },
  modules: {
    regComponents,
    customScripts,
    gameModule,
    promoCode,
    search,
    levels,
    bonuses,
    banners,
    depositView,
    currencyY,
    realityCheck,
    verification,
    localization,
    limitsUpdate,
    notifications,
    wagerLimit,
    lossLimit,
    sports,
    avatars,
    styles,
    countries,
    tournaments,
    favoriteGames,
    alerts,
  },
});
//========================================================================================================================================================
// How to get notifications
// 1. add to 'dispatch' in actions
// 2. add new notification error, info, success
//const notification = {
//  type: "success",
//  message: "message" this.$t('justError'),
//};
//dispatch("alerts/add", notification);
