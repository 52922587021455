<template>
  <v-card
    class="front-bonus"
    :style="{ 'background-image': `url(${tournament.logo_img})` }"
  >
    <v-card-title class="front-bonus__header header-bonus">
      <div class="header-bonus__label">
        <font-awesome-icon style="font-size: 12px" icon="trophy" />
      </div>
      <!-- {{ $i18n.t('bonusesFree') }} -->
      <div class="header-bonus__title">{{ $t("tournamentBonus") }}</div>

      <!-- @click="toggleCardFlip(index)"  -->
      <div @click="toggleFlip" class="header-bonus__label">
        <font-awesome-icon icon="info" style="font-size: 12px" />
      </div>
    </v-card-title>
    <v-card-text
      style="width: auto"
      class="py-0 front-bonus__main main-bonus"
      @click="toShowTournamentDialog"
    >
      <!--<div class="main-bonus__image">
              <img src="@/assets/images/bonus.png" width="100%" /> 
            </div>-->
      <div class="main-bonus__title">
        <span>
          {{ tournament.name }}
        </span>
        <p>{{ tournament.prize_pool_amount }} {{ user.currency }}</p>
      </div>
      <v-simple-table dark style="background-color: transparent; width: 100%">
        <template v-slot:default>
          <tbody color="var(--white)">
            <tr
              v-for="item in tournament.leaderboard_preview"
              :key="item.leaderboard_position"
            >
              <td
                style="
                  font-size: 14px;
                  color: var(--white);
                  border-bottom: 1px solid var(--gray);
                "
                class="px-1"
              >
                <b>{{ item.leaderboard_position }}.</b>
                <span>
                  {{ item.username }}
                </span>
              </td>
              <td style="border-bottom: 1px solid var(--gray)">
                <b>{{ item.current_score }}</b>
              </td>
              <td style="color: #ffd22f; border-bottom: 1px solid var(--gray)">
                <b>{{ item.final_prize_amount }} {{ user.currency }} </b>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions
      class="main-bonus__activate px-5"
      v-if="tournament.status === 'ongoing' && expireInSeconds > 0"
    >
      <div class="font-weight-bold timer-block item-timer">
        <span>{{ $t("timeLeftBonus") }}</span>
        <!-- expire_in_seconds -->
        <Countdown :expireInSeconds="expireInSeconds" />
      </div>
      <v-btn
        :loading="loadingFlag"
        v-if="$route.path === '/profile/promotions'"
        class="main-bonus__button item-timer"
        @click.stop="toJoin"
      >
        {{ $t("toJoin") }}
      </v-btn>
      <v-btn
        :loading="loadingFlag"
        v-else
        class="main-bonus__button item-timer"
        @click.stop="toShowTournamentDialog"
      >
        {{ $t("showInfo") }}
      </v-btn>
    </v-card-actions>
    <v-card-actions
      class="main-bonus__activate px-5"
      v-else-if="tournament.status === 'created'"
    >
      <div class="font-weight-bold timer-block item-timer">
        <span>{{ $t("tournamentWillStart") }}</span>
        <!-- expire_in_seconds -->
        <!-- <Countdown :expireInSeconds="expireInSeconds" /> -->
        {{ `${new Date(tournament.start_time).toLocaleString()}` }}
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import Countdown from "@/components/Countdown/Timer";
import { mapGetters } from "vuex";
export default {
  name: "BonusTournamentOneFront",
  components: {
    Countdown,
  },
  props: {
    tournament: {
      type: Object,
      required: true,
    },
    state: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loadingFlag: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUserInfo",
    }),
    expireInSeconds() {
      const now = new Date();
      const endTime = new Date(this.tournament.end_time);
      const differenceInSeconds = Math.max(
        Math.floor((endTime - now) / 1000),
        0
      );
      return differenceInSeconds;
    },
  },
  methods: {
    toShowTournamentDialog() {
      if (this.$route.path === "/profile/promotions") {
        this.$emit("show-tournament-dialog");
      } else {
        this.$router.push(`/tournament/${this.tournament.id}`);
      }
    },
    toggleFlip() {
      const { id, flipped } = this.tournament;
      this.$store.dispatch("tournaments/flipTournament", {
        state: this.state,
        id: id,
        flipped: !flipped,
      });
    },
    async toJoin() {
      this.loadingFlag = true;
      let notification;
      try {
        await this.$store.dispatch(
          "tournaments/awaitJoinTournament",
          this.tournament.id
        );
        notification = {
          type: "success",
          message: this.$t("tournamentJoined"),
        };
        this.$router.push(`/tournament/${this.tournament.id}`);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          const detail = error.response.data.detail;
          const { loc, reason } = detail || {};
          if (reason && reason.includes("User already participates")) {
            notification = {
              type: "error",
              message: this.$t("userAlreadyParticipates"),
            };
          } else if (reason && reason.includes("overlapping games")) {
            notification = {
              type: "error",
              message: this.$t("overlappingGames"),
            };
          }
        } else {
          notification = {
            type: "error",
            message: this.$t("justError"),
          };
        }
      } finally {
        this.$store.dispatch("alerts/add", notification);
        this.loadingFlag = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.front-bonus {
  //background: linear-gradient(173.41deg,#0094ff 4.14%,#5607d6 87.72%)!important;
  //background: var(--dark-flamingo)!important;
  //background: url("../../../../../assets/images/tournamentCard.jpg") 0 70% /
  //  cover no-repeat;
  color: var(--white) !important;
  //height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px !important;
  &__header {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
  }
  &__content {
  }
  &__main {
    flex: 1 1 100%;
    position: relative;
    z-index: 2;
  }

  &__timer {
    position: relative;
    z-index: 2;
  }
}
.header-bonus {
  padding: 10px;

  &__label {
    height: 24px;
    justify-content: center;

    position: relative;
    width: 24px;
    align-items: center;
    display: flex;
    position: relative;
    transition: background-color 0.3s;
    &:last-child {
      cursor: pointer;

      @media (any-hover: hover) {
        &:hover {
          &::before {
            background-color: hsla(0, 0%, 100%, 0.4);
          }
        }
      }
    }
    &::before {
      background-color: hsla(0, 0%, 100%, 0.2);
      border-radius: 50%;
      content: "";
      //height: calc(100% - 20px);
      height: 24px;
      width: 24px;
      position: absolute;
      //width: calc(100% - 20px);
    }
  }
}
.main-bonus {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  @media (any-hover: hover) {
    &:hover {
      .main-bonus__title {
        transform: scale(1.08);
      }
    }
  }
  &__image {
  }

  &__title {
    word-break: break-word;
    text-transform: uppercase;
    transition: all 0.3s;
    font-weight: 900;
    color: var(--white) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1;
    min-height: 100px;
    span {
      max-height: 95px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 47px;
    }
    p {
      color: #ffd22f;
      max-width: 240px;
      align-items: center;
      font-size: 20px;
      background-color: var(--dark-flamingo);
      border-radius: 16px;
      padding: 5px 10px;
    }
  }
  &__activate {
    position: relative;
    z-index: 2;
    flex-direction: column;
    gap: 4px;
  }
  &__button {
    min-height: 30px;
    padding: 0 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 16px !important;
    font-weight: 700;
    width: 100%;
    position: relative;
    z-index: 5;
    color: var(--white) !important;
    background-color: var(--red) !important;
    border-radius: 6px;
    transition: all 0.3s;
    @media (any-hover: hover) {
      &:hover {
        box-shadow: 0 0 15px var(--red) !important;
      }
    }
  }
}
.timer-block {
  min-height: 48px;
  width: 100%;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--red);
  border-radius: 6px;
  background-color: rgb(78 0 72 / 85%);
  div {
    font-size: 12px;
  }
  span {
    font-size: 12px;
    color: var(--white-design);
  }
}
.item-timer {
  flex: 0 1 50%;
}
</style>
