<template>
  <li class="notifications-list__item">
    <div class="notification-item" v-if="!notifications.length">
      <div class="notification-item__icon">
        <v-icon color="var(--white)">mdi-check</v-icon>
      </div>
      <div class="notification-item__content">
        <span>
          {{ $t("notificationsEmpty") }}
        </span>
        <!-- <p>
              
            </p> -->
      </div>
    </div>
    <template v-else>
      <div
        class="notification-item"
        v-for="item in notifications"
        :key="item.id"
        @click="$router.push('/profile/info/notifications')"
      >
        <div class="notification-item__close" @click.stop="toViewNotif(item)">
          <v-icon color="var(--white)" v-if="!item.loadingItem">close</v-icon>
          <v-progress-circular
            v-else
            indeterminate
            color="var(--white)"
          ></v-progress-circular>
        </div>
        <div class="notification-item__icon">
          <v-icon color="var(--white)">{{ getIconByType(item.type) }}</v-icon>
        </div>
        <div class="notification-item__content">
          <p>
            {{ item.message }}
          </p>
          <time-ago
            class="notification-item__time"
            :refresh="60"
            :datetime="item.created_at"
            :locale="$i18n.locale"
            tooltip
          >
          </time-ago>
          <!-- <p>
                
              </p> -->
        </div>
      </div>
    </template>
  </li>
</template>

<script>
import { TimeAgo } from "vue2-timeago";
import { mapGetters } from "vuex";
export default {
  name: "NotificationItem",
  components: {
    TimeAgo,
  },
  computed: {
    ...mapGetters({
      notifications: "notifications/getNotViewedNotifications",
      user: "getUserInfo",
    }),
  },
  methods: {
    getIconByType(type) {
      switch (type) {
        case "MESSAGE":
          return "mdi-bullhorn";
        case "BONUS":
          return "mdi-crown";
        case "BAN":
          return "mdi-cancel";
        case "COMPENSATION":
          return "mdi-cash-fast";
        case "LEVEL":
          return "mdi-trophy";
      }
    },
    async toViewNotif(item) {
      item.loadingItem = true;
      item.is_viewed = true;
      try {
        await this.$store.dispatch(
          "notifications/awaitViewedNotification",
          item
        );
      } catch (error) {
        console.log(error);
      } finally {
        item.loadingItem = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin hover-dropdown {
  border-radius: 6px;
  transition: background-color 0.3s;
  @media (any-hover: hover) {
    &:hover {
      background-color: #c935db;
    }
  }
}
.notification-item {
  grid-gap: 20px;
  border-radius: 6px;
  @include hover-dropdown;
  color: var(--white);
  cursor: pointer;
  display: grid;
  grid-template-columns: 22px 1fr;
  padding: 10px 15px 10px 10px;
  position: relative;
  &__close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
  }
  &__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 15px;
    p {
      font-weight: bold;
      margin-bottom: 0;
      text-align: left;
    }
  }
  &__time {
    color: var(--white-details);
    display: block;
    font-size: 12px;
    margin-top: 5px;
  }
}
</style>
