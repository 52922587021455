<template>
  <div class="upper-padding" :class="{ 'is-noLogin': user.id === null }">
    <div
      :class="{
        'pa-0': $route.path !== '/promotions-welcome',
        container: $route.path === '/promotions-welcome',
      }"
      :style="{
        'max-width': $route.path !== '/promotions-welcome' ? '100%' : '900px',
      }"
    >
      <template v-if="!loadingFlag">
        <welcome-mobile
          v-if="$isMobile()"
          :spinsBonuses="filteredSpinsBonuses"
          :depositBonuses="filteredDepositBonuses"
          :tournaments="tournaments.items"
        ></welcome-mobile>
        <WelcomePC
          v-else
          @show-tournament-dialog="toShowTournamentDialog"
          :spinsBonuses="filteredSpinsBonuses"
          :depositBonuses="filteredDepositBonuses"
          :tournaments="tournaments.items"
        ></WelcomePC>
      </template>
      <template v-else>
        <Preloader class="text-center"></Preloader>
      </template>
      <TournamentDialog
        :tournament="selectedTournament"
        :visible="showTournamentDialog"
        @close-modal="showTournamentDialog = false"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Preloader from "@/components/Preloader";
import TournamentDialog from "@/components/Profile/Promo/BonusCards/BonusTournament/TournamentDialog";
import WelcomeMobile from "./WelcomeMobile";
import WelcomePC from "./WelcomePC";
export default {
  name: "Welcome",
  components: {
    WelcomeMobile,
    WelcomePC,
    Preloader,
    TournamentDialog,
  },
  data() {
    return {
      loadingFlag: false,
      selectedTournament: {},
      showTournamentDialog: false,
    };
  },
  async mounted() {
    this.loadingFlag = true;
    if (localStorage.getItem("jwt")) {
      await this.toGetTournaments();
      await this.toGetBonuses();
    }
    this.loadingFlag = false;
  },
  methods: {
    async toGetTournaments() {
      try {
        await this.$store.dispatch("tournaments/awaitGetAvailableTournaments");
      } catch (error) {
        console.error(error);
      }
    },
    async toGetBonuses() {
      try {
        await this.$store.dispatch("bonuses/getUserBonuses");
      } catch (error) {
        console.error(error);
      }
    },
    toShowTournamentDialog(tournament) {
      this.selectedTournament = tournament;
      this.showTournamentDialog = true;
    },
  },
  computed: {
    ...mapGetters({
      user: "getUserInfo",
      spinsBonuses: "bonuses/getSpinsBonuses",
      depositBonuses: "bonuses/getDepositBonuses",
      tournaments: "tournaments/getAvailableTournaments",
    }),
    filteredSpinsBonuses() {
      if (this.user.id !== null) {
        // bonus.type === 'welcome' &&
        return this.spinsBonuses.filter((bonus) => bonus.status === "created");
      } else {
        return [];
      }
    },
    filteredDepositBonuses() {
      if (this.user.id !== null) {
        // && bonus.type !== 'next_deposit'
        return this.depositBonuses.filter(
          (bonus) => bonus.status === "created"
        );
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upper-padding {
  padding-top: 15px;
  @media (max-width: 600px) {
    padding-top: 48px;
  }
}
.is-noLogin {
  margin-top: 50px;
  @media (max-width: 992px) {
    //padding-top: 70px;
  }
  background-color: var(--main-flamingo);
}
.container {
  .is-noLogin & {
    background-color: var(--dark-flamingo);
    border-radius: 16px;
  }
}
</style>
