<template>
  <div
    id="app"
    :class="{
      'is-email-confirmation-bar-visible': headerNotif,
      'is-desktop': !$isMobile(),
      'is-mobile': $isMobile(),
      _blocked: isBlockedCountry,
    }"
  >
    <v-app v-if="!isBlockedCountry && !isBlockedProduct">
      <RealityTimer v-if="showDialog"></RealityTimer>
      <LimitError v-if="depositLimitError"></LimitError>
      <ModalTournamentCompleted></ModalTournamentCompleted>
      <LossError></LossError>
      <WagerError></WagerError>
      <!-- <SignIn></SignIn> -->
      <!-- <Registration /> -->
      <Header v-show="!loadingFlag" v-if="!hideHeader.includes($route.name)">
      </Header>
      <keep-alive
        v-if="!loadingFlag"
        :key="keepAliveKey"
        include="Home,Profile,Rules,DepositMainPage,CashoutPage,Tournament"
      >
        <router-view class="router_view" id="routerView" />
      </keep-alive>

      <Footer
        v-show="!loadingFlag"
        v-if="!hideBottom.includes($route.name)"
      ></Footer>
      <Ticker
        v-show="!loadingFlag"
        v-if="!hideBottom.includes($route.name)"
      ></Ticker>
      <BottomMenu
        v-if="!hideHeader.includes($route.name) && userInfo.id !== null"
      ></BottomMenu>
      <transition name="fade">
        <v-overlay opacity="1" z-index="400" v-if="loadingFlag">
          <v-container class="logo__container">
            <div class="logo__preloader">
              <img
                class=""
                :src="`https://minio.takemybet.pro/ui-style/${logo}`"
                alt="logo"
              />
            </div>
          </v-container>
          <!-- <p>
              <Preloader :disable-scroll="true"></Preloader>
            </p> -->
        </v-overlay>
      </transition>
      <!-- <v-dialog v-model="KzInfoDialog" max-width="350" overlay-opacity="0.8">
        <v-card class="basic__card" style="color: var(--white);background-color:var(--main-flamingo)!important">
          <v-card-title>
            <v-row>
              <v-col class="text-center font-weight-bold">
                {{ $i18n.t('info') }}
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text style="color: var(--white);font-size: 16px;max-width: 350px">
            <span v-html="$i18n.t('KZlang')">
            </span>
          </v-card-text>
          <v-card-actions>
            <v-btn class="all-buttons-fw" @click="KzInfoDialog = false">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->

      <Alerts />
    </v-app>
    <blocked v-else />
  </div>
</template>
<script>
import Header from "@/components/Header/Header";
import BottomMenu from "@/components/BottomMenu/BottomMenu";
import blocked from "../blocked-region-page/blocked.vue";
import Footer from "./components/Footer";
import Ticker from "./components/Ticker";
// import helpers from "./helpers";
import Preloader from "./components/Preloader";
import fingerprint from "./plugins/fingerprint";
import { mapActions, mapGetters, mapState } from "vuex";
import router from "./router/router";
import axios from "axios";
import store from "@/store/store";
import RealityTimer from "@/components/Profile/Popups/RealityTimer";
import LimitError from "@/components/Profile/Popups/LimitsPopup/LimitError";
import LossError from "@/components/Profile/Popups/LimitsPopup/LossError";
import WagerError from "@/components/Profile/Popups/LimitsPopup/WagerError";
import Alerts from "@/components/Profile/Alerts/Alerts";
import { startTimer } from "./timerWorker";
import Registration from "./components/Authorization/Registration/Registration.vue";
import SignIn from "@/components/Authorization/Login/Login";
import ModalTournamentCompleted from "./views/Tournament/ModalTournamentCompleted.vue";

export default {
  components: {
    blocked,
    //SignIn,
    //Registration,
    RealityTimer,
    LimitError,
    WagerError,
    LossError,
    // Preloader,
    Ticker,
    Header,
    Alerts,
    BottomMenu,
    Footer,
    ModalTournamentCompleted,
  },
  created() {
    // document.documentElement.style.setProperty("--main-flamingo", "#fc8eac");
    if (this.isBlockedCountry || this.isBlockedProduct) {
      return;
    }
    if (localStorage.getItem("playerBlocked")) {
      const foreverMessage = this.$t("playerBlockedForever");
      const tempMessage = this.$t("playerBlockedTemp");
      const date = localStorage.getItem("playerBlockedDate");
      const notification = {
        type: "info",
        message: date ? tempMessage : foreverMessage,
        detail: date,
      };
      this.$store.dispatch("alerts/add", notification);
      localStorage.removeItem("playerBlocked");
      localStorage.removeItem("playerBlockedDate");
    }
    this.$store.dispatch("initializeWebSocket");
    this.$store.dispatch("customScripts/awaitGetScripts").then(() => {
      this.addScripts();
    });
  },
  name: "App",
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      logo: "styles/getLogo",
    }),
    isBlockedCountry() {
      return this.$store.state.isBlockedCountry;
    },
    isBlockedProduct() {
      return this.$store.state.isBlockedProduct;
    },
    headerNotif() {
      return this.$store.state.alerts.headerNotifications.length;
    },
    showDialog() {
      return this.$store.state.showTimeoutModal;
    },
    depositLimitError() {
      return this.$store.state.limitsUpdate.depositLimitError;
    },
    lossLimitError() {
      return this.$store.state.lossLimitError;
    },
    wagerLimitError() {
      return this.$store.state.wagerLimitError;
    },
  },
  data() {
    return {
      loadingFlag: true,
      // tickerKey: helpers.generateGuid(),
      hideHeader: ["Game", "Sport"],
      hideBottom: ["TvBet", "Game", "DepositMainPage", "CashoutPage", "Sport"],
      KzInfoDialog: false,
      keepAliveKey: 0,
      betAmount: 0,
      deletedNotification: null,
    };
  },
  watch: {
    async userInfo(val, oldVal) {
      // console.log('USERINFO CHANGED!!!', val)
      //console.log(val);
      //if (val.id != null) {
      //  await this.$store.dispatch('levels/fetchBetAmount', val.id);
      //  await this.$store.dispatch('levels/awaitGetLevels', val.level_name);
      //  setTimeout(() => {
      //    // return progressValue;
      //  }, 50);
      //}

      if (val.id !== oldVal.id) {
        //this.loadingFlag = true;
        //this.loadingFlag = false;
        setTimeout(() => {
          this.keepAliveKey += 1;
        }, 400);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  async mounted() {
    if (this.isBlockedCountry || this.isBlockedProduct) {
      return;
    }
    if (localStorage.getItem("jwt")) {
      const filters = {
        page: 1,
        size: 25,
      };
      await this.$store.dispatch("notifications/awaitGetNotifications");
      await this.$store.dispatch(
        "favoriteGames/awaitGetFavoriteGames",
        filters
      );
      await this.$store.dispatch("levels/fetchBetAmount", this.userInfo.id);
      await this.$store.dispatch(
        "levels/awaitGetLevels",
        this.userInfo.level_name
      );
      setTimeout(() => {
        this.$store.commit("levels/UPDATE_PROGRESS_VALUE");
        // return progressValue;
      }, 50);
      await this.$store.dispatch("bonuses/getUserBonuses");
    }
    this.$store.dispatch("updateScreenParams");
    window.addEventListener("resize", this.handleResize);
    const savedMaxSessionTime =
      localStorage.getItem("endTime") || this.userInfo.time_limit;
    //const savedMaxSessionTime = this.userInfo.time_limit;
    if (savedMaxSessionTime) {
      startTimer(Number(savedMaxSessionTime)); // Converting to number
    }
    this.loadingFlag = true;
    let visited = false;
    if (typeof Storage !== "undefined") {
      visited = localStorage.getItem("visited");
    }
    // let [blocked, country] = await this.$store.dispatch('checkUserIpBlock');
    // if (blocked) {
    //   this.$store.commit('set', {
    //     type: 'userIpBlockInfo', items: {
    //       blocked: blocked,
    //       country: country,
    //     }
    //   });
    //   if (this.$route.path !== '/block') {
    //     await router.push('/block');
    //   }
    //   this.loadingFlag = false;
    //   return
    // } else if (country === 'KZ' && !visited) {
    //   this.KzInfoDialog = true;
    // }

    if (typeof Storage !== "undefined") {
      localStorage.setItem("visited", "true");
    }
    // window.onpageshow = function (event) {
    //   if (event.persisted) {
    //     this.tickerKey = helpers.generateGuid()
    //   }
    // }.bind(this);
    this.$cookie.set("click_id", this.$route.query.click_id || "");
    this.$cookie.set("partner_id", this.$route.query.partner_id || "");
    this.$cookie.set("offer_id", this.$route.query.offer_id || "");
    //const linkId = this.$cookie.get('click_id')
    //const partnerId = this.$cookie.get('partner_id')
    //const offerId = this.$cookie.get('offer_id')
    //console.log('partnerId', partnerId);
    //console.log('offerId', offerId);
    // console.log('linkId', linkId);
    let ip = await fingerprint.get();
    let json = {
      affiliateId: localStorage.getItem("affiliate_id")
        ? localStorage.getItem("affiliate_id")
        : "",
      linkId: localStorage.getItem("link_id")
        ? localStorage.getItem("link_id")
        : "",
      userIp: "hash" in ip ? ip.hash : "",
    };
    // await this.$store.dispatch('refClick', json);

    await this.$store.dispatch("GetCurrencies");
    await this.$store.dispatch("localization/awaitGetLocalizations");

    //await this.$store.dispatch('getRegPageBonuses');
    // await this.$store.dispatch('getTickerData');
    this.createNotifications();
    this.loadingFlag = false;
    // axios.interceptors.response.use((response) => {
    //   return response;
    // }, (error) => {
    //   if (error.response && error.response.status === 400 && error.response.data.detail.includes("User is blocked")) {
    //     localStorage.clear();
    //     location.reload();
    //   }
    //   return Promise.reject(error);
    // });
  },
  methods: {
    addScripts() {
      const scripts = this.$store.getters["customScripts/getScripts"];
      // console.log('scripts', scripts);
      scripts.forEach((script) => {
        if (script.type === "meta") {
          const metaTag = document.createElement("meta");
          const attributeMatches = script.code.match(
            /(?<=<[^>]*)([\w-]+)(?:\s*=\s*(?:"([^"]*)"|'([^']*)'))?(?=[^>]*>)/g
          );
          if (attributeMatches) {
            attributeMatches.forEach((attribute) => {
              if (attribute !== "meta") {
                const match = attribute.match(
                  /([\w-]+)(?:\s*=\s*(?:"([^"]*)"|'([^']*)'|[^>\s]*))?/
                );
                if (match) {
                  const attrName = match[1];
                  const attrValue = match[2] || match[3] || "";
                  metaTag.setAttribute(attrName, attrValue);
                }
              }
            });
          }
          if (script.locale === "head") {
            document.head.appendChild(metaTag);
          } else if (script.locale === "body") {
            document.body.appendChild(metaTag);
          }
        }
        if (script.type === "script") {
          const scriptJS = document.createElement("script");
          const attributeMatches = script.code.match(
            /(?<=<[^>]*)([\w-]+)(?:\s*=\s*(?:"([^"]*)"|'([^']*)'))?(?=[^>]*>)/g
          );
          if (attributeMatches) {
            attributeMatches.forEach((attribute) => {
              if (attribute !== "script") {
                const match = attribute.match(
                  /([\w-]+)(?:\s*=\s*(?:"([^"]*)"|'([^']*)'|[^>\s]*))?/
                );
                if (match) {
                  const attrName = match[1];
                  const attrValue = match[2] || match[3] || "";
                  scriptJS.setAttribute(attrName, attrValue);
                }
              }
            });
          }
          const contentMatch = script.code.match(
            /<script(?:[^>]*)>([\s\S]*?)<\/script>/
          );
          if (contentMatch && contentMatch[1]) {
            const scriptContent = contentMatch[1].trim();
            scriptJS.textContent = scriptContent;
          }

          if (script.locale === "head") {
            document.head.appendChild(scriptJS);
          } else if (script.locale === "body") {
            document.body.appendChild(scriptJS);
          }
        }
        if (script.type === "link") {
          const link = document.createElement("link");
          // const attributeMatches = script.code.match(/(?<=<[^>]*)([\w-]+)(?:\s*=\s*(?:"([^"]*)"|'([^']*)'))?(?=[^>]*>)/g);
          const attributeMatches = script.code.match(
            /([\w-]+)(?:\s*=\s*(?:"([^"]*)"|'([^']*)'|(?<=["']).*?(?=["'])))?(?=[^>]*>)/g
          );

          if (attributeMatches) {
            attributeMatches.forEach((attribute) => {
              if (attribute !== "link") {
                const match = attribute.match(
                  /([\w-]+)(?:\s*=\s*(?:"([^"]*)"|'([^']*)'|[^>\s]*))?/
                );
                if (match) {
                  const attrName = match[1];
                  const attrValue = match[2] || match[3] || "";
                  link.setAttribute(attrName, attrValue);
                }
              }
            });
          }
          if (script.locale === "head") {
            document.head.appendChild(link);
          } else if (script.locale === "body") {
            document.body.appendChild(link);
          }
        }
      });
    },
    handleResize() {
      this.$store.dispatch("updateScreenParams");
    },
    createNotifications() {
      // Check lists for notifications
      const deletedNotification = JSON.parse(
        localStorage.getItem("deletedNotification")
      );
      const deletedHeaderNotification = JSON.parse(
        localStorage.getItem("deletedHeaderNotification")
      );
      const allEternalNotifications =
        this.$store.state.alerts.eternalNotifications;
      let headerNotif;
      let newNotification;
      // Create notif
      if (this.userInfo.id !== null) {
        if (this.userInfo.email === null) {
          newNotification = {
            type: "info",
            message: this.$t("alertGoToProfile"),
            click: "goToProfile",
          };
          const isNotificationInDeletedList =
            newNotification &&
            deletedNotification &&
            deletedNotification.click === newNotification.click;
          if (!isNotificationInDeletedList) {
            const isNotificationInList = allEternalNotifications.some(
              (notification) => notification.click === newNotification.click
            );
            if (!isNotificationInList) {
              this.$store.dispatch("alerts/addEternal", newNotification);
            }
          }
        }
        if (this.userInfo.email !== null) {
          headerNotif = {
            type: "info",
            message: this.$t("confirmEmail"),
            clickText: this.$t("confirmEmailResend"),
            click: "resendLetter",
          };
          const isHeaderNotificationInDeletedList =
            headerNotif &&
            deletedHeaderNotification &&
            deletedHeaderNotification.clickText === headerNotif.clickText;
          if (!isHeaderNotificationInDeletedList) {
            this.$store.dispatch("alerts/addHeader", headerNotif);
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
.high-block {
  overflow: hidden;
}
.icon-t {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  font-size: 20px !important;

  @media (max-width: 500px) {
    width: 15px;
    height: 15px;
    font-size: 15px !important;
  }
}

.logo__container-ripple {
}

.top-players {
  padding: 0 !important;
  align-items: unset !important;

  .v-expansion-panel-header__icon {
    display: none !important;
  }
}
.col__order-f {
  .v-skeleton-loader {
    .v-skeleton-loader__image {
      height: 100%;
    }
  }
}
.logo__preloader {
  animation: rippleLogo 1.8s infinite linear;
}

.logo__preloader {
  z-index: 2;
  position: relative;
  display: block;
  &::before {
    content: "";
    position: absolute;
    width: 110px;
    height: 110px;
    top: 20px;
    z-index: 1;
    border-radius: 50%;
    left: 17px;
    animation: ripple 1.8s linear infinite;
  }
  img {
    width: 150px;
    height: 150px;
    position: relative;
    z-index: 5;
  }
}

$color: var(--red);

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba($color, 0.2), 0 0 0 1px rgba($color, 0.2),
      0 0 0 3px rgba($color, 0.4), 0 0 0 5px rgba($color, 0.4);
  }

  50% {
    box-shadow: 0 0 0 0 rgba($color, 0.1), 0 0 0 4px rgba($color, 0.1),
      0 0 0 20px rgba($color, 0), 0 0 0 30px rgba($color, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba($color, 0.1), 0 0 0 4px rgba($color, 0.1),
      0 0 0 20px rgba($color, 0), 0 0 0 30px rgba($color, 0);
  }
}

@keyframes rippleLogo {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.tel {
  .v-input__control {
    .v-input__slot {
      .v-text-field__slot {
        padding-left: 10px !important;

        .v-label {
          background-color: var(--main-flamingo);
          z-index: 5;
        }
      }
    }
  }
}

.card-search-column__input {
  .v-input__control {
    .v-input__slot {
      &::before {
        border: 0px !important;
      }

      &::after {
        border: 0px !important;
      }

      .v-text-field__slot {
        input {
          padding: 0;
          caret-color: white !important;
        }
      }
    }
  }
}

.withdraw_amount {
  .v-input__control {
    .v-input__slot {
      .v-text-field__slot {
        input {
          font-size: 50px;
          padding: 0;
          max-height: 50px;
        }
      }
    }
  }
}

.error--text {
  .v-input__control {
    .v-input__slot {
      fieldset {
        border: 2px solid var(--red);
      }
    }
  }
}

.theme--light.v-select .v-select__selections {
  color: inherit !important;
}

.v-dialog {
  box-shadow: none !important;
  display: flex;
  justify-content: center;
}

.v-chip.v-chip--outlined.v-chip--active:before {
  opacity: 0 !important;
}

.deposit-chips {
  .v-chip-group {
    .v-chip--active {
      color: var(--white) !important;

      .v-chip--no-color:after {
        opacity: 0;
      }
    }
  }
}

.v-messages__message {
  color: var(--red) !important;
}

.dialog-content__input_promo {
  .v-input__control {
    border: 2px solid var(--main-flamingo) !important;
    border-radius: 6px !important;

    .v-input__slot {
      .v-text-field__slot {
        label {
          color: var(--main-flamingo) !important;
          background-color: var(--white);
        }

        input {
          color: var(--main-flamingo) !important;
          caret-color: var(--main-flamingo) !important;
        }
      }
    }
  }
}

.burger-menu:-webkit-scrollbar-thumb {
  height: 10px !important;
  border-radius: 10px !important;
  background-color: var(--white) !important;
}
.burger-menu {
  //@media (min-width:769px){
  //  width: auto!important;
  //  scrollbar-width: none;
  //  padding: .875rem 4.6875rem 3rem 6.75rem;
  //  -webkit-clip-path: polygon(0 0, 100% 0, 18.125rem 100%, 0 100%);
  //  clip-path: polygon(0 0, 100% 0, 18.125rem 100%, 0 100%);
  //
  //}
}
.v-navigation-drawer__content {
  //overflow-y: auto!important;
}

.v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
}

.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  display: none;
}

.v-navigation-drawer__content::-webkit-scrollbar-track {
  display: none;
}
.checkbox-label {
  color: var(--main-flamingo);
  line-height: 1.5;
  font-size: 12px;
  button {
    color: var(--black);
  }
}
.custom-checkbox {
  &.v-input--selection-controls.v-input--is-disabled:not(
      .v-input--indeterminate
    )
    .v-icon {
    color: var(--main-flamingo) !important;
  }
}
.isEmail,
.isUsername,
.isPassword,
.is-reg {
  &__input {
    &.error--text {
      .v-input__control {
        .v-input__slot {
          .v-text-field__slot {
            input {
              color: var(--main-flamingo) !important;
              caret-color: var(--main-flamingo) !important;
            }
          }
        }
      }
    }

    .v-messages__message {
      color: #9ea4ae !important;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }

    .v-input__control {
      .v-input__slot {
        &::before {
          display: none;
        }

        &::after {
          display: none;
        }

        .v-text-field__slot {
          .v-label {
            color: var(--main-flamingo) !important;
            font-weight: 500;
            //font-size: 20px;
            overflow: visible;
          }

          input {
            color: var(--main-flamingo) !important;
            caret-color: var(--main-flamingo) !important;
            font-size: 18px;
            font-weight: 400;
          }
        }

        fieldset {
          border: 1px solid var(--main-flamingo);
        }
      }
    }

    .v-input__icon--append {
      .v-icon {
        color: var(--main-flamingo) !important;
      }
    }

    &_promo {
      input {
        caret-color: var(--main-flamingo) !important;
      }
    }

    &_error {
      .v-input__control {
        .v-input__slot {
          .v-text-field__slot {
            .v-label {
              color: var(--red) !important;
            }

            input {
              color: var(--red) !important;
              caret-color: var(--red) !important;
            }
          }
        }
      }
    }

    &_success {
      .v-input__control {
        .v-input__slot {
          .v-text-field__slot {
            .v-label {
              color: #4bb543 !important;
            }

            input {
              color: #4bb543 !important;
              caret-color: #4bb543 !important;
            }
          }
        }
      }
    }
  }
  &__select {
    &.error--text {
      .v-input__control {
        .v-input__slot {
          .v-text-field__slot {
            input {
              color: var(--main-flamingo) !important;
              caret-color: var(--main-flamingo) !important;
            }
          }
        }
      }
    }

    .v-messages__message {
      color: #9ea4ae !important;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }

    .v-input__control {
      .v-input__slot {
        &::before {
          display: none;
        }

        &::after {
          display: none;
        }

        .v-text-field__slot {
          .v-label {
            color: var(--main-flamingo) !important;
            font-weight: 700;
            //font-size: 20px;
            overflow: visible;
          }

          input {
            color: var(--main-flamingo) !important;
            caret-color: var(--main-flamingo) !important;
            font-size: 18px;
            font-weight: 400;
          }
        }

        fieldset {
          border: 1px solid var(--main-flamingo);
        }
      }
      .v-select__slot {
        .v-label {
          color: var(--main-flamingo) !important;
          font-weight: 500;
          //font-size: 20px;
          overflow: visible;
        }

        .v-select__selections {
          color: var(--main-flamingo) !important;
          font-weight: 400;
        }
      }
    }

    .v-input__icon--append {
      .v-icon {
        color: var(--main-flamingo) !important;
      }
    }

    &_promo {
      input {
        caret-color: var(--main-flamingo) !important;
      }
    }

    &_error {
      .v-input__control {
        .v-input__slot {
          .v-text-field__slot {
            .v-label {
              color: var(--red) !important;
            }

            input {
              color: var(--red) !important;
              caret-color: var(--red) !important;
            }
          }
        }
      }
    }

    &_success {
      .v-input__control {
        .v-input__slot {
          .v-text-field__slot {
            .v-label {
              color: #4bb543 !important;
            }

            input {
              color: #4bb543 !important;
              caret-color: #4bb543 !important;
            }
          }
        }
      }
    }
  }
}

.eternal-notif {
  .v-alert__wrapper {
    .v-alert__icon {
      margin-right: 10px;
    }
  }

  .v-alert__content {
    display: flex;

    .eternal-notif__message {
      text-align: left;
    }

    .eternal-notif__close {
      cursor: pointer;
      align-self: flex-start;
      font-size: 26px;
    }
  }
}

.dialog-email-content__form {
  .v-alert {
    .v-alert__wrapper {
      .v-alert__content {
        text-align: left;
      }
    }
  }
}

.all-inputs {
  .v-input__icon--append {
    .v-icon {
      color: var(--white) !important;
    }
  }
}

.selectMain {
  &.v-input--is-disabled {
    opacity: 0.5;
  }
  &._center {
    .v-list {
      .v-list-item {
        justify-content: center !important;
      }
    }
  }
  .v-list {
    padding: 0;

    .v-list-item {
      &::before {
        border: 1px solid hsla(0, 0%, 100%, 0.12);
        background-color: transparent !important;
      }
    }
  }

  .v-input__control {
    .v-input__slot {
      align-items: center !important;
      .v-select__slot {
        .v-label {
          color: var(--white) !important;
        }
        .v-select__selections {
          .v-select__selection {
            color: var(--white) !important;
          }
        }
        .v-input__append-inner {
          .v-input__icon {
            .v-icon {
              color: var(--white) !important;
            }
          }
        }
      }
    }
  }
}

.selectMenuSelfExclusion {
  .v-list {
    padding: 0;

    .v-list-item {
      text-align: center;

      &::before {
        border: 1px solid hsla(0, 0%, 100%, 0.12);
        background-color: transparent !important;
      }
    }
  }

  &_select {
    .v-input__control {
      .v-select__slot {
        .v-select__selections {
          .v-select__selection--comma {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

.menuTranslate-t {
  transform: translate(12px, 0px) !important;

  .v-list {
  }

  .v-list {
    border: 1px solid var(--white) !important;
    border-top: 0px !important;
    border-radius: 0px !important;
    padding: 0 !important;
  }

  .theme--light.v-list-item--active:first-child:before {
    opacity: 0 !important;
  }

  .v-list-item--link:before {
    background-color: var(--white) !important;
  }

  .v-list-item--link:not(:first-child):before {
    background-color: var(--white) !important;
    height: 1px !important;
    //top: -2px;
  }
}

.select__translation-t {
  .v-input__slot {
    fieldset {
      border: 0 !important;
    }
  }
}

//.menuable__content__active{
//  text-transform: uppercase;
//
//}

.router_view {
  flex: 1 1 100% !important;
}

.chip-items-reality {
  .v-slide-group__wrapper {
    .v-slide-group__content {
      justify-content: center;
    }
  }
}

.v-toolbar__content {
  //max-width: 1210px;
  //margin: 0px auto;
  padding: 0 !important;
}

.header_wrapper {
  .app__bar {
    @media (max-width: 992px) {
      height: 50px !important;
    }

    @media (max-height: 500px) and (orientation: landscape) and (max-width: 992px) {
      height: 50px !important;
    }
  }

  .v-toolbar__content {
    @media (max-width: 992px) {
      height: 50px !important;
    }

    @media (max-height: 500px) and (orientation: landscape) and (max-width: 992px) {
      height: 50px !important;
    }
  }
}

.v-list-item--link {
  &:not(:first-child):before {
    background-color: var(--red) !important;
    width: 100% !important;
    height: 2px !important;
    opacity: 1 !important;
  }
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
  //min-height: 20px!important;
}

.custom-switch {
  .theme--light.v-input--switch.v-input--is-disabled:not(.v-input--is-dirty)
    .v-input--switch__track {
    color: var(--white) !important;
  }

  .v-input__control {
    .v-input__slot {
      .v-input--selection-controls__input {
        margin-right: 0px;
        .v-input--selection-controls__ripple {
          display: none;
          &::before {
            opacity: 0;
            display: none !important;
          }
        }

        .v-input--switch__track {
          opacity: 1;
          background-color: var(--gray);

          &.green--text.text--accent-4 {
            opacity: 0.3;
            background-color: currentColor;
          }
        }
      }
    }
  }

  &_deposit {
    .v-input__control {
      .v-input__slot {
        .v-input--selection-controls__input {
          .v-input--selection-controls__ripple {
            &::before {
              opacity: 0;
            }
          }

          .v-input--switch__thumb {
            background-color: var(--white) !important;
          }

          .v-input--switch__track {
            opacity: 1 !important;

            &.green--text.text--accent-4 {
              opacity: 0.3;
              background-color: var(--green-success) !important;
            }
          }
        }
      }
    }
  }
}

.reg__switches {
  .v-input__control {
    .v-input__slot {
      justify-content: center;

      .v-input--selection-controls__input {
        margin: 0;
        margin-left: 10px;
      }
    }
  }
}

input {
  text-overflow: ellipsis;
}

.chip-items {
  .v-slide-group__prev {
    &.v-slide-group__prev--disabled {
      //display: none;
    }
    .v-icon {
      .v-icon__svg {
        fill: var(--main-flamingo) !important;
      }
    }
  }

  .v-slide-group__next {
    .v-icon {
      .v-icon__svg {
        fill: var(--main-flamingo) !important;
      }
    }
  }
}

.dialog-email-content {
  &__chip-items {
    .v-slide-group__wrapper {
      display: block !important;

      .v-slide-group__content {
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        display: grid !important;
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  &__chip-item {
    border-radius: 6px !important;
    justify-content: center;
    align-items: center;
    min-height: 44px;
    border: none !important;

    span {
      color: #161421 !important;
    }
  }
}

.deposit-chip {
  .v-slide-group__wrapper {
    display: block !important;

    .v-slide-group__content {
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      display: grid !important;
      grid-template-columns: repeat(4, 1fr);
      padding: 0;
    }
  }

  &__item {
    border-radius: 6px !important;
    justify-content: center;
    align-items: center;
    margin: 0 !important;

    span {
      color: #161421 !important;
    }
  }
}

.v-input__append-inner {
  align-self: center !important;
  margin: 0 !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@import "./assets/scss/main.scss";

body {
  // background: url('./assets/images/mafia_site_bg.png'); --> main background
  background-color: var(--main-flamingo);
  min-height: 100vh;
  width: 100%;
  //min-height: -webkit-fill-available;
}
.is-desktop {
  height: 100vh;
}
html {
  height: -webkit-fill-available;
}
* {
  caret-color: var(--white) !important;
}

#app {
  font-family: "Montserrat", sans-serif;
  //font-family: "Inter",Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-weight: 400;
  color: var(--white);
  background-color: var(--white);
  //width: 100vw;
  //height: max-content;
  &._blocked {
    height: 100%;
  }
}

//========================================================================================================================================================
// pc-search
.search-pc__input {
  .v-input__control {
    .v-input__slot {
      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }
}

//========================================================================================================================================================
.title__link-v {
  @media (min-width: 992.98px) {
    display: none !important;
  }
}

.all-buttons-t {
  cursor: pointer;
  font-weight: 700 !important;
  min-height: 36px !important;
  background-color: var(--red) !important;
  color: var(--white) !important;
  border-radius: 6px !important;
  font-size: 15px !important;
  line-height: 17px !important;
  transition: all 0.3s !important;

  @media (any-hover: hover) {
    &:hover {
      box-shadow: 0 0 5px var(--red) !important;
    }
  }

  @media (max-width: 1140px) {
    font-size: 14px !important;
  }

  @media (max-width: 768px) {
    font-size: 12px !important;
    padding: 0 5px !important;
  }

  @media (max-width: 500px) {
    padding: 0 3px !important;
  }

  @media (max-width: 375.98px) {
    font-size: 10px !important;
  }

  @media (max-height: 500px) and (orientation: landscape) and (max-width: 992px) {
    padding: 0 3px !important;
  }

  &_enter {
    min-width: auto !important;

    @media (max-width: 768px) {
      margin-left: 0 !important;
    }
  }

  &_play {
    min-width: auto !important;

    @media (min-width: 500.98px) {
      max-width: 118px !important;
      width: 118px !important;
    }

    @media (max-width: 500.98px) {
      margin-bottom: 3px;
      height: auto !important;
      max-width: 75px !important;
      width: 75px !important;
      font-size: 10px !important;
    }
  }
  &_mini {
    min-height: 30px !important;
  }
}

.photo-text-size {
  max-width: 155px;
  min-width: 155px;
  margin-right: 10px;

  @media (min-width: 375px) {
    max-width: 170px;
    min-width: 170px;
    margin-right: 5px;
  }

  @media (min-width: 425px) {
    max-width: 190px;
    min-width: 190px;
  }

  @media (min-width: 500px) {
    max-width: 230px;
    min-width: 230px;
  }

  @media (min-width: 768px) {
    max-width: 180px;
    min-width: 180px;
  }

  @media (min-width: 960px) {
    max-width: 190px;
    min-width: 190px;
  }

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.all-buttons-fw {
  width: 100%;
  color: var(--white) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  background-color: var(--red) !important;
  min-height: 40px !important;

  @media (any-hover: hover) {
    &:hover {
      box-shadow: 0 0 5px var(--red);
    }
  }

  &_green {
    background-color: var(--green-success) !important;

    @media (any-hover: hover) {
      &:hover {
        box-shadow: 0 0 5px var(--green-success) !important;
      }
    }
  }

  &_verif {
    @media (max-width: 650px) {
      font-size: 14px !important;
    }

    @media (any-hover: hover) {
      &:hover {
        font-weight: 700 !important;
      }
    }
  }
}

.main-input {
  color: var(--main-flamingo) !important;
  border-color: var(--main-flamingo) !important;

  .v-input__slot {
    .v-input__prepend-inner {
      .v-icon {
        .error--text & {
          color: var(--red) !important;
        }
      }
    }

    &::before {
      border-color: var(--main-flamingo) !important;
    }

    &::after {
      border-color: var(--main-flamingo) !important;
    }

    fieldset {
      color: var(--main-flamingo) !important;
      border-color: var(--main-flamingo) !important;

      .error--text & {
        color: var(--red) !important;
      }
    }

    .v-text-field__slot {
      .v-label {
        color: var(--main-flamingo) !important;
        font-weight: 500;
      }

      input {
        color: var(--main-flamingo) !important;
        caret-color: var(--main-flamingo) !important;
      }
    }
  }

  .v-text-field__details {
  }
}
.links-label {
  .link-label {
    //border: 1px solid var(--red);
    border-radius: 6px !important;
    background-color: var(--dark-flamingo);
    transition: all 0.15s;
    .v-chip__content {
      color: var(--black);
    }
    @media (any-hover: hover) {
      &:hover {
        background-color: var(--dark-flamingo) !important;
        .v-chip__content {
          color: var(--white);
        }
      }
    }
    &._active {
      color: var(--dark-flamingo);
      .v-chip__content {
        color: var(--white);
      }
    }
  }
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin: 0 6px !important;
}
.input-sign {
  transition: all 0.3s !important;
  margin-bottom: 0px !important;
}
.small-text {
  color: var(--gray-text);
  font-size: 14px;
  font-weight: 400;
}
.main-input {
  &.error--text {
    .v-input__slot {
      background-color: #ffe0e9;
    }

    .v-input__slot {
      .v-input__prepend-inner {
        .v-icon {
          color: var(--red) !important;
        }
      }

      fieldset {
        color: var(--red) !important;
        border-color: var(--red) !important;
      }

      .v-text-field__slot {
        .v-label {
          color: var(--red) !important;
        }

        input {
        }
      }
    }

    .v-text-field__details {
    }
  }
}
.input-auto {
  transition: margin-bottom 0.3s;
  &.v-input {
    margin-bottom: 9px !important;
  }
  &.error--text {
    margin-bottom: 0px !important;
  }
  &.v-input--has-state {
    margin-bottom: 9px !important;
  }
}
.input-colors {
  &.error--text {
    .v-input__control {
    }

    .v-input__slot {
      .v-input__prepend-inner {
        .v-icon {
          color: var(--red) !important;
        }
      }

      fieldset {
        color: var(--red) !important;
        border-color: var(--red) !important;
      }

      .v-text-field__slot {
        .v-label {
          color: var(--red) !important;
        }

        input {
        }
      }
    }

    .v-text-field__details {
    }
  }

  &.success--text {
    .v-input__slot {
      .v-input__prepend-inner {
        .v-icon {
          color: #5eeb64 !important;
        }
      }

      fieldset {
        color: #5eeb64 !important;
        border-color: #5eeb64 !important;
      }

      .v-text-field__slot {
        .v-label {
          color: #5eeb64 !important;
        }

        input {
          color: #5eeb64 !important;
        }
      }
    }

    .v-text-field__details {
    }
  }
}

.all-shadow-hover {
  transition: box-shadow 0.15s ease;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: transparent !important;
}

.passportDialog {
  border-radius: 16px !important;
}

.whiteDialog {
  background-color: var(--white);
}

.black-input {
  .v-input__control {
    .v-input__slot {
      &::before {
        border-color: var(--white-design) !important;
      }

      &::after {
        transform: scaleX(0) !important;
        border-color: var(--white-design) !important;
      }

      .v-text-field__slot {
        input {
          color: var(--dark-flamingo) !important;
          caret-color: var(--dark-flamingo) !important;
        }
      }
    }
  }
}

.notification-h {
  border-radius: 0px !important;

  .v-alert__wrapper {
    justify-content: center;
    align-items: center;
  }

  .v-alert__icon {
    align-self: center;
  }

  .v-alert__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: initial;
  }
}

.swiper-container {
  overflow: visible !important;
}

.hover-link {
  color: inherit !important;
  text-decoration: none;
  &_green {
    color: var(--green-success) !important;
  }

  @media (any-hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
}

.slider-fade {
  overflow: hidden !important;
}

.tmb-style {
  &_fz24 {
    font-size: 24px;
  }

  &_fz16 {
    font-size: 16px;
  }

  &_fz14 {
    font-size: 14px;
  }

  &_fz12 {
    font-size: 12px;
  }

  &_white {
    color: var(--white);
  }
  &_black {
    color: var(--black);
  }

  &_bg-light {
    background-color: var(--light-flamingo);
  }

  &_bg-dark {
    background-color: var(--dark-flamingo);
  }

  &_bg-main {
    background-color: var(--main-flamingo);
  }

  &_td-none {
    text-decoration: none;
  }

  &_title-header {
    display: grid;
    grid-template-columns: 36px 1fr 36px;
    align-items: center;
    width: 100%;
    padding: 10px 16px !important;
    border-radius: 0px !important;
    position: relative;

    &_logo {
      grid-template-columns: 65px 1fr 36px;
    }

    &__content {
      grid-column: 2/3;
      max-width: 100%;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;

      span {
        color: var(--white);
        font-size: 15px;
        white-space: nowrap;
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__close {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.blur-overlay {
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.322);
}

.side-gradient-right,
.side-gradient-left {
  position: absolute;
  height: 100%;
  top: 0;
  pointer-events: none;
  z-index: 2;

  @media (max-width: 1140px) {
    display: none;
  }
}

.side-gradient-right {
  background: linear-gradient(
    90deg,
    rgba(43, 39, 64, 0) 40%,
    var(--main-flamingo)
  );
  right: 0;
  width: 50px;

  &_dark {
    width: 100px;
    background: linear-gradient(
      90deg,
      rgba(43, 39, 64, 0) 40%,
      var(--dark-flamingo)
    );
  }
}

.side-gradient-left {
  background: linear-gradient(90deg, var(--main-flamingo), rgba(43, 39, 64, 0));
  left: 0;
  width: 6px;

  &_dark {
    width: 6px;
    background: linear-gradient(
      90deg,
      var(--dark-flamingo),
      rgba(43, 39, 64, 0)
    );
  }
}

.v-progress-linear .v-progress-linear__indeterminate--active {
  .long,
  .short {
    border-color: var(--red) !important;
    background-color: var(--red) !important;
  }
}
.custom-alert {
  .v-alert__content {
    text-align: left;
  }
}
</style>
