<template>
  <div>
    <v-col class="d-flex align-center justify-space-between">
      <v-card-title
        class="pa-0 text-uppercase title-card"
        style="color: var(--white)"
      >
        {{ $i18n.t("bonusesTitle") }}
      </v-card-title>
      <template v-if="$route.path !== '/promotions-welcome'">
        <v-btn @click="openPromocodeDialog" class="all-buttons-t">{{
          $t("promoTitle")
        }}</v-btn>
        <PromoCode></PromoCode>
      </template>
    </v-col>
    <!-- slider bonuses -->
    <v-row>
      <v-col>
        <div class="d-flex flex-column">
          <div class="bonus-wrapper">
            <div
              class="bonus-card-wrapper"
              v-for="(cardDeposit, index) in depositBonuses"
              :key="'depositBonus_' + index"
            >
              <deposit-bonus
                v-if="user.id !== null"
                :index="index"
                :bonusId="cardDeposit.id"
                :cardDeposit="cardDeposit"
              >
              </deposit-bonus>
            </div>
            <div
              class="bonus-card-wrapper"
              v-for="(cardSpins, index) in spinsBonuses"
              :key="'spinsBonus_' + index"
            >
              <spins-bonus
                :index="index"
                :bonusId="cardSpins.id"
                :cardSpins="cardSpins"
              ></spins-bonus>
            </div>
            <div class="bonus-card-wrapper" v-if="!user.id">
              <bonus-no-login> </bonus-no-login>
            </div>
            <div
              class="bonus-card-wrapper"
              v-for="tournament in tournaments"
              :key="tournament.id"
            >
              <bonus-tournament
                :tournament="tournament"
                :state="() => $store.state.tournaments.availableTournaments"
                @show-tournament-dialog="
                  $emit('show-tournament-dialog', tournament)
                "
              >
              </bonus-tournament>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DepositBonus from "@/components/Profile/Promo/BonusCards/DepositBonus/DepositBonus.vue";
import SpinsBonus from "@/components/Profile/Promo/BonusCards/SpinsBonus/SpinsBonus.vue";
import BonusTournament from "@/components/Profile/Promo/BonusCards/BonusTournament/BonusTournament.vue";
import BonusFutureOne from "@/components/Profile/Promo/BonusCards/BonusFutureOne/BonusFutureOne.vue";
import PromoCode from "@/components/Profile/Promo/PromoCode/Promocode.vue";
import BonusNoLogin from "@/components/Profile/Promo/BonusCards/BonusNoLogin/BonusNoLogin.vue";
import { mapGetters } from "vuex";
export default {
  name: "WelcomePC",
  components: {
    DepositBonus,
    BonusTournament,
    SpinsBonus,
    PromoCode,
    //BonusFutureOne,
    BonusNoLogin,
  },
  data() {
    return {};
  },
  props: {
    spinsBonuses: Array,
    depositBonuses: Array,
    tournaments: Array,
  },
  computed: {
    ...mapGetters({
      user: "getUserInfo",
    }),
  },
  methods: {
    openPromocodeDialog() {
      this.$store.commit("promoCode/setDialogPromo", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.title-card {
  font-weight: bold;
  font-size: 18px !important;
}
</style>
