<template>
  <div class="block">
    <div class="block__header header">
      <div class="header__title font-weight-bold">
        <!-- {{ getCategoryTitle(category) }} -->
        {{ $t(block_name) }}
        <!-- {{ block_name }} -->
      </div>
      <v-btn class="all-buttons-t header__show-more" @click="viewAll">{{
        $i18n.t("ViewAll")
      }}</v-btn>
    </div>

    <template>
      <swiper
        :options="swiperOptions"
        ref="swiper"
        slides-per-view="auto"
        :spaceBetween="5"
        class="block__item item"
      >
        <div class="side-gradient-left"></div>
        <template v-if="!loadingFlag">
          <swiper-slide
            v-for="(item, index) in games"
            :key="index"
            class="item__item"
          >
            <v-lazy v-model="isActive"></v-lazy>
            <div class="item__image-container">
              <div class="badges" v-if="user.id !== null">
                <div
                  class="badge badge__favourite"
                  :class="{ 'is-favourite': item.is_favourite }"
                  @click.stop="addGameToFavorite(item)"
                >
                  <v-icon>mdi-heart</v-icon>
                </div>
              </div>
              <v-lazy v-model="isActive" v-if="item.logo">
                <v-lazy-image
                  @load="isActive = true"
                  :src="item.logo"
                  class="item__image"
                />
              </v-lazy>
              <div class="item__overlay">
                <v-btn
                  class="item__play all-buttons-t all-buttons-fw_green"
                  @click="playGame(item)"
                >
                  <img
                    src="@/assets/images/white-heart.svg"
                    width="15px"
                    height="15px"
                    class="white-heart mr-1"
                  />
                  {{ $i18n.t("play") }}
                </v-btn>
                <v-btn
                  class="item__demo all-buttons-t"
                  @click="playGame(item, true)"
                >
                  {{ $i18n.t("demo") }}
                </v-btn>
              </div>
            </div>
            <div class="item-mobile">
              <div class="item-mobile__image-container">
                <v-lazy v-model="isActive">
                  <v-lazy-image
                    @load="isActive = true"
                    v-if="item.logo"
                    :src="item.logo"
                    class="item-mobile__image"
                  />
                </v-lazy>
              </div>
              <div class="item-mobile__overlay">
                <span class="item-mobile__name" v-if="item.game_name">
                  {{ item.game_name }}
                </span>
                <v-btn
                  class="item-mobile__play all-buttons-t all-buttons-fw_green"
                  @click="playGame(item)"
                >
                  <img
                    src="@/assets/images/white-heart.svg"
                    width="15px"
                    height="15px"
                    class="white-heart mr-1"
                  />

                  {{ $i18n.t("play") }}
                </v-btn>
                <v-btn
                  class="item-mobile__demo all-buttons-t"
                  @click="playGame(item, true)"
                >
                  {{ $i18n.t("demo") }}
                </v-btn>
              </div>
            </div>
          </swiper-slide>
        </template>
        <swiper-slide
          v-for="(item, index) in 10"
          :key="index"
          class="item__item"
          v-else
        >
          <v-skeleton-loader class="item__image" type="card" />
        </swiper-slide>
        <div class="side-gradient-right"></div>
      </swiper>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SwiperCore, Swiper, SwiperSlide, Navigation } from "swiper-vue2";

import "swiper/swiper-bundle.css";
export default {
  name: "GamesSection",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    category: String,
    // isType: String,
    loadingFlag: Boolean,
    block_name: String,
    block_id: Number,
    games: Array,
  },
  data() {
    return {
      isActive: false,
      items: [],
      favouriteGames: [],
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      screenParams: "screenParams",
      gameBlocks: "getGameBlocks",
      user: "getUserInfo",
    }),
  },
  async mounted() {
    // console.log('category', this.category);
    // console.log('games', this.games);
    //   const params = {
    //     size: 35,
    //     sub: null,
    //     game_type: null,
    //     page: 1,
    //   }
    //   if (this.category !== 'All') {
    //     if (this.isType === 'sub') {
    //       params.sub = this.category;
    //     } else if(this.isType === 'gameType'){
    //     params.game_type = this.category;
    //   }
    // }
    //     this.items = await this.$store.dispatch("gameModule/getFilteredGames", params);
  },
  methods: {
    viewAll() {
      //let blockFilter;
      //if(this.block_name === '⭐️ ALL GAMES'){
      //  blockFilter = 'All';
      //} else if(this.block_name === '🎲 ROULETTE'){
      //  blockFilter = 'roulette';
      //} else if(this.block_name === '💎 TOP'){
      //  blockFilter = 'top';
      //} else if(this.block_name === '🎰 SLOTS'){
      //  blockFilter = 'slots';
      //} else if(this.block_name === '😍 NEW GAMES'){
      //  blockFilter = 'new';
      //}
      // localStorage.setItem('blockId', this.block_id);
      this.$router.push({
        name: "GridBlock",
        params: {
          type: `game-block`,
          info: `${this.block_id}`,
        },
      });
    },

    async playGame(game, demoFlag = false) {
      if (this.user.id === null) {
        try {
          await this.$router.push("/reg");
        } catch (error) {
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        }
      } else {
        if (this.user.balance == 0 && !demoFlag) {
          try {
            await this.$router.push("/deposit");
          } catch (error) {
            if (error.name !== "NavigationDuplicated") {
              throw error;
            }
          }
        } else {
          let params = { id: game.string_id };
          if (demoFlag) {
            params.demo = "demo";
          }
          await this.$router.push({ name: "Game", params });
        }
      }
    },

    async addGameToFavorite(game) {
      game.is_favourite = !game.is_favourite;
      await this.$store.dispatch("favoriteGames/awaitToggleFavoriteGame", {
        id: game.id,
        is: game.is_favourite,
      });
      this.$store.commit("favoriteGames/APPEND_CURRENT_FAVORITE_GAME", {
        game: game,
        is: game.is_favourite,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  overflow: hidden !important;
  @media (max-width: 1140px) {
    overflow: visible !important;
  }
}
.swiper-wrapper {
  display: flex;
  flex-direction: column;
}
.block {
  &__header {
  }
}
.header {
  display: flex;
  align-items: center;
  &__title {
    text-align: left;
    flex: 1 1 100%;
    text-transform: uppercase;
    color: var(--white);
    font-size: 24px;
    margin-right: 5px;
    @media (max-width: 992px) {
      font-size: 18px;
    }
  }

  &__show-more {
  }
}
@mixin imageGame {
  max-width: 100%;
  max-height: 100%;
  border-radius: 16px;
  overflow: hidden;
  transition: opacity 0.3s;
}
@mixin logoLoading {
  position: relative;
  &::before {
    content: "";
    display: block;
    border-radius: 16px;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: var(--red);
    animation: disappear 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    opacity: 1;
    visibility: visible;
  }
  &::after {
    content: "";
    position: absolute;
    border-radius: 16px;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background: url("../../assets/images/main_logo.png") center / 90px no-repeat;
    animation: disappear2 1s;
    opacity: 0.6;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    @media (max-width: 992px) {
      background-size: 60px;
    }
  }
}
.badges {
  position: absolute;
  right: 15px;
  top: 15px;
  display: flex;
  align-items: center;
  z-index: 4;
}
.badge {
  background-color: hsla(0, 0%, 100%, 0.3);
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  position: relative;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, transform 0.3s;
  &:not(:last-child) {
    margin-right: 5px;
  }
  &__favourite {
    i {
      color: var(--white);
      font-size: 18px;
    }
    @media (any-hover: hover) {
      &:hover {
        background-color: var(--red);
      }
    }
    &.is-favourite {
      background-color: var(--red);
      transform: scale(0.9);
    }
  }
}

.item {
  position: relative;
  &__item {
    padding: 10px 0;
    display: flex;
    width: 170px;
    flex: 0 0 170px;
    height: 170px;
    @media (max-width: 992px) {
      width: 150px;
      flex: 0 0 150px;
      height: 150px;
    }
    @media (max-width: 768px) {
      width: 120px;
      flex: 0 0 120px;
      height: 120px;
    }
    //&._live{
    //height: 230px;
    //@media (max-width:768px){
    //  height: 175px;
    //}
    //}
  }

  &__image-container {
    @media (any-hover: none) {
      display: none;
    }
    @include logoLoading;
    @media (any-hover: hover) {
      &:hover {
        .item__overlay {
          opacity: 1;
          visibility: visible;
          &::before {
            left: 168%;
          }
        }
        .item__image {
          opacity: 0.5;
        }
      }
    }
  }

  &__image {
    @include imageGame;
    width: 170px;
    flex: 0 0 170px;
    height: 170px;
    @media (max-width: 992px) {
      width: 150px;
      flex: 0 0 150px;
      height: 150px;
    }
    @media (max-width: 768px) {
      width: 120px;
      flex: 0 0 120px;
      height: 120px;
    }
    //._live &{
    //  height: 230px;
    //  @media (max-width:992px){
    //    height: 175px;
    //  }
    //
    //}
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__span {
      text-transform: uppercase;
      font-size: 16px;
      padding: 0 8px;
      line-height: 1.3;
      @media (max-width: 500px) {
        font-size: 10px;
      }
    }
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    border-radius: 16px;
    overflow: hidden;
    text-decoration: none;
    backdrop-filter: blur(2px);
    transition: all 0.3s ease-out;
    @media (min-width: 849.98px) {
      &::before {
        content: "";
        background-color: rgba(white, 0.4);
        width: 60%;
        height: 100%;
        top: 0px;
        left: -125%;
        transform: skew(45deg);
        z-index: 3;
        position: absolute;
        transition: left 0.5s ease-out, background-color 0.5s;
      }
    }
  }
  &__play {
  }
  &__demo {
    margin-top: 10px;
  }
  &__name {
    height: 25px;
    max-width: calc(100% - 20px);
    overflow: hidden;
    color: white;
    position: relative;
    top: -5px;
    animation: disName 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
    visibility: hidden;
    @keyframes disName {
      0% {
        opacity: 0;
        visibility: hidden;
      }

      100% {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.item-mobile {
  @media (any-hover: hover) {
    display: none;
  }
  @include imageGame;
  perspective: 1000px;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  &:hover {
    transform: rotateY(180deg);
    .item-mobile__overlay {
      opacity: 1;
      visibility: visible;
    }
  }
  &__image-container {
    @include logoLoading;
    @include imageGame;
  }

  &__image {
    @include imageGame;
  }

  &__overlay {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transform: rotateY(180deg);
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.7);
    transition: opacity 0.5s, visibility 0.5s;
    transition-delay: 0.1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__name {
    font-size: 14px;
    color: var(--white);
    line-height: 1;
    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  &__play {
    margin-top: 5px;
    @media (max-width: 600px) {
      margin-top: 3px;
      height: 30px !important;
      min-height: 30px !important;
    }
  }

  &__demo {
    margin-top: 10px;
    @media (max-width: 600px) {
      margin-top: 5px;
      height: 30px !important;
      min-height: 30px !important;
    }
  }
}
//========================================================================================================================================================
// Kf
@keyframes disappear {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes disappear2 {
  0% {
    opacity: 0.6;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
