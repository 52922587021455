import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Game from "../views/Game/Game";
import Sport from "../views/Sport";
import ProfileMain from "../components/Profile/ProfileMain";
import Profile from "../views/Profile";
import ProfileInfo from "../components/Profile/ProfileInfo";
import PlayerInfo from "../components/Profile/PlayerInfo/PlayerInfo";
import UserSessions from "../components/Profile/PlayerInfo/UserSessions";
import Promo from "../components/Profile/Promo";
import Levels from "../components/Profile/Promo/Levels";
import Bonuses from "../components/Profile/Promo/Bonuses";
import Welcome from "../components/Profile/Promo/PromotionsPages/Welcome";
import CashBox from "../components/Profile/CashBox";
import Balance from "../components/Profile/Cashbox/Balance";
import BetHistory from "../components/Profile/Cashbox/BetHistory";
import Tournament from "@/views/Tournament/Tournament.vue";
import PaymentsHistory from "../components/Profile/Cashbox/PaymentsHistory";
import WithDraw from "../components/Profile/Cashbox/WithDraw";
import DepositMainPage from "../views/DepositPages/DepositMainPage";
import CashoutPage from "../views/CashoutPage";
import store from "../store/store";
import Verification from "../components/Profile/PlayerInfo/Verification";
import PlayerSecurity from "../components/Profile/PlayerInfo/PlayerSecurity";
import Rules from "../views/Rules";
import Registration from "../components/Authorization/Registration/Registration.vue";
import SignIn from "../views/SignIn";
import ResetPass from "../views/ResetPass";
import ResponsibleGaming from "@/components/Profile/ResponsibleGaming.vue";
import SelfExclusion from "@/components/Profile/ResponsibleGaming/SelfExclusion.vue";
import RealityCheck from "@/components/Profile/ResponsibleGaming/RealityCheck.vue";
import Limits from "@/components/Profile/ResponsibleGaming/Limits.vue";

import Blocked from "../../blocked-region-page/blocked.vue";

import GridBlock from "@/components/GridBlock/GridBlock.vue";
import Login from "../components/Authorization/Login/Login.vue";
//========================================================================================================================================================
// async function redirectIfNotAuth(to, from, next) {
//   console.log(getUserState());
//   const user = await getUserState();
//   if (user === null) {
//     next("/");
//   } else {
//     next();
//   }
// }

// function getUserState() {
//   return new Promise((resolve, reject) => {
//     if (store.state.userInfo.id === null) {
//       const unwatch = store.watch(
//         () => store.state.userInfo.id,
//         (value) => {
//           unwatch();
//           resolve(value);
//         }
//       );
//       // Если изначально store.state.userTest === undefined, вернуть null
//       resolve(null);
//     } else {
//       resolve(store.state.userInfo.id);
//     }
//   });
// }
//========================================================================================================================================================

//import {
//  redirectIfAuth,
//  redirectIfNotAuth,
//  redirectIfHaveVerif,
//} from "./check_user_info";
const redirectIfNotAuth = (to, from, next) => {
  const user = store.getters.getUserInfo.id;
  if (user === null) {
    next("/");
  } else {
    next();
  }
};
const redirectIfNotBlocked = (to, from, next) => {
  const country = store.state.isBlockedProduct;
  const product = store.state.isBlockedProduct;
  if (!product || !country) {
    next("/");
  } else {
    next();
  }
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/reg",
    name: "Registration",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Registration,
    // beforeEnter: redirectIfAuth,
  },
  {
    path: "/promotions-welcome",
    component: Welcome,
    // beforeEnter: (to, from, next) => {
    //   if (store.getters.getUserInfo.id === null) {
    //     next("/profile/promo-my/bonus");
    //   } else {
    //     next();
    //   }
    // },
  },

  {
    path: "/game/:id/:demo?",
    // path: "/game",
    name: "Game",
    component: Game,
    beforeEnter: redirectIfNotAuth,
  },
  {
    path: "/sport",
    name: "Sport",
    component: Sport,
    beforeEnter: redirectIfNotAuth,
  },

  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    beforeEnter: redirectIfNotAuth,
    children: [
      {
        path: "info",
        component: ProfileInfo,
        redirect: "info/data",
        children: [
          {
            path: "data",
            component: PlayerInfo,
            name: "ProfileInfo",
            props: true,
          },
          {
            path: "notifications",
            component: function () {
              return import(
                "@/components/Profile/PlayerInfo/PlayerNotifications/Notifications.vue"
              );
            },
            name: "Notifications",
            props: true,
          },
          {
            path: "verification",
            component: Verification,
            // beforeEnter: redirectIfHaveVerif,
          },
          {
            path: "main",
            component: ProfileMain,
          },
          {
            path: "security",
            component: PlayerSecurity,
          },
        ],
      },
      {
        path: "promotions",
        component: Welcome,
      },
      {
        path: "promo-my",
        component: Promo,
        redirect: "promo-my/bonus",
        children: [
          {
            path: "bonus",
            component: Bonuses,
          },
          {
            path: "level",
            component: Levels,
          },
        ],
      },
      {
        path: "responsible-gambling",
        component: ResponsibleGaming,
        redirect: "responsible-gambling/self-exclusion",
        children: [
          {
            path: "self-exclusion",
            component: SelfExclusion,
          },
          {
            path: "reality-check",
            component: RealityCheck,
          },
          {
            path: "limits",
            component: Limits,
          },
        ],
      },
      {
        path: "cashbox",
        component: CashBox,
        redirect: "cashbox/payhistory",
        children: [
          {
            path: "balance",
            component: Balance,
          },
          /*{
                      path:'bethistory',
                      component: BetHistory
                    },*/
          {
            path: "payhistory",
            component: PaymentsHistory,
          },
          //{
          //  path: "withdraw",
          //  component: WithDraw,
          //},
        ],
      },
    ],
  },
  {
    path: "/enter",
    name: "SignIn",
    component: Login,
    // beforeEnter: redirectIfAuth,
  },
  {
    path: "/deposit",
    name: "DepositMainPage",
    component: DepositMainPage,
    beforeEnter: redirectIfNotAuth,
  },
  {
    path: "/cashout",
    name: "CashoutPage",
    component: CashoutPage,
    beforeEnter: redirectIfNotAuth,
  },
  {
    path: "/rules",
    name: "Rules",
    component: Rules,
  },
  {
    path: "/reset",
    name: "ResetPass",
    component: ResetPass,
  },
  {
    //
    path: "/section/:type/:info",
    name: "GridBlock",
    component: GridBlock,
    props: true,
  },
  {
    path: "/tournament/:id",
    component: Tournament,
    children: [
      {
        path: "",
        name: "TournamentView",
        component: () => import("@/views/Tournament/TournamentView.vue"),
      },
      {
        path: "section/:type/:info",
        name: "TournamentProvider",
        component: GridBlock,
        props: true,
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Blocked,
    beforeEnter: redirectIfNotBlocked,
  },
  {
    path: "/blocked",
    name: "Blocked",
    component: Blocked,
    beforeEnter: redirectIfNotBlocked,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { selector: to.hash };
    } else {
      return {
        x: 0,
        y: 0,
        meta: { scrollPosition: from.meta.scrollPosition || 0 },
      };
    }
  },
});
router.beforeEach(async (to, from, next) => {
  const htmlElement = document.getElementById("app-html");
  if (to.path === "/sport") {
    htmlElement.classList.add("high-block");
  } else {
    htmlElement.classList.remove("high-block");
  }
  // await store.dispatch("checkUserIpBlock");
  const geoBlocked = store.state.isBlockedCountry;
  const productBlocked = store.state.isBlockedProduct;

  if (geoBlocked && to.path !== "/blocked") {
    next("/blocked");
  } else if (productBlocked && to.path !== "/maintenance") {
    next("/maintenance");
  } else {
    next();
  }
});
/*

router.beforeEach(async (to, from, next) => {
  //console.log('here',localStorage.getItem('jwt'))
  if(to.name === 'TeleAuth'){
    if('token' in to.query){
      await store.dispatch('awaitGetUserInfo');
      await store.dispatch('getUserBonuses');
    }

  }
  if(to.name === 'SignIn' || to.name === 'Registration' ){
    if($sto){
      next('/')
    }else{
      next()
    }
  }else{
    if(localStorage.getItem('jwt') || to.name === 'Home' || to.name === 'Game'){
      next()
    }else{
      next('/reg')
    }

  }
});
*/

export default router;
