// tournaments.js
import axios from "axios";
import createQueryString from "@/plugins/queryString";
import Vue from "vue";
const state = {
  availableTournaments: {
    items: [],
  },
  playerTournaments: {
    items: [],
  },
  tournament: {
    id: null,
    final_prize_amount: null,
  },
  tournamentProviders: {
    items: [],
  },
  tournamentGames: {
    items: [],
  },
  currentLeaderboard: null,
  modalTournamentCompleted: false,
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
  flipTournament(state, { id, flipped, tournamentsArray }) {
    const tournament = tournamentsArray.find((item) => item.id === id);
    if (tournament) {
      tournament.flipped = flipped; // `flipped`
    }
  },
  APPEND_ITEMS(state, { type, items }) {
    state[type].items = state[type].items.concat(items);
    state[type].page = items.page;
    state[type].pages = items.pages;
    state[type].size = items.size;
    state[type].total = items.total;
  },
};

const getters = {
  getAvailableTournaments(state) {
    return state.availableTournaments;
  },
  getPlayerTournaments(state) {
    return state.playerTournaments;
  },
  getTournament(state) {
    return state.tournament;
  },
  getCurrentLeaderboard(state) {
    return state.currentLeaderboard;
  },
};

const actions = {
  flipTournament({ commit }, { state, id, flipped }) {
    const tournamentsArray = state().items;
    commit("flipTournament", { id, flipped, tournamentsArray });
  },
  async awaitGetAvailableTournaments({ commit }) {
    await axios
      .get(`/api/tournaments/available`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const rd = response.data.items.map((item) => {
            return {
              ...item,
              flipped: false,
            };
          });
          const data = {
            items: rd,
            page: response.data.page,
            pages: response.data.pages,
            size: response.data.size,
            total: response.data.total,
          };
          commit("set", {
            type: "availableTournaments",
            items: data,
          });
        }
      })
      .catch((error) => {
        console.log("error = ", error);
        throw error;
      });
  },
  async awaitGetPlayerTournaments({ commit }) {
    await axios
      .get(`/api/tournaments/my`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const rd = response.data.items.map((item) => {
            return {
              ...item,
              flipped: false,
            };
          });
          const data = {
            items: rd,
            page: response.data.page,
            pages: response.data.pages,
            size: response.data.size,
            total: response.data.total,
          };
          commit("set", {
            type: "playerTournaments",
            items: data,
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  async awaitJoinTournament({ commit }, id) {
    await axios
      .post(`/api/tournaments/${id}/join`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          state.availableTournaments.items =
            state.availableTournaments.items.filter((item) => item.id !== id);
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async awaitLeaveTournament({ commit }, id) {
    await axios
      .delete(`/api/tournaments/${id}/unsubscribe`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 202) {
          state.playerTournaments.items = state.playerTournaments.items.filter(
            (item) => item.id !== id
          );
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },

  async awaitGetLeaderboard({ commit, state }, id) {
    await axios
      .get(`/api/tournaments/${id}/leaderboard`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const rd = response.data;
          const tournament = state.availableTournaments.items.find(
            (item) => item.id === id
          );
          if (tournament) {
            Vue.set(tournament, "leaderboard", rd);
          }
          if (state.tournament && state.tournament.id == id) {
            Vue.set(state.tournament, "leaderboard", rd);
          }
          state.currentLeaderboard = rd;
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async awaitGetTournament({ commit }, id) {
    await axios
      .get(`/api/tournaments/my/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("set", {
            type: "tournament",
            items: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async awaitGetTournamentProviders({ commit }, filters) {
    const queryString = createQueryString(filters);
    await axios
      .get(`/api/games/filter${queryString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (filters.page === 1) {
            commit("set", {
              type: "tournamentProviders",
              items: response.data,
            });
          } else if (filters.page > 1) {
            commit("APPEND_ITEMS", {
              type: "tournamentProviders",
              items: response.data,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async awaitGetTournamentGames({ commit }, filters) {
    const queryString = createQueryString(filters);
    await axios
      .get(`/api/games/filter${queryString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (filters.page === 1) {
            commit("set", {
              type: "tournamentGames",
              items: response.data,
            });
          } else if (filters.page > 1) {
            commit("APPEND_ITEMS", {
              type: "tournamentGames",
              items: response.data,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
